<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeModal" @keydown.esc="closeModal" width="600" scrollable>
      <v-card id="areaEdit">
        <v-card-title v-if="modalMode === 'create'" class="secondary">
          {{preSetAreaType === 'indoor' ? $t('add-floorplan') : $t('add-area')}}
        </v-card-title>
        <v-card-title v-if="modalMode === 'edit'" class="secondary">
          {{preSetAreaType === 'indoor' ? $t('edit-floorplan') : $t('edit-area')}}
        </v-card-title>
        <v-card-subtitle v-if="preSetAreaType === 'outdoor' && modalMode === 'create'" class="m-0 p-0 px-6 pt-4 pb-1">
          {{ $t('geo-area-clarification') }}
        </v-card-subtitle>
        <v-card-text class="card-text" :class="{'pt-6': !(preSetAreaType === 'outdoor' && modalMode === 'create')}">
          <v-form @submit.stop.prevent="handleSubmit" id="areaEditForm">
            <v-row justify="center">
              <v-col cols="12">
                <!-- name -->
                <v-text-field
                  :label="$t('name')"
                  tabindex="1"
                  :dense="true"
                  v-model="form.name"
                  outlined
                  :error-messages="nameErrors"
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <!-- site -->
                <v-text-field
                  :label="$t('site')"
                  :dense="true"
                  disabled
                  v-model="form.site.value"
                  outlined
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="modalMode === 'edit' && form.areaType !== 'outdoor'">
              <v-col>
               <v-btn
                  id="areaSubmitBtn"
                  depressed
                  color="secondary"
                  @click="openFloorPlan"
                  class="px-8 float-right">{{ $t('edit-reference-points') }}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="ma-auto" cols="12">
            <v-btn
              id="areaSubmitBtn"
              depressed
              color="secondary"
              tabindex="3"
              @click="handleSubmit"
              class="px-8 float-right"
            ><span v-if="modalMode === 'edit'">{{$t('save')}}</span><span v-if="modalMode === 'create'">{{$t('next')}}</span></v-btn>
            <v-btn
              v-if="modalMode === 'edit' && form.areaType === 'outdoor'"
              depressed
              color="secondary"
              tabindex="3"
              @click="handleEditOutdoorArea"
              class="float-left"
            >{{$t('edit-area-polygon')}}</v-btn>
            <v-btn
              v-if="modalMode === 'edit' && form.areaType !== 'outdoor'"
              depressed
              color="secondary"
              tabindex="3"
              @click="handleEditImage"
              class="float-left"
            >{{$t('edit-area-image')}}</v-btn>
            <v-btn
              text
              color="secondary"
              tabindex="3"
              @click="closeModal"
              class="px-8 mr-2 float-right"
            >{{$t('cancel')}}</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <indoor-area-image-upload :backBtn="true" @onBackHandler="openModal" @areaCreatedEdited="areaCreated" :area="form" :modalMode="modalMode" ref="imageUpload" />
    <outdoor-area-polygon-upload :backBtn="true" @onBackHandler="openModal" @areaCreatedEdited="areaCreated" :area="form" :modalMode="modalMode" ref="mapPolygonUpload" />
    <edit-floor-plan-data :backBtn="true" @onBackHandler="openModal" ref="floorPlanData" :mode="modalMode"></edit-floor-plan-data>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';
import UploadImageArea from './indoor-area-image-upload';
import UploadMapPolygonArea from './outdoor-area-polygon-upload';
import EditFloorPlanData from './floor-plan-data';


export default {
  components: {
    'indoor-area-image-upload': UploadImageArea,
    'outdoor-area-polygon-upload': UploadMapPolygonArea,
    EditFloorPlanData
  },
  data() {
    return {
      dialog: false,
      form: {
        name: '',
        areaType: '',
        areaLocation: '',
        id: '',
        polygon: null,
        site: {},
        zones: []
      },
      editOriginalAreaName: '',
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
    },
      id: '',
    },
  },
  props: ['modalMode', 'preSetAreaType', 'toastPosition', 'toastContainerClass'],
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required &&
        errors.push(this.$t('error-name-required'));
      return errors;
    }
  },
  methods: {
    ...mapActions('area', ['editArea', 'validateAreaName']),
    openModal(area, site) {
      this.editOriginalAreaName = '';
      this.dialog = true;
      this.$v.form.$reset();
      if (site) {
        this.$set(this.form, 'site', site);
      }
      this.$nextTick(()=>{
        if (area) {
          Object.assign(this.form, {
            name: area.name,
            id: area.id,
            areaLocation: area.areaLocation,
            indoorMapping: area.indoorMapping,
            polygon: area.polygon,
            areaType: area.areaLocation || area.areaType,
            zones: area.zones
          });
        } else {
          Object.assign(this.form, {
            name: '',
            id: '',
            areaType: this.preSetAreaType ? this.preSetAreaType: 'indoor'
          });
        }
      })
    },
    handleCreateArea() {
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.form.areaType === 'indoor') {
        this.$refs.imageUpload.openModal();
      } else if(this.form.areaType === 'outdoor') {
        this.$refs.mapPolygonUpload.openModal();
      } 
      this.dialog = false;
    },
    handleEditImage() {
      this.$refs.imageUpload.openModal(this.form);
    },
    areaCreated(area) {
      this.$emit('areaCreatedEdited', area)
      this.dialog = false;
    },
    async handleSubmit() {
      try{
        if (this.modalMode === 'create') {
          const availability = await this.validateAreaName(this.form)
          if(!availability){
            this.$toasted.show(this.$t('area-duplicated'), {
              position: this.toastPosition ? this.toastPosition : 'bottom-right',
              containerClass: this.toastContainerClass ? [this.toastContainerClass] : [],
              className: ['toast-error'],
              duration: 5000,
            });
            return;
          }
          this.handleCreateArea();
        } else if (this.modalMode === 'edit') {
          if(this.editOriginalAreaName != this.form.name){ //name is updated
            const availability = await this.validateAreaName(this.form)
            if(!availability){
              this.$toasted.show(this.$t('area-duplicated'), {
                position: this.toastPosition ? this.toastPosition : 'bottom-right',
                containerClass: this.toastContainerClass ? [this.toastContainerClass] : [],
                className: ['toast-error'],
                duration: 5000,
              });
              return;
            }
          }
          this.hadleEditArea();
        }
      }catch(error){
        this.$toasted.show(error, {
          position: this.toastPosition ? this.toastPosition : 'bottom-right',
          containerClass: this.toastContainerClass ? [this.toastContainerClass] : [],
          className: ['toast-error'],
          duration: 5000,
        }); 
        throw new Error('Something went wrong', error);
      }
    },
    handleEditOutdoorArea() {
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$refs.mapPolygonUpload.openModal();
      this.closeModal();
    },
    async hadleEditArea() {
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        let payload = {
          data: {
            name: this.form.name,
          id: this.form.id,
          },
        };
        const editedArea = await this.editArea(payload);
        this.$toasted.show(this.$t('area-edited'), {
          position: this.toastPosition ? this.toastPosition : 'bottom-right',
          containerClass: this.toastContainerClass ? [this.toastContainerClass] : [],
          className: ['toast-success'],
          duration: 2000,
        });
        this.closeModal();
        this.$emit('areaCreatedEdited', editedArea)
      } catch (error) {
        this.$toasted.show(error, {
          position: this.toastPosition ? this.toastPosition : 'bottom-right',
          containerClass: this.toastContainerClass ? [this.toastContainerClass] : [],
          className: ['toast-error'],
          duration: 5000,
        }); 
        throw new Error('Something went wrong', error);
      }
    },
    closeModal() {
      this.dialog = false;
    },
    openFloorPlan(){
      this.$refs.floorPlanData.openModal(this.form);
    }
  },
};
</script>

<style scoped>
.negative-mt {
  margin-top: -20px;
  margin-bottom: -19px;
}
.card-text {
  min-height: 260px;
}
.airfinder3-slide-enter-active, .airfinder3-slide-leave-active {
  transition: height .5s
}
.airfinder3-slide-enter, .airfinder3-slide-leave-to {
  height: 0px
}
</style>