import axiosHelper from '../utils/axios';
import LocationModel from '../models/location';

export default (() => {
    const networkAssetPath = "/networkAsset/airfinder/";
    const v4Path = 'v4/';
    const v4Sites = [
		'f0637995-b796-4134-ab3e-0781a0e4f361',
        '6dfe8db7-af14-4962-9ad9-ab7c6d070fde',
        '28e8ae97-b656-44ac-a228-210c999bad95'
	];


    async function getLocationById(id) {
        let response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}location/${id}`);
        response = new LocationModel().normalize(response.data);
        return response;
    }
    async function getLocationsBySite(siteId, hiddenLoader = false, isAf3){
        let response = null;
        if(isAf3){
            response =  await axiosHelper.getNetworkAsset(hiddenLoader ? 'hideLoadingIndicator' : '').get(`${networkAssetPath}${v4Path}locations?siteId=${siteId}`);
        }else{
            response =  await axiosHelper.getNetworkAsset(hiddenLoader ? 'hideLoadingIndicator' : '').get(`${networkAssetPath}locations?siteId=${siteId}`);
        }
        response = response.data.map(filter => new LocationModel().normalize(filter) );
		return response;
    }
    async function getLocationByZone(zoneId) {
        const response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}locations?zoneId=${zoneId}`);
        return response.data.map(location => new LocationModel().normalize(location));
    }
    async function getLocationsByArea(areaId) {
        let response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}locations?areaId=${areaId}`);
        return response.data.map(location => new LocationModel().normalize(location));
    }
    async function createLocation(location) {
        const response = await axiosHelper.getNetworkAsset().post(`${networkAssetPath}locations`, location);
        return response;
    }
    async function importLocations(siteId, formData) {
		const response = await axiosHelper.getNetworkAsset().post(`${networkAssetPath}locations/bulk?siteId=${siteId}`, formData);
		return response.data;
    }
    async function editLocation(locationId, location) {
        const response = await axiosHelper.getNetworkAsset().put(`${networkAssetPath}location/${locationId}`, location);
        return response;
    }
    async function editLocations(params) {
        const response = await Promise.all(params.devicesToUpdate.map(location => {
            return this.editLocation(location.id, {
                zoneId: location.zoneId,
                name: location.name
            });
        }));
        return response;
    }
    async function deleteLocations(payload) {
        const response = await axiosHelper.getNetworkAsset().delete(`${networkAssetPath}locations`, {data: payload});
        return response;
    }

	return {
        getLocationById,
        getLocationsBySite,
        getLocationByZone,
        getLocationsByArea,
        createLocation,
        importLocations,
        editLocation,
        editLocations,
        deleteLocations
	}

})();