class Profile {
  normalizeEulaAcceptance = ({ accepted }) => {
    return { accepted };
  };

  serializeEulaAcceptance = ({ accepted }) => {
    return { accepted };
  };
}

export default Profile;
