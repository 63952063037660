var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.condition ? _vm.evalCondition(_vm.item) : true),expression:"item.condition ? evalCondition(item) : true"}],key:_vm.item.title,class:{
      'ma-2 mt-4 regular-link': !_vm.mini,
      'mt-4': _vm.mini,
      'regular-link-not-hover': !_vm.mini && _vm.item.subMenuItems && !_vm.item.collapsed,
      'v-list-item--active':
        _vm.hasSubmenuActive(_vm.item.subMenuItems) && _vm.item.active,
    },attrs:{"link":"","to":_vm.item.toLink,"target":_vm.item.blank ? '_blank' : '_self',"dense":true,"color":"primary"},on:{"click":function($event){return _vm.deactiveSubLinks(_vm.item)}}},[(_vm.mini)?_c('v-menu',{attrs:{"disabled":!_vm.item.subMenuItems,"absolute":false,"open-on-hover":true,"close-on-click":true,"close-on-content-click":true,"offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({attrs:{"title":_vm.$t(_vm.item.title)}},on),[_c('v-icon',{staticClass:"mini-link-icon",attrs:{"medium":""},domProps:{"textContent":_vm._s(_vm.item.icon)}}),(_vm.item.subMenuItems)?_c('v-icon',{staticClass:"mini-link-icon-arrow",domProps:{"textContent":_vm._s('mdi-chevron-right')}}):_vm._e()],1)]}}],null,false,1329265117)},[(_vm.item.subMenuItems)?_c('v-list',[(_vm.item.subMenuItems.length === 0 && _vm.item.emptySubmenuInfo)?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t(_vm.item.emptySubmenuInfo))+" ")]):_vm._l((_vm.item.subMenuItems),function(subItem,index){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
            (subItem.condition ? _vm.evalCondition(subItem) : true) &&
              (!_vm.currentSite.isAf3 ||
                (_vm.currentSite.isAf3 && !subItem.hideForAf3))
          ),expression:"\n            (subItem.condition ? evalCondition(subItem) : true) &&\n              (!currentSite.isAf3 ||\n                (currentSite.isAf3 && !subItem.hideForAf3))\n          "}],key:index,staticClass:"submenu-link",class:[
            {
              'submenu-item-name-active-mini': subItem.active && _vm.item.active,
            },
            { 'warn-high': subItem.classWarn === 'high' },
            { 'warn-mid': subItem.classWarn === 'mid' } ],on:{"click":function($event){_vm.onNavigate(subItem), _vm.activeSubLink(_vm.item, subItem)}}},[_vm._v(_vm._s(subItem.string_name ? subItem.string_name : _vm.$t(subItem.name, { value: subItem.amount })))])})],2):_vm._e()],1):[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"medium":""},domProps:{"textContent":_vm._s(_vm.item.icon)}})],1),_c('v-list-item-content',[(_vm.item.subMenuItems)?_c('div',{staticClass:"submenu-title-clicker",on:{"click":function($event){return _vm.onMainLinkSelected(_vm.item)}}}):_vm._e(),_c('v-list-item-title',{class:{ 'sub-menu-item-title': _vm.item.subMenuItems }},[_vm._v(_vm._s(_vm.$t(_vm.item.title)))]),(_vm.item.subMenuItems && !_vm.item.collapsed)?_c('v-icon',{staticClass:"regular-link-arrow",domProps:{"textContent":_vm._s('mdi-chevron-up')}}):_vm._e(),(_vm.item.subMenuItems && _vm.item.collapsed)?_c('v-icon',{staticClass:"regular-link-arrow",domProps:{"textContent":_vm._s('mdi-chevron-down')}}):_vm._e(),(_vm.item.subMenuItems && !_vm.item.collapsed)?_c('div',{staticClass:"submenu-list"},_vm._l((_vm.item.subMenuItems),function(subItem,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
              (subItem.condition ? _vm.evalCondition(subItem) : true) &&
                (!_vm.currentSite.isAf3 ||
                  (_vm.currentSite.isAf3 && !subItem.hideForAf3))
            ),expression:"\n              (subItem.condition ? evalCondition(subItem) : true) &&\n                (!currentSite.isAf3 ||\n                  (currentSite.isAf3 && !subItem.hideForAf3))\n            "}],key:index,staticClass:"mr-2 w-auto submenu-item-name",class:[
              { 'submenu-item-name-active': subItem.active && _vm.item.active },
              { 'warn-high': subItem.classWarn === 'high' },
              { 'warn-mid': subItem.classWarn === 'mid' } ],on:{"click":function($event){_vm.onNavigate(subItem), _vm.activeSubLink(_vm.item, subItem)}}},[(subItem.classWarn === 'high')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-close-circle')}}):_vm._e(),(subItem.classWarn === 'mid')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-alert')}}):_vm._e(),_vm._v(_vm._s(subItem.string_name ? subItem.string_name : _vm.$t(subItem.name, { value: subItem.amount }))+" ")],1)}),0):_vm._e()],1)]],2),_c('confirm-modal',{attrs:{"dialogConfirmOnly":"true","dialogModel":_vm.noItemsModal,"dialogBody":_vm.modalMsg,"dialogConfirmBtn":_vm.$t('ok')},on:{"confirm":function($event){_vm.noItemsModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }