<template>
    <div>
        <v-row>
            <v-col class="custom-card card-no-footer-height">
                <v-row>
                    <v-col md="5" sm="12">
                        <v-row>
                            <v-col md="8" class="pt-0">
                                <p class="custom-label">{{ $t('supertag-settings-tags')}}</p>
                                <v-autocomplete
                                    id="supertag"
                                    :items="supertags"
                                    item-text="label"
                                    item-value="value"
                                    v-on:change="onSupertagChange"
                                    :label="$t('supertag-settings-select')"
                                    v-model="selectedSupertag"
                                    :no-data-text="$t('tags-not-available')"
                                    hide-details
                                    solo
                                    dense
                                    outlined
                                    return-object
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedSupertag && supertagConfig" style="max-height:1.5em;">
                            <v-col md="5" class="custom-label">
                                <p>{{ $t('supertag-settings-lastMessageTime')}}</p>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedSupertag && supertagConfig">
                            <v-col md="5">
                                <p v-if="this.minutesAgo != null">{{ this.minutesAgo }}&nbsp;&nbsp;Ago</p>
                            </v-col>
                            <v-col>
                                <v-btn id="refresh" class="mx-2" fab dark small color="primary" :title="$t('supertag-settings-refresh')" @click="refreshCurrentData()">
                                    <v-icon dark>mdi-refresh</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedSupertag && supertagConfig">
                            <v-col md="8">
                                <p class="custom-label">{{ $t('supertag-settings-location') }}</p>
                                <v-row>
                                    <v-col md="6" class="pt-0">
                                        <p>{{ $t('supertag-settings-current') }}</p>
                                    </v-col>
                                    <v-col md="6" class="pt-0">
                                        <p>{{checkIfValueIsAvailable(currentSettingLocationInterval)}}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="6" class="pt-0">
                                        <p>{{ $t('supertag-settings-new') }}</p>
                                    </v-col>
                                    <v-col md="6" class="pt-0 slider-label">
                                        <p>{{checkIfValueIsAvailable(convertLocInterval)}}</p>
                                    </v-col>
                                </v-row>
                                <v-slider
                                    id="location"
                                    v-model="loc_slider"
                                    color="secondary"
                                    track-color="grey"
                                    always-dirty
                                    min="5"
                                    max="1440"
                                    @change="onLocationIntervalChange"
                                    @start="onLocationIntervalChange"
                                >
                                    <template v-slot:prepend>
                                    <v-icon
                                        id="decrement-loc"
                                        color="secondary"
                                        @click="decrement"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    </template>

                                    <template v-slot:append>
                                    <v-icon
                                        id="increment-loc"
                                        color="secondary"
                                        @click="increment"
                                    >
                                        mdi-plus
                                    </v-icon>
                                    </template>
                                </v-slider>
                            </v-col>

                        </v-row>
                        <v-row v-if="selectedSupertag && supertagConfig">
                            <v-col md="8">
                                <p class="custom-label">{{ $t('supertag-settings-heartbeat') }}</p>
                                <v-row>
                                    <v-col md="6" class="pt-0">
                                        <p>{{ $t('supertag-settings-current') }}</p>
                                    </v-col>
                                    <v-col md="6" class="pt-0">
                                        <p>{{checkIfValueIsAvailable(currentSettingHeartBeatInterval)}}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="6" class="pt-0">
                                        <p>{{ $t('supertag-settings-new') }}</p>
                                    </v-col>
                                    <v-col md="6" class="pt-0 slider-label">
                                        <p>{{checkIfValueIsAvailable(convertHeartBeatInterval)}}</p>
                                    </v-col>
                                </v-row>
                                <v-slider
                                    id="heartbeat"
                                    v-model="hb_slider"
                                    color="secondary"
                                    track-color="grey"
                                    always-dirty
                                    min="240"
                                    max="1440"
                                    step="60"
                                    @change="onHeartBeatIntervalChange"
                                    @start="onHeartBeatIntervalChange"
                                >
                                    <template v-slot:prepend>
                                    <v-icon
                                        id="decrement-hb"
                                        color="secondary"
                                        @click="hbIntervalDecrement"
                                    >
                                        mdi-minus
                                    </v-icon>
                                    </template>

                                    <template v-slot:append>
                                    <v-icon
                                        id="increment-hb"
                                        color="secondary"
                                        @click="hbIntervalIncrement"
                                    >
                                        mdi-plus
                                    </v-icon>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedSupertag && supertagConfig">
                            <v-col>
                                <p class="custom-label ma-auto">{{ $t('supertag-settings-location-order') }}</p>
                                <v-row>
                                    <v-col md="4" id="location-order-current">
                                        <p class="location-order">{{ $t('supertag-settings-current') }}</p>
                                    </v-col>
                                    <v-col md="8" class="d-flex location-order-values" >
                                        <v-list-item
                                            v-for="(item, i) in outdoorLocationOrder"
                                            :key="i"
                                            >
                                            <v-list-item-content>
                                                <b><v-list-item-title v-text="item"></v-list-item-title></b>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" id="location-order-new">
                                        <p class="location-order">{{ $t('supertag-settings-new') }}</p>
                                    </v-col>
                                    <v-col md="8" class="d-flex location-order-values" >
                                        <v-list-item
                                            v-for="(item, i) in supertagConfig.connectivity"
                                            :key="i" disabled
                                            >
                                            <v-list-item-icon>
                                                <v-icon>{{ item.icon }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content style="color: black;">
                                                <v-list-item-title v-text="item.label"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                                <div id='list-pick-container'>
                                    <v-list id="available" shaped>
                                        <v-list-item-group v-model="selectedLeft" color="primary">
                                            <v-list-item inactive>
                                                <v-list-item-icon>
                                                    <v-icon>{{ display_gps_switch.icon }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content style=" max-width: 50%;">
                                                    <v-list-item-title v-text="display_gps_switch.text"></v-list-item-title>
                                                </v-list-item-content>
                                                <v-tooltip color="#004670" right >
                                                    <template v-slot:activator="{on}">
                                                        <div v-on="on" style="width:min-content;">
                                                            <v-switch
                                                                v-model="gps_switch"
                                                                style="top-margin: 0px;"
                                                                class="ml-2 float-left switcher"
                                                                color="secondary"
                                                                @change="updateLocationOrder"
                                                            ></v-switch>
                                                        </div>
                                                    </template>
                                                    <div v-text="display_gps_switch.span" style="font-size: small; max-width:300px;"></div>
                                                </v-tooltip>
                                            </v-list-item>
                                            <v-list-item inactive>
                                                <v-list-item-icon>
                                                    <v-icon>{{ display_wifi_switch.icon }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content style=" max-width: 50%;">
                                                    <v-list-item-title v-text="display_wifi_switch.text"></v-list-item-title>
                                                </v-list-item-content>
                                                <v-tooltip color="#004670" right >
                                                    <template v-slot:activator="{on}">
                                                        <div v-on="on" style="width:min-content;">
                                                            <v-switch
                                                                v-model="wifi_switch"
                                                                style="top-margin: 0px;"
                                                                class="ml-2 float-left switcher"
                                                                color="secondary"
                                                                :disabled="wifi_disabled"
                                                                @change="updateLocationOrder"
                                                            ></v-switch>
                                                        </div>
                                                    </template>
                                                    <div v-text="display_wifi_switch.span" style="font-size: small; max-width:300px;"></div>
                                                </v-tooltip>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </div>
                            </v-col>        
                        </v-row>
                        <v-row v-if="hasPendingDownlink">
                            <v-col md="7" class="custom-label">
                                <p>{{ $t('supertag-settings-pending')}}</p>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasPendingDownlink && isLocationRatePending">
                            <v-col md="10" class="pt-0">
                                <p>Location Update Rate: {{ checkIfValueIsAvailable(currentSettingLocationInterval) }}  <v-icon>mdi-forward</v-icon> {{ superTagLocationInterval }} </p>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasPendingDownlink && isHeartbeatPending">
                            <v-col md="12" class="pt-0">
                                <p>Heartbeat: {{ checkIfValueIsAvailable(currentSettingHeartBeatInterval) }} <v-icon>mdi-forward</v-icon> {{ superTagHeartbeatInterval }}</p>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasPendingDownlink && isLocationOrderPending">
                            <v-col md="10" class="pt-0">
                                <p>Location Order: {{ outdoorLocationOrder.join(', ') }} <v-icon>mdi-forward</v-icon> {{ supertagConnectivity.join(', ') }}

                                </p>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedSupertag && supertagConfig">
                            <v-col>
                                <v-btn
                                    id="save-setting"
                                    color="primary"
                                    :disabled="hasUpdated"
                                    depressed
                                    @click="showSaveDialog = true"
                                >
                                    {{ $t('send-update') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="7" sm="12" v-if="currentData">
                        <p class="label">{{ $t('supertag-settings-details') }}</p>
                        <v-card class="mx-auto" id="currentData">
                            <v-card-title class="py-0">
                                <v-row>
                                    <v-col class="px-0">
                                        <table class="px-2 w-100">
                                            <tr v-for="item in currentData.highlightItems" :key="item.fieldKey">
                                                <td class="w-50">
                                                    <p>{{item.title}}</p>
                                                </td>
                                                <td class="pl-2 w-50">
                                                    <p v-if="item.type && item.type === 'date'">{{ item.value | moment('M/D/YYYY hh:mm A')}}</p>
                                                    <p v-else>{{ item.value }}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-actions class="pt-0">
                                <v-btn id="show-more" class="mx-2" text @click="showMore = !showMore" color="primary">
                                    {{showMore ? $t('supertag-settings-less-info') : $t('supertag-settings-more-info')}}<v-icon>{{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-actions>
                        
                            <v-expand-transition>
                                <div v-show="showMore">
                                    <v-card-text class="py-0">
                                        <v-row>
                                            <v-col class="px-0">
                                                <table class="px-2 w-100">
                                                    <tr v-for="item in currentData.items" :key="item.fieldKey">
                                                        <td class="w-50">
                                                            <p>{{item.title}}</p>
                                                        </td>
                                                        <td class="w-50 pl-2">
                                                            <p v-if="item.type && item.type === 'date'">{{ item.value | moment('M/D/YYYY hh:mm A')}}</p>
                                                            <p v-else>{{ item.value }}</p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog persistent v-model="showSaveDialog" max-width="900">
            <v-card v-if="!applyNowStep1 && !applyNowStep2">
                <v-btn color="primary" text fab outlined x-small dark class="float-right mt-1 mr-1" title="close" @click="showSaveDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-text class="pt-3" v-html="cloudSynNotSupported ? $t('supertag-settings-save-not-cloud') : $t('supertag-settings-save')"></v-card-text>
                <v-card-actions v-if="cloudSynNotSupported">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="secondary"
                        @click="showSaveDialog = false"
                        class="px-8"
                        >{{$t('cancel')}}
                    </v-btn>
                    <v-btn
                        depressed
                        color="secondary"
                        @click="confirmSave"
                        class="px-8"
                        >{{$t('save')}}
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-if="!cloudSynNotSupported">
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="confirmSave">
                        <v-icon>mdi-calendar</v-icon>
                        {{ cloudSynNotSupported ? 'save' : $t('supertag-settings-next-check') }}
                    </v-btn>
                    <v-btn height="32" depressed color="secondary" @click="applyNow" class="px-8 float-right">
                        <v-icon>mdi-download</v-icon>
                        {{ $t('supertag-settings-apply-now') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="applyNowStep1">
                <v-btn color="primary" text fab outlined x-small dark class="float-right mt-1 mr-1" title="close" @click="showSaveDialog = false; applyNowStep1 = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-text class="pt-3" v-html="$t('supertag-settings-apply-now-step-1')"></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text depressed color="secondary" @click="applyNowStart">
                        {{ $t('yes') }}
                    </v-btn>
                    <v-btn depressed color="secondary" @click="confirmSave" class="px-8 float-right small-text">
                        {{ $t('supertag-settings-no-device') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="applyNowStep2">
                <v-btn color="primary" text fab outlined x-small dark class="float-right mt-1 mr-1" title="close" @click="showLastAlert($t('supertag-settings-saved'));">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-text class="pt-3">
                    <img class="cloud-sync-img" src="http://mediaupload-us-east1.s3.amazonaws.com/images/cloudSync.jpeg">
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text depressed color="secondary" @click="showLastAlert($t('supertag-settings-saved'))">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn depressed color="secondary" @click="showLastAlert($t('supertag-settings-apply-now-finish'))">
                        {{ $t('supertag-settings-apply-finish') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <confirm-save-modal
            @confirm="onDiscardChanges"
            @cancel="onKeepChanges"
            :dialogModel="discardChanges"
            :dialogBody="$t('supertag-settings-discard')"
            :dialogConfirmBtn="$t('supertag-settings-discard-btn')"
        />
        <confirm-save-modal
            @confirm="cellIdChangeWarningModal = false"
            :dialogModel="cellIdChangeWarningModal"
            :dialogBody="$t('supertag-settings-cell-id-warning')"
            :dialogConfirmBtn="$t('ok')"
            :dialogConfirmOnly=true
        />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ConfirmSaveModal from '../../components/shared/modals/confirmModal';
    const firmwareVersionCloudSync = "1.3.3";

    export default {
        name: "Settings",
        components: {
            ConfirmSaveModal
        },
        data(){
            return{
                showMore: false,
                supertags: [],
                supertagConfig: null,           
                gps_switch: false,
                wifi_switch: true,
                display_gps_switch: { networkId: 'gps', sequenceId: 1, text: 'Enable GPS', label: 'GPS', icon: 'mdi-map-marker-radius-outline', span: this.$t('supertag-settings-gps-tooltip')}, // switch: this.gps_switch,
                display_wifi_switch: { networkId: 'wifi', sequenceId: 2, text: 'Try WIFI before GPS', label: 'WIFI', icon: 'mdi-wifi', span: this.$t('supertag-settings-wifi-tooltip')}, //, disabled: this.wifi_disabled, switch: this.wifi_switch
                wifi_disabled: null,
                currentData: null,
                selectedSupertag: null,
                lastMessageTime: null,
                minutesAgo: null,
                loc_slider: null,
                loc_interval: null,
                hb_slider: null,
                hb_interval: null,
                currentConfig: [],
                availableTechs: [],
                selectedLeft: [],
                selectedRight: [],
                showSaveDialog: false,
                hasChanges: false,
                hasUpdated: false,
                hasLocationIntervalChanged: false,
                hasHeartBeatIntervalChanged: false,
                discardChanges: false,
                cellIdChangeWarningModal: false,
                navigateTo: null,
                applyNowStep1: false,
                applyNowStep2: false,
                notSaved: false,
                cloudSynNotSupported: true,
                defaultLocInterval: 30*60, // 30 Minutes
                defaultHbInterval: 24*60*60, // 24 Hours
                defaultOutdoorLocationOrder: ["CELL ID"],
                cellNetworkId: 'cell_id',
                hasPendingDownlink: false,
                showSettingChangedStatus: false,
                outdoorLocationOrder: null,
                currentSettingLocationInterval: null,
                currentSettingHeartBeatInterval: null,
                supertagConnectivity: null                
            }
        },
        beforeRouteLeave: function(to, from, next) {
            //if the user leave the page without saving changes
            if(this.hasChanges){
                this.discardChanges = true;
                this.navigateTo = to;
            }else{
                next();
            }
        },
        computed: {
            ...mapGetters('site', ['currentSiteId']),
            ...mapGetters('supertag', ['getInitialSupertag']),
            superTagLocationInterval() {
                const interval = this.supertagConfig.locationInterval / 60
                if (interval < 60) {
                    return this.$t('supertag-settings-minutes', { minutes: interval });
                } else {
                    const duration = this.$moment.duration(interval, 'minutes');
                    const locIntervalHours = Math.floor(duration.asHours());
                    const locIntervalMinutes = duration.minutes();

                    if (interval >= 60 && interval < 120) {
                        if (locIntervalMinutes) {
                            return this.$t('supertag-settings-hr-minutes', { hr: locIntervalHours, m: locIntervalMinutes });
                        } else {
                            return this.$t('supertag-settings-hr', { hr: locIntervalHours });
                        }
                    } else {
                        if (locIntervalMinutes) {
                            return this.$t('supertag-settings-hrs-minutes', { hrs: locIntervalHours, m: locIntervalMinutes });
                        } else {
                            return this.$t('supertag-settings-hrs', { hrs: locIntervalHours });
                        }
                    }
                }
            },
            superTagHeartbeatInterval() {
                const interval = this.supertagConfig.heartBeatInterval / 60

                if (interval < 60) {
                    return this.$t('supertag-settings-minutes', { minutes: interval });
                } else {
                    const duration = this.$moment.duration(interval, 'minutes');
                    const hbIntervalHours = Math.floor(duration.asHours());
                    const hbIntervalMinutes = duration.minutes();

                    if (interval >= 60 && interval < 120) {
                        if (hbIntervalMinutes) {
                            return this.$t('supertag-settings-hr-minutes', { hr: hbIntervalHours, m: hbIntervalMinutes });
                        } else {
                            return this.$t('supertag-settings-hr', { hr: hbIntervalHours });
                        }
                    } else {
                        if (hbIntervalMinutes) {
                            return this.$t('supertag-settings-hrs-minutes', { hrs: hbIntervalHours, m: hbIntervalMinutes });
                        } else {
                            return this.$t('supertag-settings-hrs', { hrs: hbIntervalHours });
                        }
                    }
                }
            },
            convertLocInterval(){
                if (this.hasLocationIntervalChanged) {
                    this.loc_interval = this.loc_slider;
                }
                if(this.loc_slider < 60){
                    return this.$t('supertag-settings-minutes', {minutes : this.loc_interval});
                }else{
                    const duration = this.$moment.duration(this.loc_interval, 'minutes');
                    const locIntervalHours = Math.floor (duration.asHours());
                    const locIntervalMinutes = duration.minutes();

                    if(this.loc_slider >= 60 && this.loc_slider < 120){
                        if (locIntervalMinutes) {
                            return this.$t('supertag-settings-hr-minutes', {hr : locIntervalHours, m: locIntervalMinutes});
                        } else {
                            return this.$t('supertag-settings-hr', {hr : locIntervalHours});
                        }
                    }else{
                        if (locIntervalMinutes) {
                            return this.$t('supertag-settings-hrs-minutes', {hrs : locIntervalHours, m: locIntervalMinutes});
                        } else {
                            return this.$t('supertag-settings-hrs', {hrs : locIntervalHours});
                        }
                    }
                }
            },
            convertHeartBeatInterval(){
                if (this.hasHeartBeatIntervalChanged) {
                    this.hb_interval = this.hb_slider;
                }
                if(this.hb_interval < 60){
                    return this.$t('supertag-settings-minutes', {minutes : this.hb_interval});
                }else{
                    const duration = this.$moment.duration(this.hb_interval, 'minutes');
                    const hbIntervalHours = Math.floor (duration.asHours());
                    const hbIntervalMinutes = duration.minutes();

                    if(this.hb_slider >= 60 && this.hb_slider < 120){
                        if (hbIntervalMinutes) {
                            return this.$t('supertag-settings-hr-minutes', {hr : hbIntervalHours, m: hbIntervalMinutes});
                        } else {
                            return this.$t('supertag-settings-hr', {hr : hbIntervalHours});
                        }
                    }else{
                        if (hbIntervalMinutes) {
                            return this.$t('supertag-settings-hrs-minutes', {hrs : hbIntervalHours, m: hbIntervalMinutes});
                        } else {
                            return this.$t('supertag-settings-hrs', {hrs : hbIntervalHours});
                        }
                    }
                } 
            }, 
            isLocationRatePending(){
                if (this.currentData && this.currentData.configurableItems && this.supertagConfig && this.currentData.configurableItems.locationInterval != this.supertagConfig.locationInterval){
                    return true
                }
                return false
            },
            isHeartbeatPending(){
                if (this.currentData && this.currentData.configurableItems && this.supertagConfig && this.currentData.configurableItems.heartBeatInterval != this.supertagConfig.heartBeatInterval) {
                    return true
                }
                return false
            },
            isLocationOrderPending(){
                if(this.supertagConfig && this.outdoorLocationOrder){
                    if (JSON.stringify(this.supertagConnectivity) != JSON.stringify(this.outdoorLocationOrder)){
                        return true
                    }
                    return false
                }
                return false
            },
        },
        watch:{
            currentSiteId(){
                this.selectedSupertag = null;
                this.hasChanges = false;
                this.hasLocationIntervalChanged = false;
                this.hasHeartBeatIntervalChanged = false;
                this.hasPendingDownlink = false;
                this.loc_interval = null;
                this.hb_interval = null;
                this.stTechs = [];
                this.currentData = null;
                this.fetchTags();
            },
            selectedSupertag(newTag, oldTag){
                this.previousTag = oldTag;
            }
        },
        mounted() {
            if(this.currentSiteId){
                this.fetchTags();
            }
            if(this.getInitialSupertag){
                this.selectedSupertag = {value: this.getInitialSupertag.id, label: this.getInitialSupertag.name};
                this.clearInitialSupertag();
                this.onSupertagChange();
            }
        },
        methods: {
            ...mapActions('supertag', ['getSupertags', 'getSupertag', 'getSupertagCurrentData', 'saveSupertag', 'clearInitialSupertag']),
            ...mapActions('tag', ['getTag']),

            async fetchTags(){
                try{
                    this.supertags = await this.getSupertags(this.currentSiteId);
                }catch(error){
                    this.$toasted.show(error, { 
                        position: "bottom-right",
                        className: ['toast-error'], 
                        duration : 5000
                    });
                }
            },
            async setBatteryPercentage() {
                const SUPERTAG_SERCOMM_APPTOKEN = "d29b3be8f2cc9a1a7051";
                const RTK02_HWID = '0A';
                
                const appTok = this.currentData?.items?.find(item => item.fieldKey === "app_tok")?.value;
                const hwId = this.currentData?.items?.find(item => item.fieldKey === "hwId")?.value;
                
                if (appTok === SUPERTAG_SERCOMM_APPTOKEN && hwId?.toUpperCase() === RTK02_HWID) {
                    const batteryRemainingItem = this.currentData.highlightItems.find(item => item.fieldKey === 'batteryRemaining');
                    const batteryCapacityItem = this.currentData.items.find(item => item.fieldKey === 'batteryCapacity_mAh');
                    const batteryConsumedItem = this.currentData.items.find(item => item.fieldKey === 'batteryConsumed_mAh');
                    if (batteryRemainingItem) {
                        batteryRemainingItem.value = "Not available";
                    }
                    if (batteryCapacityItem) {
                        batteryCapacityItem.value = "470 mAh";
                    }
                    if (batteryConsumedItem) {
                        batteryConsumedItem.value = "Not available";
                    }
                }
            },
            async onSupertagChange(){
                if(this.hasChanges){
                    this.discardChanges = true;
                }else{
                    this.hasChanges = false;
                    this.hasLocationIntervalChanged = false;
                    this.hasHeartBeatIntervalChanged = false;
                    this.supertagConfig = await this.getSupertag(this.selectedSupertag.value);
                    this.currentData = await this.getSupertagCurrentData(this.selectedSupertag.value);
                                        
                    this.setBatteryPercentage(); //NEX-7560- Set Battery percentage if supertag appTok is rechargeable RTK02 

                    const isDownlinkAvailable = this.checkDownlinkAvailability(this.supertagConfig);
                    const hasCurrentData = !!(this.currentData && this.currentData.configurableItems);

                    if (isDownlinkAvailable) {
                        this.hasPendingDownlink = hasCurrentData ? this.checkPendingDownlink(this.supertagConfig, this.currentData.configurableItems) : true;
                    } else {
                        this.hasPendingDownlink = false;
                    }
                     
                    this.currentConfig= await this.getSupertag(this.selectedSupertag.value);
                    
                    this.availableTechs= [{ networkId: 'gps', sequenceId: 1, label: 'GPS', icon: 'mdi-map-marker-radius-outline'},
                                        { networkId: 'wifi', sequenceId: 2, label: 'WIFI', icon: 'mdi-wifi'}];

                    this.cloudSynNotSupported = (this.currentData) ? (this.currentData.highlightItems.find( item => item.fieldKey === 'firmwareVersion').value < firmwareVersionCloudSync) : true;

                    let locIntervalSeconds = this.defaultLocInterval;
                    let hbIntervalSeconds = this.defaultHbInterval;

                    if(hasCurrentData){
                        this.currentSettingLocationInterval = this.convertInterval(Math.round((this.currentData.configurableItems.locationInterval / 60) * 100) / 100);
                        this.currentSettingHeartBeatInterval = this.convertInterval(Math.round((this.currentData.configurableItems.heartBeatInterval / 60) * 100) / 100);
                    }else{
                        this.currentSettingLocationInterval = this.convertInterval(Math.round((this.defaultLocInterval / 60) * 100) / 100);
                        this.currentSettingHeartBeatInterval = this.convertInterval(Math.round((this.defaultHbInterval / 60) * 100) / 100);
                    }
                    if (this.hasPendingDownlink) {
                        locIntervalSeconds = this.supertagConfig.locationInterval;
                        hbIntervalSeconds = this.supertagConfig.heartBeatInterval;
                    } else {    
                        if (hasCurrentData) {
                            locIntervalSeconds = this.currentData.configurableItems.locationInterval;
                            hbIntervalSeconds = this.currentData.configurableItems.heartBeatInterval;
                            
                            if (this.currentData.configurableItems.gpsOrder) {
                                const currentGpsConnectivity = {
                                    networkId: 'gps',
                                    sequenceId: this.currentData.configurableItems.gpsOrder,
                                    label: 'GPS', 
                                    icon: 'mdi-map-marker-radius-outline'
                                };
                                let gpsConnectivity = this.supertagConfig.connectivity.find(tech => tech.networkId === 'gps');
                                if (gpsConnectivity) {
                                    gpsConnectivity = currentGpsConnectivity;
                                } else {
                                    this.supertagConfig.connectivity.push(currentGpsConnectivity);
                                }
                            }

                            if (this.currentData.configurableItems.wifiOrder) {
                                const currentWifiConnectivity = {
                                    networkId: 'wifi',
                                    sequenceId: this.currentData.configurableItems.wifiOrder,
                                    label: 'WIFI', 
                                    icon: 'mdi-wifi'
                                };
                                let wifiConnectivity = this.supertagConfig.connectivity.find(tech => tech.networkId === 'wifi');
                                if (wifiConnectivity) {
                                    wifiConnectivity = currentWifiConnectivity;
                                } else {
                                    this.supertagConfig.connectivity.push(currentWifiConnectivity);
                                }
                            }

                            if (this.currentData.configurableItems.cellOrder) {
                                // Cell ID is always available as fail-over, updating the sequence id as per current data
                                let cellConnectivity = this.supertagConfig.connectivity.find(tech => tech.networkId === 'cell_id');
                                cellConnectivity.sequenceId = this.currentData.configurableItems.cellOrder;
                            } else {
                                // Cell ID is always available as fail-over, moving to last item
                                let cellConnectivity = this.supertagConfig.connectivity.find(tech => tech.networkId === 'cell_id');
                                cellConnectivity.sequenceId = this.supertagConfig.connectivity.length;
                            }

                            this.supertagConfig.connectivity.sort(function (a, b) {
                                    if (a.sequenceId > b.sequenceId) {
                                    return 1;
                                    }
                                    if (a.sequenceId < b.sequenceId) {
                                    return -1;
                                    }
                                    return 0;
                            });
                        }
                    }

                    //remove from availablesTechs the ones that are already assigned
                    this.availableTechs = this.availableTechs.filter(val => this.supertagConfig.connectivity.findIndex(item => item.networkId === val.networkId) === -1);
                    
                    //update switch state up to availableTechs includes
                    this.updateSwitchSates();
  
                    // TODO Update variable names to camel case
                    if(this.supertagConfig.lastEventTime){                        
                        this.minutesAgo = this.formattingLastEventTime(this.supertagConfig.lastEventTime)
                    }
                    this.loc_interval = Math.round((locIntervalSeconds / 60) * 100) / 100; //convert to minutes, round to 2 decimal places
                    this.loc_slider = this.loc_interval;
                    this.hb_interval = Math.round((hbIntervalSeconds / 60) * 100) / 100; //converting to minutes 
                    this.hb_slider = this.hb_interval;
                    this.showSettingChangedStatus = true;
                    this.supertagConnectivity = this.supertagConfig.connectivity.map(item => { return item.label.toUpperCase() })
                    
                    let currentOutdoorOrder = this.currentData && this.currentData.items ? this.currentData.items.find(item => { return item.fieldKey == "outdoorLocationOrder" }) : null
                    if(currentOutdoorOrder){
                        let data = currentOutdoorOrder.value.split(', ')
                        this.outdoorLocationOrder = data
                    }else{
                        this.outdoorLocationOrder = this.defaultOutdoorLocationOrder
                    }
                }
                this.hasUpdated = false;
            },
            formattingLastEventTime(lastEventTime){
                const lastEventTimeDate = new Date(lastEventTime);                
                const nowDate = new Date();
                const minutesLET = Math.floor((lastEventTimeDate.getTime() / 1000) / 60);
                const minutesNow = Math.floor((nowDate.getTime() / 1000) / 60);
                const minutesAgo = (minutesNow - minutesLET) + (nowDate.getTimezoneOffset());
                return this.convertMinutesAgo(minutesAgo)
            },
            checkIfValueIsAvailable(value) {
                if (typeof value === 'string') {
                    return !value.includes("NaN") ? value : "Value Not Available";
                }
            },
            checkDownlinkAvailability(downlink) {
                let isAvailable = true;
                if (
                    !downlink.locationInterval &&
                    !downlink.heartBeatInterval &&
                    (downlink.connectivity.length === 1 &&
                    downlink.cellIdFailover)
                ) {
                    isAvailable = false;
                }
                return isAvailable;
            },
            checkPendingDownlink(configValues, currentValues) {
                let isPending = false;

                if (configValues.locationInterval != currentValues.locationInterval ||
                    configValues.heartBeatInterval != currentValues.heartBeatInterval) {
                    isPending = true;
                }
                else {
                    const cellConfig = configValues.connectivity.find(network => network.networkId === this.cellNetworkId);
                    const cellOrder = (cellConfig && !configValues.cellIdFailover) ? cellConfig.sequenceId : 0;
                    const cellOrderChanged = cellOrder !== currentValues.cellOrder;

                    const wifiConfig = configValues.connectivity.find(network => network.networkId === 'wifi');
                    const wifiOrder = wifiConfig ? wifiConfig.sequenceId : 0;
                    const wifiOrderChanged = wifiOrder !== currentValues.wifiOrder;
                    
                    const gpsConfig = configValues.connectivity.find(network => network.networkId === 'gps');
                    const gpsOrder = gpsConfig ? gpsConfig.sequenceId : 0;
                    const gpsOderChanged = gpsOrder !== currentValues.gpsOrder;

                    if (cellOrderChanged || wifiOrderChanged || gpsOderChanged) {
                        isPending = true;
                    }
                }
                return isPending;
            },
            //update switch state up to availableTechs includes
            updateSwitchSates(){
                if(this.availableTechs.length == 1){  
                    this.availableTechs.forEach(element => {
                        if(element.networkId === 'gps'){
                            this.gps_switch = false;
                            this.wifi_switch = false;
                            this.wifi_disabled = true;
                        }                            
                        if(element.networkId === 'wifi'){
                            this.wifi_switch = false;
                            this.gps_switch = true;
                            this.wifi_disabled = false;
                        }
                    });
                }else if(this.availableTechs.length == 2){
                    this.gps_switch = false;
                    this.wifi_switch = false;
                    this.wifi_disabled = true;
                }else if((this.availableTechs.length == 0 && this.supertagConfig.connectivity[0].networkId === 'gps')
                        || (this.availableTechs.length == 0 && this.supertagConfig.connectivity[2].networkId === 'gps')){
                    this.gps_switch = true;
                    this.wifi_switch = false;
                    this.wifi_disabled = false;
                }else{
                    this.gps_switch = true;
                    this.wifi_switch = true;
                    this.wifi_disabled = false;
                }
            },
            updateLocationOrder(val){
                if(val){      
                    if(this.gps_switch == true && this.wifi_switch == false){
                        this.selectedLeft = this.availableTechs.filter(element => element.networkId != 'cell_id');         
                        this.addToSelectedList();                        
                        this.supertagConfig.connectivity.map(item => {
                            item.networkId === "gps" ? item.sequenceId = 2 : item.sequenceId = item.sequenceId;
                            item.networkId === "wifi" ? item.sequenceId = 1 : item.sequenceId = item.sequenceId;
                            item.networkId === "cell_id" ? item.sequenceId = 3 : item.sequenceId = item.sequenceId;
                        });
                        this.supertagConfig.connectivity.sort((a, b) => (a.sequenceId > b.sequenceId) ? 1 : -1); 
                        this.wifi_switch = true;
                        this.wifi_disabled = false;
                        
                    };
                    if(this.gps_switch == true && this.wifi_switch == true){
                        this.selectedLeft = this.availableTechs.filter(element => element.networkId === 'wifi');
                        this.addToSelectedList();
                        this.hasChanges=true;
                        this.supertagConfig.connectivity.map(item => {
                            item.networkId === "gps" ? item.sequenceId = 2 : item.sequenceId = item.sequenceId;
                            item.networkId === "wifi" ? item.sequenceId = 1 : item.sequenceId = item.sequenceId;
                            item.networkId === "cell_id" ? item.sequenceId = 3 : item.sequenceId = item.sequenceId;
                        });
                        this.supertagConfig.connectivity.sort((a, b) => (a.sequenceId > b.sequenceId) ? 1 : -1);
                    };
                }else{
                    if(this.gps_switch == true && this.wifi_switch == false){
                        this.supertagConfig.connectivity.map(item => {
                            item.networkId === "gps" ? item.sequenceId = 1 : item.sequenceId = item.sequenceId;
                            item.networkId === "wifi" ? item.sequenceId = 2 : item.sequenceId = item.sequenceId;
                            item.networkId === "cell_id" ? item.sequenceId = 3 : item.sequenceId = item.sequenceId;
                        });                        
                        this.supertagConfig.connectivity.sort((a, b) => (a.sequenceId > b.sequenceId) ? 1 : -1);
                        this.hasChanges=true;
                    }else{                        
                        this.selectedRight = this.supertagConfig.connectivity.filter(element => element.networkId === 'gps');                
                        this.removeFromSelectedList();                        
                        this.supertagConfig.connectivity.map(item => {
                            item.networkId === "cell_id" ? item.sequenceId = 2 : item.sequenceId = item.sequenceId;
                            item.networkId === "wifi" ? item.sequenceId = 1 : item.sequenceId = item.sequenceId;
                        }); 
                    };
                };
                
            },            
            addToSelectedList(){
                if(this.selectedLeft.length > 0){
                    this.hasChanges = true;
                    this.selectedLeft.forEach(item => { 
                        this.supertagConfig.connectivity.push( (this.availableTechs.filter(val => val.networkId === item.networkId))[0]); 
                    }); //add techs to the supertag techs
                    this.availableTechs = this.availableTechs.filter(val => this.supertagConfig.connectivity.findIndex(item => item.networkId === val.networkId) === -1); //remove the selected techs from left container
                    this.selectedLeft = [];
                    this.updateSwitchSates();
                }
            },
            removeFromSelectedList(){
                if(this.selectedRight.length > 0){
                    let techsToMoveLeft = [];                   
                    this.selectedRight.forEach(item => { 
                        techsToMoveLeft.push((this.supertagConfig.connectivity.filter(val => val.networkId === item.networkId))[0]); 
                    }); //add techs to the availables techs
                    if (techsToMoveLeft.find(tech => tech.networkId === this.cellNetworkId)) {
                        this.cellIdChangeWarningModal = true;
                        return;
                    }
                    this.hasChanges = true;
                    this.availableTechs = this.availableTechs.concat(...techsToMoveLeft);
                    this.supertagConfig.connectivity = this.supertagConfig.connectivity.filter(val => this.availableTechs.findIndex(item => item.networkId === val.networkId) === -1); //remove the selected supertag techs from right container
                    this.selectedRight = [];
                    this.updateSwitchSates();
                }
            },
            onLocationIntervalChange() {
                this.hasLocationIntervalChanged = true;
                this.hasChanges = true;
            },
            onHeartBeatIntervalChange() {
                this.hasHeartBeatIntervalChanged = true;
                this.hasChanges = true;
            },
            async confirmSave(showSuccessMsg=true){
                try{
                    if(showSuccessMsg){
                        this.showSaveDialog = false;
                    }
                    if(this.loc_interval !== null){
                        this.supertagConfig.locationInterval = this.loc_interval * 60; //convert minutes to seconds
                    }
                    if(this.hb_interval !== null){
                        this.supertagConfig.heartBeatInterval = this.hb_interval * 60; //convert minutes to seconds
                    }
                    this.supertagConfig.connectivity.forEach((item, index) => {
                        item.sequenceId = index + 1;
                    });
                    this.hasChanges = false;
                    this.hasLocationIntervalChanged = false;
                    this.hasHeartBeatIntervalChanged = false;
                    this.supertagConfig = await this.saveSupertag(this.supertagConfig);
                    let msg = this.$t('supertag-settings-next-check-saved');
                    if(showSuccessMsg){
                        if(this.applyNowStep1){
                            msg = this.$t('supertag-settings-saved');
                        }
                        this.$toasted.show(msg, { 
                            position: "bottom-right",
                            className: ['toast-success'], 
                            duration : 5000
                        });
                    }
                    this.applyNowStep1 = false;
                    this.notSaved = false;
                    this.onSupertagChange();
                }catch(error){
                    this.$toasted.show(error.data.message ? error.data.message : error.data.error_description, { 
                        position: "bottom-right",
                        className: ['toast-error'], 
                        duration : 5000
                    });
                    this.applyNowStep1 = false;
                    this.notSaved = true;
                }
            },
            applyNow(){
                this.applyNowStep1 = true;
            },
            async applyNowStart(){
                await this.confirmSave(false);
                this.applyNowStep1 = false;
                if(!this.notSaved){
                    this.applyNowStep2 = true;
                }else{
                    this.showSaveDialog = false;
                }
            },
            showLastAlert(msg){
                this.applyNowStep1 = false;
                this.applyNowStep2 = false;
                this.showSaveDialog = false;
                this.$toasted.show(msg, { 
                    position: "bottom-right",
                    className: ['toast-success'], 
                    duration : 5000
                });
                this.hasUpdated = true;
            },
            async refreshCurrentData(){
                this.onSupertagChange();
            },
            onDiscardChanges(){
                this.discardChanges = false;
                this.hasChanges = false;
                this.hasLocationIntervalChanged = false;
                this.hasHeartBeatIntervalChanged = false;
                if(this.navigateTo !== null){
                    this.$router.push(this.navigateTo);
                }else{
                    this.onSupertagChange();
                }
            },
            onKeepChanges(){
                this.discardChanges = false;
                this.selectedSupertag = this.previousTag;
                this.previousTag = null;
                this.navigateTo = null;
            },
            decrement(){
                let subtractMinutes = null
                let remainder = 0
                this.hasChanges = true;
                this.hasLocationIntervalChanged = true
                if (this.loc_interval <= 60) {
                    subtractMinutes = 5
                    remainder = (this.loc_interval - 1) % 5
                    if (remainder) {
                        subtractMinutes = remainder
                    }
                    this.loc_interval -= subtractMinutes + 1;
                } else {
                    if (this.loc_interval > 60) {
                        subtractMinutes = 15
                        remainder = (this.loc_interval) % 15
                        if(remainder){
                            subtractMinutes = remainder
                        }
                        this.loc_interval -= subtractMinutes;
                    }
                }
                this.loc_slider = this.loc_interval;
            },
            increment(){
                let addMinutes = null
                let remainder = 0
                this.hasChanges = true;
                this.hasLocationIntervalChanged = true
                if(this.loc_interval < 60){
                    addMinutes = 5
                    remainder = (this.loc_interval+1) % 5
                    if(!remainder){
                        addMinutes = 0
                    }else{
                        addMinutes -= remainder 
                    }
                    this.loc_interval += addMinutes + 1;
                }else{
                    if(this.loc_interval >= 60){
                        addMinutes = 15
                        remainder = (this.loc_interval) % 15
                        if(remainder){
                            addMinutes -= remainder
                        }
                        this.loc_interval += addMinutes;
                    }
                }
                this.loc_slider = this.loc_interval;
            },
            hbIntervalDecrement(){
                this.hasChanges=true
                this.hasHeartBeatIntervalChanged = true;
                this.hb_interval-=60;
                this.hb_slider = this.hb_interval;
            },
            hbIntervalIncrement(){
                this.hasChanges=true;
                this.hasHeartBeatIntervalChanged = true;
                this.hb_interval+=60;
                this.hb_slider = this.hb_interval;
            },
            convertInterval(interval_value){
                if(interval_value < 60){
                    return this.$t('supertag-settings-minutes', {minutes : interval_value});
                }else{
                    const duration = this.$moment.duration(interval_value, 'minutes');
                    const hours = Math.floor (duration.asHours());
                    const minutes = duration.minutes();

                    if(interval_value >= 60 && interval_value < 120){
                        if (minutes) {
                            return this.$t('supertag-settings-hr-minutes', {hr : hours, m: minutes});
                        } else {
                            return this.$t('supertag-settings-hr', {hr : hours});
                        }
                    }else{
                        if (minutes) {
                            return this.$t('supertag-settings-hrs-minutes', {hrs : hours, m: minutes});
                        } else {
                            return this.$t('supertag-settings-hrs', {hrs : hours});
                        }
                    }
                }
            },
            convertMinutesAgo(minutes_ago){
                if(minutes_ago < 60){
                    return this.$t('supertag-settings-minutes', {minutes : minutes_ago});
                }else{
                    const duration = this.$moment.duration(minutes_ago, 'minutes');
                    const hours = Math.floor (duration.asHours());
                    const minutes = duration.minutes();
                    const dayHours = duration.hours();
                    const days = duration.days();
                    const months = duration.months();
                    const years = duration.years();

                    if(minutes_ago >= 60 && minutes_ago < 120){
                        if (minutes) {
                            return this.$t('supertag-settings-hr-minutes', {hr : hours, m: minutes});
                        } else {
                            return this.$t('supertag-settings-hr', {hr : hours});
                        }
                    } else if (minutes_ago >= 120 && minutes_ago < 2160){
                        if (minutes) {
                            return this.$t('supertag-settings-hrs-minutes', {hrs : hours, m: minutes});
                        } else {
                            return this.$t('supertag-settings-hrs', {hrs : hours});
                        }
                    } else if(minutes_ago >= 2160 && minutes_ago < 44640){
                        if (dayHours > 0) {
                            return days == 1 ? this.$t('supertag-settings-day-hrs', {dy : days, hrs: dayHours}) : this.$t('supertag-settings-days-hrs', {dys : days, hrs: dayHours});
                        } else {
                            return days == 1 ? this.$t('supertag-settings-day', {dy : days}) : this.$t('supertag-settings-days', {dys : days});
                        }
                    } else if(minutes_ago >= 44640 && minutes_ago < 525600){
                        if (days) {
                            return months == 1 ? this.$t('supertag-settings-month-days', {mo : months, dys: days}) : this.$t('supertag-settings-months-days', {mos : months, dys: days});
                        } else {
                            return months == 1 ? this.$t('supertag-settings-month', {mo : months}) : this.$t('supertag-settings-months', {mos : months});
                        }
                    } else {
                        if (months) {
                            return years == 1 ? this.$t('supertag-settings-year-months', {yr : years, mos: months}) : this.$t('supertag-settings-years-months', {yrs : years, mos: months});
                        } else {
                            return years == 1 ? this.$t('supertag-settings-year', {yr : years}) : this.$t('supertag-settings-years', {yrs : years});
                        }
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .custom-card{
        overflow-y: auto;
    }
    .cloud-sync-img {
        width: 100%;
        margin: auto;
        display: block;
    }
    #list-pick-container{
        min-width: 460px;
        float: left;
        @media (max-width: 525px){
            >.v-card{
                width: auto!important;
            }
        }
    }
    .move-to{
        height: 230px;
        width: 80px;
        float: left;
        >div:first-child{
            margin-top: 75px;
            margin-bottom: 15px;
        }
        @media (max-width: 525px){
            width: 55px;
        }
    }
    .v-list-item__title{
        text-transform: uppercase;
        font-size: 0.8rem;
    }
    .v-list-item__icon{
        margin-right: 10px!important;
        @media (max-width: 525px){
            margin-right: 0px!important;
        }
        i.v-icon{
            font-size: 1.2rem;
        }
    }
    .v-subheader{
        color: var(--v-primary-base);
        font-weight: bold;
    }
    #currentData .v-card__title{
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin-right: 10px;
    }
    #currentData .v-card__text{
        height: calc(100vh - 420px);
        min-height: 250px;
        overflow-y: auto;
    }
    #config p{
        text-transform: uppercase;
    }
    .headline {
        height: 48px;
        font-size: 18px !important;
        font-weight: 600;
        color: #3B5762;
    }
    .v-card__text {
        padding-top: 35px!important;
    }
    .small-text{
        font-size: 0.8rem;
    }
    table p { word-break: break-word; }
    @media (max-width: 525px){
        .v-list{
            padding-right: 0px;
        }
    }
    .location-order{
        width: 100%;
    }
    #location-order-current{
        padding-top: 4%
    }
    #location-order-new{
        padding-top: 4%
    }
    .location-order-values{
        max-width: fit-content;
    }

</style>