<template>
  <v-app>
    <div>
      <app-header></app-header>
      <div class="eula-container">
        <div class="eula-header">
          <p>
            <strong>{{ $t('link-labs') }}</strong>
          </p>
          <p>
            <strong>
              <u>{{ $t('end-user-license-agreement') }}</u>
            </strong>
          </p>
        </div>
        <iframe :src="enlacePDF" width="100%" height="600px"></iframe>
        <v-container class="eula-options">
          <v-row align="center" justify="center">
            <p class="text-center">
              <strong>
                <a
                  href="https://www.link-labs.com/privacy-policy"
                  target="_blank"
                  >{{ $t('privacy-policy-agreement') }}</a
                >
                <v-icon>mdi-link</v-icon>
              </strong>
            </p>
          </v-row>

          <v-row align="center" justify="center" v-if="!eulaAccepted">
            <p class="text-center">
              <strong>
                {{ $t('eula-notice-text-1') }}
                <br />
                {{ $t('eula-notice-text-2') }}
              </strong>
            </p>
          </v-row>
          <v-row align="center" justify="space-around" v-if="!eulaAccepted">
            <v-btn depressed color="primary" @click="accept">
              {{ $t('i-agree') }}
            </v-btn>
            <v-btn depressed @click="reject">
              {{ $t('i-do-not-agree') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-app>
</template>

<script type="text/javascript">
import { mapActions } from 'vuex';
import Header from '../layouts/shared/header.vue';
import englishPDF from '../assets/docs/en/EULA.pdf';
import spanishPDF from '../assets/docs/es/EULA.pdf';

export default {
  name: 'EULA',
  data() {
    return {
      //enlacePDF: archivo
      supportedLanguages: ['en', 'es'],
    };
  },
  components: {
    'app-header': Header,
  },
  computed: {
    //...mapGetters('user', ['eulaAcceptance']),
    currentLanguage() {
      const userLanguage = this.$i18n.locale;
      return this.supportedLanguages.includes(userLanguage)
        ? userLanguage
        : 'en';
    },

    enlacePDF() {
      return this.currentLanguage === 'en' ? englishPDF : spanishPDF;
    },

    eulaAccepted() {
      console.log(this.$store.getters.eulaAcceptance.accepted);
      return this.$store.getters.eulaAcceptance.accepted;
    },
  },
  methods: {
    ...mapActions([
      'acceptEula',
      'processLogin',
      'logout',
      'checkEulaAcceptance',
    ]),
    async accept() {
      try {
        await this.acceptEula();
        await this.processLogin();
      } catch (e) {
        e;
      }
    },
    async reject() {
      try {
        await this.logout({ vuetify: this.$vuetify });
      } catch (e) {
        e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.eula-container {
  margin-top: 80px;
  padding: 10px 30px 30px;
  overflow: auto;
  height: calc(100vh - 100px);
}
.eula-header {
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
}
.eula-body {
  max-height: calc(100vh - 320px);
  overflow: auto;
}
.eula-options {
  margin-top: 10px;
  max-width: 500px;
}

// Following is a modified version of a solution posted in https://stackoverflow.com/a/26245056
ol.nested-list {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    &:before {
      content: counters(item, '.') '. ';
      display: table-cell;
      padding-right: 0.6em;
    }
    > ol.nested-list > li {
      margin: 0;
      &:before {
        content: counters(item, '.') ' ';
      }
    }
  }
  &.bold-list > li:before {
    font-weight: bold;
  }
}
</style>
