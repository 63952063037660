import { PHONE_PATTERN, NO_SPACE_PATTERN } from '../constants/regex';

/* 
    Min 5 characters, Max 17 characters-
    Only numbers from 0-9, () ,+ ,-.
    As asked in NEX-2903
*/
export const phoneNumber = (value) => {
    if ( value ) {
        let phoneRegex = new RegExp(PHONE_PATTERN);
        return phoneRegex.test(value);
    }
    return true
}
/*
    A full name must have at least two words
    *Must add .trim to v-model => v-model.trim=""
*/
export const fullName = (val) => {
    if ( val ) {
        let names = val.split(' ');
        //Name must be at least 2 words
        if ( names.length < 2 ) {
        return false;
        }
        return true;
    }
    return true;
};

/*
    Avoid double spaces in a Full Name
    "John Doe" -> valid
    "John  Doe" -> invalid
    *Must add .trim to v-model => v-model.trim=""
*/
export const noDoubleSpaceInFullName = (val) => {
    if (val) {
      let names = val.split(' ');
      let noSpaceRegex = new RegExp(NO_SPACE_PATTERN);

      for ( let i = 0; i < names.length; i++ ) {
        if ( !noSpaceRegex.test(names[i]) ) {
          return false;
        }
      }
      return true;
    }
    return true;
};


