<script>
import { Doughnut } from 'vue-chartjs';

export default {
  name: 'Doughnut-Chart',
  extends: Doughnut,
  props: ['chartData', 'middleText', 'colors', 'labels', 'values', 'label'],
  data() {
    return {
      type: 'doughnut',
      chartdata: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: [],
            data: [],
          },
        ],
      },
      options : {
      responsive: true, 
      maintainAspectRatio: false, 
      animation: {
        animateRotate: false
      },
       elements: {
            center: {
                text: '50%'
            }
        },
        cutoutPercentage: 60,
       legend: {
        display: false,
        position: 'bottom'
      }
    },
  }
  },
  mounted(){
    this.registerPlugin();
    this.generateChartData();
    this.renderChart(this.chartdata, this.options);
    
  },
  methods: {
    registerPlugin: function(){
      const customPlugin = (chart) => {
      const { width, height, ctx } = chart.chart;
      ctx.restore();
      const fontSize = (height / 114).toFixed(2);
      ctx.font = `${fontSize}rem sans-serif`;
      ctx.textBaseline = "middle";

      const text = this.middleText;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    };

      const emptyChart = (chart) => {

        const { datasets } = chart.data;
        let hasData = false;
        hasData = datasets[0].data.length;

        if (!hasData) {
          const { chartArea: { left, top, right, bottom }, ctx } = chart;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;

          ctx.beginPath();
          ctx.lineWidth =  2;
          ctx.strokeStyle =  '#ffffff';
          ctx.arc(centerX, centerY, (r - 1 || 0), 0, 2 * Math.PI);
          ctx.stroke();
        }
      };

      this.addPlugin({
        id: "middle-text-plugin",
        beforeDraw: customPlugin
      });

      this.addPlugin({
        id: "empty-chart",
        beforeDraw: emptyChart
      });
    },
    generateChartData: function(){
      this.chartdata.labels = this.labels;
      this.chartdata.datasets[0].backgroundColor = this.colors;
      this.chartdata.datasets[0].data = this.values;
      this.chartdata.datasets[0].label = this.label;
    }
  },
  watch: {
    chartData(){
      this.generateChartData()
      this.renderChart(this.chartdata, this.options)
    }
  }
};

</script>
