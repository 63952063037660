<template>
  <div id="geofences">
    <v-row>
      <v-col class="custom-card card-no-footer-height">
        <v-row>
          <SearchBar :id="'search-geozone'"  @onSearchBarKeyUp="searchChange($event)" @searchText="searchChange($event)" :cols="6" :placeholder="$t('geozone-search-placeholder')"  :searchTerm="searchTerm" />
          <v-col cols="6">
            <v-btn height="32" width="50" min-width="50" :depressed="true" class="primary--text font-weight-bold ml-3 p-0 geofence-actions-popup" color="primaryLight">
              <actions-popup-component id="geofence-options" :closeDelay="'150'" :iconColor="'var(--v-primary-base)'" :actions="geofenceBtns"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="geo-container">
          <v-col class="accordion-scroll default-scroll-bar" cols="6">
            <!-- Maps -->
            <v-row>
              <v-col cols="12">
                <v-expansion-panels v-model="mapsOpen" :disabled="!outdoorAreas.length" flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      @click="closeMapAreaPanel"
                      expand-icon="mdi-menu-down"
                    > 
                      <div class="d-flex justify-space-between align-center ml-5">
                        <div class="fw-b" >{{$t('maps')}}</div>
                        <!-- Add Map (Geo Area) Button -->
                        <v-tooltip 
                          content-class="custom-tooltip"
                          color="white"
                          right
                          open-delay="800"
                          transition="slide-x-transition"
                          :open-on-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click.stop="handleAddOutdoorArea"
                              v-bind="attrs"
                              v-on="on"
                              class="mx-2 all-events"
                              color="white"
                              tag="span"
                              x-small
                              fab
                              :elevation="4"
                            >
                              <v-icon color="secondary">mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span class="secondary--text">{{$t('add-area')}}</span>
                        </v-tooltip>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-model="mapAreaOpen" accordion flat>
                        <v-expansion-panel v-for="(area, i) in outdoorAreas" :key="i" >
                          <v-expansion-panel-header
                            @click="mapAreaOpen !== i ? handleGetZones(area) : null"
                            @mouseenter="handleMapAreaHeaderHover(area, i)"
                            expand-icon="mdi-menu-down"
                          >
                            <v-row class="ml-3" align="center">
                              <v-col cols="5 d-flex" >
                                  <div class="area-header">
                                    <p class="mr-2">{{ area.name }}</p>
                                    <!-- Edit Outdoor Area Button -->
                                    <v-tooltip
                                      content-class="custom-tooltip"
                                      color="primary"
                                      right
                                      open-delay="200"
                                      transition="slide-x-transition"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn @click.stop="handleEditArea(area)" color="primary" icon  v-on="on" small>
                                          <v-icon size="14px">mdi-pen</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{$t('edit-area')}}</span>
                                    </v-tooltip>
                                    <!-- Delete Outdoor Area Button -->
                                    <v-tooltip
                                      content-class="custom-tooltip"
                                      color="primary"
                                      right
                                      open-delay="200"
                                      transition="slide-x-transition"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn @click.stop="handleDeleteArea(area)" color="primary" icon  v-on="on" small>
                                          <v-icon size="14px">mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{$t('delete-area')}}</span>
                                    </v-tooltip>
                                  </div>
                              </v-col>
                              <v-col cols="3">
                                <div class="area-header"><p>{{$t('geozones')}}</p></div>
                                <div class="area-value">{{ area.zoneCount }}</div>
                              </v-col>
                              <v-col cols="4">
                                <v-btn
                                  id="add-geozone"
                                  @click.stop="handleAddGeoZone(area)"
                                  block
                                  color="primary"
                                  class="add-geozone-btn"
                                  v-text="$t('add-geozone')"
                                />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <!-- HERE IS THE DATA OF ZONE NAMES   -->
                          <v-expansion-panel-content v-if="Number(area.zoneCount) > 0">
                            <div class="ml-4 mb-2">
                              <div class="area-column w-50">
                                <div class="area-header fs-12"><p>{{$t('zone-name')}}</p></div>
                              </div>
                              <div class="area-column w-50">
                                <div class="area-header fs-12"><p>{{$t('zone-center-point')}}</p></div>
                              </div>
                            </div>
                            <div v-if="loadingZones" class="mt-4">
                              <v-skeleton-loader
                                v-for="(zone, i) in [...Array(3)]"
                                loading
                                type="text"
                                class="ml-4 mb-4"
                                :key="i"
                              />
                            </div>
                            <div
                              class="zone-item ml-4"
                              v-else
                              v-for="(zone,i) in outdoorZones" :key="i"
                              @mouseenter="zonePolygonHover('outdoor', zone)"
                              @mouseleave="hoveredPolygon = ''"
                            >
                              <div class="d-flex w-50 pr-2">
                                <div class="mr-2">{{ zone.name }}</div>
                                <v-tooltip
                                  content-class="custom-tooltip"
                                  color="primary"
                                  right
                                  open-delay="200"
                                  transition="slide-x-transition"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      @click.stop="handleZoneClick(zone)"
                                      color="primary"
                                      v-on="on"
                                      small
                                      icon
                                    >
                                      <v-icon size="14px">mdi-pen</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{$t('edit-geozone')}}</span>
                                </v-tooltip>
                              </div>
                              <div class="w-50">
                                <div class="area-value fs-12">{{zoneCenter(zone)}}</div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <!-- Floorplans -->
            <v-row>
              <v-col cols="12">
                <v-expansion-panels v-model="floorplansOpen" :disabled="!indoorAreas.length" flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="closeFloorplanAreaPanel" expand-icon="mdi-menu-down">
                      <div class="d-flex justify-space-between align-center ml-5">
                        <div class="fw-b" >{{$t('floorplans')}}</div>
                        <!-- Add Floorplan Button -->
                        <v-tooltip 
                          content-class="custom-tooltip"
                          color="white"
                          right
                          open-delay="800"
                          transition="slide-x-transition"
                          :open-on-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click.stop="handleAddIndoorArea"
                              v-bind="attrs"
                              v-on="on"
                              class="mx-2 all-events"
                              color="white"
                              tag="span"
                              x-small
                              fab
                              :elevation="4"
                            >
                              <v-icon color="secondary">mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span class="secondary--text">{{$t('add-floorplan')}}</span>
                        </v-tooltip>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-model="floorplanAreaOpen" accordion flat>
                        <v-expansion-panel v-for="(area, i) in indoorAreas" :key="i">
                          <v-expansion-panel-header
                            @click="floorplanAreaOpen !== i ? handleGetZones(area) : null"
                            @mouseenter="handleFloorplanAreaHeaderHover(area, i)"
                            expand-icon="mdi-menu-down"
                            >
                            <v-row class="ml-3" align="center">
                              <v-col cols="5 d-flex">
                                  <div class="area-header">
                                    <p class="mr-2">{{ area.name }}</p>
                                    <!-- Edit Floorplan Button -->
                                    <v-tooltip
                                      content-class="custom-tooltip"
                                      color="primary"
                                      right
                                      open-delay="200"
                                      transition="slide-x-transition"
                                    >
                                    <template v-slot:activator="{ on }">
                                      <v-btn @click.stop="handleEditArea(area)" color="primary" icon  v-on="on" small>
                                        <v-icon size="14px">mdi-pen</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{$t('edit-floorplan')}}</span>
                                    </v-tooltip>
                                    <!-- Delete Floorplan Button -->
                                    <v-tooltip
                                      content-class="custom-tooltip"
                                      color="primary"
                                      right
                                      open-delay="200"
                                      transition="slide-x-transition"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn @click.stop="handleDeleteArea(area)" color="primary" icon  v-on="on" small>
                                          <v-icon size="14px">mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{$t('delete-floorplan')}}</span>
                                    </v-tooltip>
                                  </div>
                              </v-col>
                              <v-col cols="2">
                                <div class="area-header"><p>{{$t('zones')}}</p></div>
                                <div class="area-value">{{ area.zoneCount }}</div>
                              </v-col>
                              <v-col cols="5">
                                <v-btn
                                  id="add-geozone"
                                  @click.stop="handleManageFloorplanZones(area)"
                                  block
                                  color="primary"
                                  class="add-geozone-btn fixed-h"
                                  v-text="$t('manage-floorplan-zones')"
                                />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <!-- HERE IS THE DATA OF ZONE NAMES   -->
                          <v-expansion-panel-content v-if="Number(area.zoneCount) > 0">
                            <div class="ml-4 mb-2">
                              <div class="area-column w-50">
                                <div class="area-header fs-12"><p>{{$t('zone-name')}}</p></div>
                              </div>
                              <!-- <div class="area-column w-50">
                                <div class="area-header fs-12"><p>{{$t('zone-center-point')}}</p></div>
                              </div> -->
                            </div>
                            <div v-if="loadingZones" class="mt-4">
                              <v-skeleton-loader
                                v-for="(zone, i) in [...Array(3)]"
                                loading
                                type="text"
                                class="ml-4 mb-4"
                                :key="i"
                              />
                            </div>
                            <div
                              class="zone-item ml-4"
                              v-else
                              v-for="(zone,i) in indoorZones" :key="i"
                              @mouseenter="zonePolygonHover('indoor', zone)"
                              @mouseleave="hoveredPolygon = ''"
                            >
                              <div class="mr-2">{{ zone.name }}</div>
                              <v-tooltip
                                content-class="custom-tooltip"
                                color="primary"
                                right
                                open-delay="200"
                                transition="slide-x-transition"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    @click.stop="handleZoneClick(zone)"
                                    color="primary"
                                    v-on="on"
                                    small
                                    icon
                                  >
                                    <v-icon size="14px">mdi-pen</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{$t('edit-zone')}}</span>
                              </v-tooltip>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="p-relative" cols="6">
            <div
              class="indoor-image-skeleton-loader"
              v-if="loadingZones && preselectedAreaType === 'indoor'"
            ></div>
            <mapGL
              class="geofences-map"
              v-bind:class="{ 'show-map': preselectedAreaType === 'indoor' || preselectedAreaType === '' }"
              id="indoorMap"
              key="indoor-map"
              styleType='clear'
              :accessToken="'VUE_APP_SUPERTAG_MAPBOX_ACCESS_TOKEN'"
              @onLoad="onLoadIndoor"
            >
            </mapGL>
            <mapGL
              class="geofences-map"
              v-bind:class="{
                'show-map': preselectedAreaType === 'outdoor'
                || preselectedAreaType === ''
                || !indoorAreas.find(n => n.image)
              }"
              id="geozonesMap"
              key="outdoor-map"
              styleType="default"
              :accessToken="'VUE_APP_SUPERTAG_MAPBOX_ACCESS_TOKEN'"
              @onLoad="onLoad"
            >
            </mapGL>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ZoneModal
      @hasChanges="handleZoneHasChanges"
      :areas="preselectedAreaType === 'outdoor' ? outdoorAreas: indoorAreas"
      :selectedZone="zoneSelected"
      ref="mapZonePolygonUpload"
    />
    <ManageDevices
      :areas="indoorAreas"
      ref="locationAccessGateway"
    />
    <AreaNewEdit
      @areaCreatedEdited="handleZoneHasChanges"
      :preSetAreaType="preselectedAreaType"
      :modalMode="createEdit"
      ref="areaNewEditModal"
    />
    <!-- Delete Area Modal -->
    <v-dialog
      v-model="confirmationDeleteAreaDialog"
      @click:outside="cancelAreaDeletion"
      @keydown.esc="cancelAreaDeletion"
      max-width="450"
    >
      <v-card>
        <v-card-title v-if="areaToDelete" class="error lighten-1">{{
          areaToDelete.areaLocation === 'indoor' ? $t('delete-floorplan') : $t('delete-area')
        }}</v-card-title>
        <v-card-text v-if="areaToDelete" class="mt-3 font-weight-bold">{{
          $t('delete-are-you-sure', { name: areaToDelete.name })
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            height="32"
            text
            color="error"
            @click="cancelAreaDeletion"
            class="primary--text font-weight-bold"
            >{{ $t('cancel') }}</v-btn
          >
          <v-btn
            depressed
            color="error lighten-1"
            @click="confirmAreaDeletion"
            class="px-8 float-right"
            >{{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ZoneCategoryNewEdit
      :modalMode="'create'"
      ref="zoneCategoryNewEditModal"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters} from 'vuex';
import mapboxgl from 'mapbox-gl';
import {
  addPolygonToMap,
  updatePolygon,
  clearLayers,
  fitPolygon,
  drawAreaIndoorPlan,
  getCenterLatLongInPoly,
  addFillLayerToMap,
} from '../utils/map';
import SearchBar from '../components/search-bar/search-bar.vue';
import mapGL from '../components/map/map.vue';
import ZoneModal from '../components/zones/zone-polygon-upload.vue';
import AreaNewEdit from '../components/areas/areas-new-edit.vue';
import ManageDevices from '../components/locations/manage-location-access-point-gateway.vue';
import ActionsPopupComponent from "../components/shared/data-table/custom-cells/actions-popup"
import ZoneCategoryNewEdit from '../components/zones/zone-category-new-edit.vue';
import AreaModel from '../models/area';
import { LOCATION_TYPES } from '../constants/areas';

const USA_CENTER_LONG = -100.4458825;
const USA_CENTER_LAT = 39.7837304;
const CREATE = 'create';
const EDIT = 'edit';

export default {
  components: {
    mapGL,
    ZoneModal,
    SearchBar,
    AreaNewEdit,
    ManageDevices,
    ActionsPopupComponent,
    ZoneCategoryNewEdit
  },
  data() {
    return {
      showHeaderOutdoor: true,
      showHeaderIndoor: true,
      preselectedAreaType: '',
      searchWord:'',
      areaLayerId: 'mapbox-area-id-airfinder',
      zoneLayerId: 'mapbox-zone-id-airfinder',
      map: null,
      mapIndoor: null,
      indoorZones: [],
      outdoorZones: [],
      hoveredPolygon: '',
      zoneSelected: {},
      dialog: true,
      mapsOpen: 0,
      mapAreaOpen: undefined,
      floorplansOpen: undefined,
      floorplanAreaOpen: undefined,
      searchTerm: '',
      areasLoaded: false,
      selectedArea: {},
      createEdit: 'create',
      loadingZones: false,
      inQueueArea: false,
      confirmationDeleteAreaDialog: false,
      areaToDelete: null,
      geofenceBtns: [
        {
            name: this.$t('add-zone-category'),
            fn: this.addZoneCategory,
            icon: 'group',
        },
        {
            name: this.$t('edit-zone-category'),
            fn: this.editZoneCategory,
            icon: 'ungroup',
        },
        {
            name: this.$t('delete-zone-category'),
            fn: this.deleteZoneCategory,
            icon: 'delete',
        },
      ],
    };
  },
  watch: {
    async currentSiteId() {
      this.areasLoaded = false;
      await this.getAreas({siteId: this.currentSiteId});
      this.areasLoaded = true;
    },
    currentSite() {
      //We clean every outdoor or indoor zones saved in the local state
      this.outdoorZones = [];
      this.indoorZones = [];
      this.closeMapsPanels();
      this.closeFloorplansPanels();
    },
    mapsOpen(value) {
      if ( value !== undefined) {
        this.closeFloorplansPanels();
        this.preselectedAreaType = LOCATION_TYPES.OUTDOOR;
      }
    },
    floorplansOpen(value) {
      if ( value !== undefined) {
        this.closeMapsPanels();
        this.preselectedAreaType = LOCATION_TYPES.INDOOR;
      }
    },
    mapAreaOpen() {
      if (this.mapAreaOpen === undefined) {
        this.map.flyTo({ center: [USA_CENTER_LONG, USA_CENTER_LAT], zoom: 3.5 });
        clearLayers(this.map)
      }
    }
  },
  async mounted() {
    if (this.currentSiteId) {
      await this.fetchAreas()
    }
  },
  computed:{
    ...mapGetters('site', ['currentSiteId', 'currentSite']),
    ...mapGetters('area', ['areas']),
    parsedAreas() {
      return this.areas.map(area => new AreaModel().normalizeInfo(area))
    },
    indoorAreas() {
      return this.parsedAreas.filter(n => n.areaLocation === LOCATION_TYPES.INDOOR && (n.name.toLowerCase().includes(this.searchWord) || n.id.includes(this.searchWord)))
    },
    outdoorAreas() {
      return this.parsedAreas.filter(n => n.areaLocation === LOCATION_TYPES.OUTDOOR && (n.name.toLowerCase().includes(this.searchWord) || n.id.includes(this.searchWord)))
    },
    currentSiteCopy() {
      return JSON.parse(JSON.stringify(this.currentSite));
    },
  },
  methods: {
    ...mapActions('area', ['getAreas', 'createOutdoorArea', 'getAreaIndoorFile', 'deleteArea']),
    ...mapActions('location', ['getLocationsByArea']),
    ...mapActions('gateway', ['getGatewaysBySite']),
    ...mapActions('accessPoint', ['getAccessPointsBySite']),
    ...mapActions('zone', ['getZoneByArea', 'getZoneCategories']),
    async fetchAreas() {
      this.areasLoaded = false;
      await this.getAreas({siteId: this.currentSiteId});
      this.areasLoaded = true;
    },
    async handleZoneHasChanges() {
      this.areasLoaded = false;
      //We clean every outdoor or indoor zones saved in the local state
      this.outdoorZones = [];
      this.indoorZones = [];
      //We close every indoor or outdoor area panel so user after changing a zone needs to clicks on panel to load again fresh zones.
      this.mapAreaOpen = undefined;
      this.floorplanAreaOpen = undefined;

      await this.getAreas({siteId: this.currentSiteId});
      this.areasLoaded = true;
    },
    onLoad(mapRef) {
      this.map = mapRef;
      this.map.addControl(new mapboxgl.ScaleControl());
      this.map.flyTo({ center: [USA_CENTER_LONG, USA_CENTER_LAT], zoom: 3.5 });
    },
    onLoadIndoor(mapRef) {
      this.mapIndoor = mapRef;
    },
    async handleManageFloorplanZones( area) {
      this.preselectedAreaType = LOCATION_TYPES.INDOOR;
      this.zoneSelected = null;
      this.$set(area, 'site', this.currentSiteCopy);
      let gatewaysPromise = this.handleGetGatewaysBySite(area);
      let accessPointsPromise = this.handleGetAccessPointsBySite(area);
      if (parseInt(area.locationCount) > 0) {
        let locationPromise = this.handleGetLocationByArea(area);
        await locationPromise;
      }
      let zoneCategoryPromise = this.getZoneCategories({ siteId: this.currentSite.id });
      await accessPointsPromise, gatewaysPromise, zoneCategoryPromise;
      this.$nextTick(()=>{
        this.$refs.mapZonePolygonUpload.openModal(area);
      })
    },
    async handleGetLocationByArea(area) {
      try {
        const locations = await this.getLocationsByArea(area.id);
        this.$set(area, 'locations', locations);
      } catch (e) {
        throw new Error(this.$t('something-went-wrong'), e);
      }
    },
    async handleGetGatewaysBySite(area) {
      try {
        const gateways = await this.getGatewaysBySite({ siteId: this.currentSiteId });
        this.$set(area.site, 'gateways', gateways);
      } catch (e) {
        throw new Error(this.$t('something-went-wrong'), e);
      }
    },
    async handleGetAccessPointsBySite(area) {
      try {
        const accessPoints = await this.getAccessPointsBySite({
          siteId: this.currentSiteId,
        });
        this.$set(area.site, 'accessPoints', accessPoints);
      } catch (e) {
        throw new Error(this.$t('something-went-wrong'), e);
      }
    },
    async handleAddGeoZone(area) {
      this.preselectedAreaType = LOCATION_TYPES.OUTDOOR;
      this.$set(area, 'site', this.currentSiteCopy);
      this.zoneSelected = null;
      await this.getZoneCategories({ siteId: this.currentSite.id });
      this.$nextTick(()=>{
        this.$refs.mapZonePolygonUpload.openModal(area);
      })
    },
    handleManageDevices(area) {
      area.site = this.currentSiteCopy
      this.$refs.locationAccessGateway.openModal(area);
    },
    handleZoneClick(zone){
      this.preselectedAreaType = this.selectedArea.areaLocation;
      this.zoneSelected = zone;
      this.$set(this.selectedArea, 'site', this.currentSiteCopy);
      this.$nextTick(()=>{
        this.$refs.mapZonePolygonUpload.openModal(this.selectedArea);
      })
    },
    searchChange(value) {
       this.searchWord = value?.toLowerCase();
    },
    async handleGetZones(area) {
      //Avoid calling zones if they are already loading
      if (this.loadingZones) {
        return;
      }
      //Setting the selected area
      this.selectedArea = area;

      if (area.areaLocation === LOCATION_TYPES.OUTDOOR) {
        /* Drawing points */
        this.areaPolygonHover('outdoor', area);
        clearLayers(this.map);
        addPolygonToMap(this.map, area.polygon, this.areaLayerId, area.name);
        addFillLayerToMap(this.map, this.areaLayerId, this.$vuetify.theme.themes.light.secondary, 0.1, 3);
        fitPolygon(this.map, area.polygon);
        /* End Drawing points */
      }

      try {
        this.loadingZones =  true;
        const zonesByArea = await this.getZoneByArea({ areaId: area.id, hiddenLoader: true });

        if (area.areaLocation === LOCATION_TYPES.OUTDOOR) {
          this.outdoorZones = zonesByArea;
        } else {
          this.indoorZones = zonesByArea;
          const indoorMapImage = await this.initIndoorMap(area);
          //We add the image to the corresponding area to save it for future requests
          this.indoorAreas.find(n => n.id === area.id).image = indoorMapImage;
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loadingZones = false;
      }
    },
    async initIndoorMap(area) {
      try {
        const indoorMapImage = await this.getAreaIndoorFile({
          areaId: area.id,
          hiddenLoader: true,
        });

        clearLayers(this.mapIndoor);
        drawAreaIndoorPlan(this.mapIndoor, indoorMapImage);
        this.preselectedAreaType = LOCATION_TYPES.INDOOR;

        return indoorMapImage;
      } catch (error) {
        throw new Error(error);
      }
    },
    //When hovering an area item
    areaPolygonHover(location, polygon){
      this.preselectedAreaType = location;
      this.hoveredPolygon = polygon.id;
      let currentMap = location === LOCATION_TYPES.OUTDOOR ? this.map : this.mapIndoor;

      //Remove zone layers from the map, first remove layers, then the source.
      if (currentMap.getLayer(this.zoneLayerId)) {
        currentMap.removeLayer(this.zoneLayerId);
      }
      if(currentMap.getLayer(`line-${this.zoneLayerId}`)) {
        currentMap.removeLayer(`line-${this.zoneLayerId}`);
      }
      if( currentMap.getSource('readonly-' + this.zoneLayerId)) {
        currentMap.removeSource('readonly-' + this.zoneLayerId);
      }

      setTimeout(() => {
        if (this.hoveredPolygon === polygon.id) {
          if (currentMap && !currentMap.getSource('readonly-' + this.areaLayerId)) {
            addPolygonToMap(currentMap, polygon.polygon, this.areaLayerId, polygon.name);
            addFillLayerToMap(currentMap, this.areaLayerId, this.$vuetify.theme.themes.light.secondary, 0.3);
            fitPolygon(currentMap, polygon.polygon);

          } else {
            updatePolygon(currentMap, polygon.polygon, this.areaLayerId);
            fitPolygon(currentMap, polygon.polygon);
          }
        }
      }, 250);
    },
    //When hovering a zone item
    zonePolygonHover(location, zonePolygon) {
      this.preselectedAreaType = location;
      this.hoveredPolygon = zonePolygon.id;
      let currentMap = location === LOCATION_TYPES.OUTDOOR ? this.map : this.mapIndoor;

      setTimeout(() => {
        if (this.hoveredPolygon === zonePolygon.id) {
          if (currentMap && !currentMap.getSource('readonly-' + this.zoneLayerId)) {
            addPolygonToMap(currentMap, zonePolygon.polygon, this.zoneLayerId, zonePolygon.name);
            addFillLayerToMap(currentMap, this.zoneLayerId, this.$vuetify.theme.themes.light.secondary, 0.3, 3);

            //Dont zoom in to the zone if it is indoor
            if ( location === LOCATION_TYPES.INDOOR ) {
              return;
            }
            if ( location === LOCATION_TYPES.OUTDOOR ) {
              //Fit polygon with specific padding in order to avoid full zoom in to zone
              fitPolygon(currentMap, zonePolygon.polygon, 125);
            }

          } else {
            updatePolygon(currentMap, zonePolygon.polygon, this.zoneLayerId);

            //Dont zoom in to the zone if it is indoor
            if ( location === LOCATION_TYPES.INDOOR ) {
              return;
            }
            if ( location === LOCATION_TYPES.OUTDOOR ) {
              //Fit polygon with specific padding in order to avoid full zoom in to zone
              fitPolygon(currentMap, zonePolygon.polygon, 125);
            }
          }
        }
      }, 250);
    },
    zoneCenter(zone){
      let coords = getCenterLatLongInPoly(zone.polygon);
      let str = ''
      if (coords) {
        str = `Lat ${coords.y.toString().slice(0,9)}, Lng ${coords.x.toString().slice(0,10)}`
      } else {
        str = 'Invalid Polygon'
      }
      return str;
    },
    handleAddOutdoorArea() {
      this.createEdit = CREATE;
      this.preselectedAreaType = LOCATION_TYPES.OUTDOOR;
      this.$refs.areaNewEditModal.openModal(null, this.currentSiteCopy);
    },
    handleAddIndoorArea() {
      this.createEdit = CREATE;
      this.preselectedAreaType = LOCATION_TYPES.INDOOR;
      this.$refs.areaNewEditModal.openModal(null, this.currentSiteCopy);
    },
    handleEditArea(area) {
      this.createEdit = EDIT;
      this.$refs.areaNewEditModal.openModal(area, this.currentSiteCopy);
    },
    handleDeleteArea(area){
      this.areaToDelete = area;
      this.confirmationDeleteAreaDialog = true;
    },
    async confirmAreaDeletion() {
     
      const { id, areaLocation } = this.areaToDelete;

      try {
        //Call service to delete area by id
        await this.deleteArea(id);
        
        this.$toasted.show(areaLocation === 'indoor' ? this.$t('floorplan-deleted') : this.$t('outdoor-area-deleted'), {
          position: 'bottom-right',
          className: ['toast-success'],
          duration: 2000,
        });

        this.confirmationDeleteAreaDialog = false;
        await this.handleZoneHasChanges();

      } catch (error) {
        this.confirmationDeleteAreaDialog = false;

        this.$toasted.show(error, {
          position: 'bottom-right',
          className: ['toast-error'],
          duration: 5000,
        });
      }
      
    },
    cancelAreaDeletion(){
      this.areaToDelete = null;
      this.confirmationDeleteAreaDialog = false;
    },
    closeMapsPanels() {
      this.mapsOpen = undefined;//Close all maps panels
      this.mapAreaOpen = undefined;//Close all maps areas panels
    },
    closeMapAreaPanel() {
      this.mapAreaOpen = undefined;
    },
    closeFloorplansPanels() {
      this.floorplansOpen = undefined;//Close all floorplans panels
      this.floorplanAreaOpen = undefined;//Close all floorplans areas panels
    },
    closeFloorplanAreaPanel() {
      this.floorplanAreaOpen = undefined;
    },
    handleMapAreaHeaderHover(area, i) {
      //We only run this if if we are mouse entering a header panel that is open
      if ( this.mapAreaOpen !== i ) {
        return;
      }
      this.areaPolygonHover('outdoor', area);
    },
    handleFloorplanAreaHeaderHover(area, i) {
      //We only run this if if we are mouse entering a header panel that is open
      if ( this.floorplanAreaOpen !== i ) {
        return;
      }
      if( area.image ) {
        clearLayers(this.mapIndoor);
        drawAreaIndoorPlan(this.mapIndoor, area.image);
      }
    },
    addZoneCategory() {
      this.$refs.zoneCategoryNewEditModal.openModal('create', this.currentSite.id);
    },
    async editZoneCategory() {
      await this.getZoneCategories({ siteId: this.currentSite.id });
      this.$refs.zoneCategoryNewEditModal.openModal('edit', this.currentSite.id);
    },
    async deleteZoneCategory() {
      await this.getZoneCategories({ siteId: this.currentSite.id });
      this.$refs.zoneCategoryNewEditModal.openModal('delete', this.currentSite.id);
    },
  }
};
</script>

<style scoped lang="scss">
.all-events {
  pointer-events: all;
}

#geofences {
  --max-height: calc(100vh - 200px);
}

.theme--light.v-expansion-panels .v-expansion-panel  {

  &:hover {
    background-color: var(--v-primaryLight-base);
  }
}
.geo-container {
  height: var(--max-height);
}
.accordion-scroll {
  max-height: var(--max-height);
  overflow-y: scroll;
}
.accordion-header {
  width: 90%;
}
.area-column {
  width: 25%;
  display: inline-block;
}
.area-outdoor-col {
  width: 33%;
  display: inline-block;
}
.area-header {
  display: flex;
  align-items: center;
  color: var(--v-primary-base);
  border-radius: 5px;
  text-align: left;
  font-weight: 600;
  min-height: 28px;

  p {
    margin: 0;
    max-width: 10ch;
    word-wrap: break-word;
  }
}
.area-value {
  margin-top: 4px;
  overflow-wrap: break-word;
}

.v-tooltip__content {
  &.custom-tooltip {
    padding: 0 6px;
    font-size: 11px;
  }
}

.add-geozone-btn {
  font-size: 12px !important;
  white-space: normal;
  line-height: 16px;
  padding: .5rem .25rem !important;

  @media only screen and (max-width: 1500px) {
    font-size: 10px !important;
  }
  &.fixed-h {
    @media only screen and (max-width: 1300px) {
      height: 50px !important;
      min-height: 50px !important;
      max-height: 50px !important;
    }
  }
}
.zone-item{
  display: flex;
  align-items: center;
  cursor: default;
  padding: 5px;

  &:hover{
    border-radius: 5px;
    background-color: #fff;
  }
}
.no-area-error{
  color: var(--v-error-base);
  font-size: .75rem;
  display: block;
}
.geofences-map {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  canvas {
    max-width: 100% !important;
  }
}
.show-map {
  z-index: 2;
}
.indoor-image-skeleton-loader {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 3;
  overflow: hidden;
  background: rgb(212, 212, 212);
  animation: fade-in-loader 400ms linear forwards;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -250px;
    top: -25%;
    height: 150%;
    width: 350px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    transform: rotate(10deg);
    animation: load 1200ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  @keyframes load {
    from {
      left: -550px;
    }
    to   {
      left: 100%;
    }
  }
  @keyframes fade-in-loader {
    from {
      opacity: 0;
    }
    to   {
      opacity: 1;
    }
  }
}
.geofence-actions-popup {
  float: right;
}

</style>