import axiosHelper from '../utils/axios';
import Tag from '../models/tag';
import TagV3 from '../models/tagv3'
import Summary from '../models/summary'

export default (() => {
	const networkAssetPath = "/networkAsset/airfinder/";
	const v2Path = '/v2';
	const v3Path = 'v3/';
	const v4Path = 'v4/';
	const v2_1Path = '/v2.1';

	const v4Orgs = [
		'c5c972a3-8a33-4bd9-b2f7-960f8bd15d40',
		'e6448f24-e971-4727-81ec-0fb3a933e8e0',
		'3a107d54-d958-49e9-abab-a99d4b1cca88',
	  ];

	async function getTagsBySite(params, firstPage) {
		let queryParams = `?siteId=${params.siteId}&groupBy=none`;
		if (firstPage){
			queryParams = `${queryParams}&page=1&size=${params.pagination.size}`
		}else if(params.areaId) {
			queryParams = `${queryParams}&page=${params.pagination.page}&size=${params.pagination.size}&areaId=eq:${params.areaId}`			
		}else {
			queryParams = `${queryParams}&page=${params.pagination.page}&size=${params.pagination.size}`
		}

		if(params.filters){
			queryParams += params.filters.replaceAll("None",null);
		}
		if(params.sorting){
			queryParams += params.sorting;
		}
		const response = await axiosHelper.getNetworkAsset(firstPage ? 'hideLoadingIndicator' : '').get(`${networkAssetPath}${v4Path}tags${queryParams}`);
		
		const totalTags = response.headers.total_site_records;
		const totalFilteredTags = response.headers.total_filtered_records ? response.headers.total_filtered_records : response.data.length;
		const tagsResponse = response.data.map(tag => new TagV3().normalize(tag));
		return { total: totalTags, filteredTotal: totalFilteredTags, tags: tagsResponse };
	}

	async function getAF3TagsInArea(params){
		let queryParams = `?siteId=${params.siteId}&areaName=eq:${params.areaName}&groupBy=none`;
		const response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}${v3Path}tags${queryParams}`);
		const tagsResponse = response.data.map(tag => new TagV3().normalize(tag));
		return tagsResponse;
	}

	async function getTagById(tagId){
		const response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}${v4Path}tag/${tagId}`);
        return new TagV3().normalize(response.data);
	}

	async function getTagsUrl(url){
		const response = await axiosHelper.getNetworkAsset().get(url);
        return response.data;
	}

	async function createTag(device) {
		const response = await axiosHelper.getNetworkAsset().post(`${networkAssetPath}tags`, device);
		return response;
	}
	async function editTag(device) {
		const response = await axiosHelper.getNetworkAsset().put(`${networkAssetPath}tag/${device.id}`, device);
		return response;
	}
	async function deleteTag(device) {
		const response = await axiosHelper.getNetworkAsset().delete(`${networkAssetPath}tag`,{ data: device });
		return response.data;
	}
	async function deleteTags(devices) {
		const response = await axiosHelper.getNetworkAsset().delete(`${networkAssetPath}tags`,{ data: devices });
		return response.data;
	}

	async function getHistory(params) {
		const showCellIds = ("showCellIds" in params) ? params.showCellIds : '';
		const response = await axiosHelper.getNetworkAsset()
			.get(`${networkAssetPath}device-location-history/${params.id}/${params.startDate}/${params.endDate}?showCellIds=${showCellIds}`);
		const cellId = response.headers.is_cell_id_configured ? response.headers.is_cell_id_configured : false;
		const historyResponse = response.data.map(event => new Tag().normalizeHistory(event));
		return {history: historyResponse.reverse(), cellId: cellId};
	}

	async function exportAllTags(params) {
		let queryParams = `?siteId=${params.siteId}${params.filters}&groupBy=none&format=csv`;
		
		const response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}${v4Path}tags${queryParams}`);
		return response;
	}

	async function getTagsSummary(siteId, attributes) {
		const response = await axiosHelper
		  .getNetworkAsset()
		  .get(`${networkAssetPath}summary?siteId=${siteId}&groupBy=${attributes}`);	

		const summaryResponse = response.data.map((summary, index) => new Summary().normalize(summary,index))

		return summaryResponse;
	}

	async function getBlueDotsIndoor(params) {		
		const response = await axiosHelper
		  .getNetworkAsset()
		  .get(`${networkAssetPath}${params.v3 ? v3Path : v4Path}tags/indoor/geojson?siteId=${params.siteId}&areaId=${params.areaId}${params.filters}&sortBy=lastEventTime&sort=dsc`);	

		return response.data;
	}

	return {
		getTagsBySite,
		getAF3TagsInArea,
		getTagById,
		getTagsUrl,
		createTag,
		editTag,
		deleteTag,
		getHistory,
		exportAllTags,
		deleteTags,
		getTagsSummary,
		getBlueDotsIndoor,
	}


})();