class Summary{
    normalize = (data, index) =>{
        return{
            id: index,
            areaId: data?.areaId,
            areaName: data?.areaName,
            categoryName: data?.categoryName,
            zoneName: data?.zoneName,
            groupName: data?.groupName,
            field1: data?.field1,
            field2: data?.field2,
            tagAmount: data?.tagAmount,
            checked: false
        }
    }
}

export default Summary;