export const WORKFLOWS = Object.freeze({
    TYPE_ENTRY: 'IN',
    TYPE_EXIT: 'NOT_IN',
    TYPE_DELIVERY: 'DELIVERY',
    SUBTYPE_DELIVERY: 'Delivery',
    TYPE_AUTOMATIC: 'AUTOMATIC',
    CRITERIA_ALL: 'ALL',
    CRITERIA_GROUP: 'GROUP',
    CRITERIA_CATEGORY: 'CATEGORY',
    CRITERIA_FIELD1: 'FIELD1',
    CRITERIA_FIELD2: 'FIELD2',
    REGIONS_AREA: 'AREA',
    REGIONS_ZONE: 'ZONE',
    REGIONS_LOCATION: 'LOCATION'
  });
  