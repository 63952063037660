import axiosHelper from '../utils/axios';
import TableauSubmenu from '../models/tableauSubmenu'

export default (() => {
    const networkAssetPath = "/networkAsset/tableau/";

    async function getWorkbooksBySiteId(siteId){
        let queryParams = `workbooks?siteId=${siteId}&projectId=4b345318-47ed-4a14-87ff-d101ded5dbbf&sortBy=name`;
        
        const response = await axiosHelper.getNetworkAsset().get(`${networkAssetPath}${queryParams}`);
        const mappedData = new TableauSubmenu().normalize(response.data.records);

        return mappedData;
    }
    
    return {
        getWorkbooksBySiteId
	}


})();