export const NODE_ADDRESS_PATTERN = "^(\\$.*\\$)(.*)$";

export const MAC_ADDRESS_PATTERN = "^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$";

export const MAC_ADDRESS_ACCESS_POINT_PATTERN = "^(((c|C)0:00:30|00:00:30)[:](0\\w)[:]\\w{2}[:]\\w{2})$";

//SAME REGEX USED IN THE BACKEND.
export const PHONE_PATTERN = /^(\+\d{1,3}( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4}$|^(\\+\d{1,3}( )?)?(\d{3}[ ]?){2}\d{3}$|^(\+\d{1,3}( )?)?(\d{3}[ ]?)(\d{2}[ ]?){2}\d{2}$/;

//VALIDATES STRINGS DONT CONTAIN SPACES
export const NO_SPACE_PATTERN = /^\S+$/;

//VALIDATE STRINGS NAME IN DEVICES
export const DEVICE_NAME_ASCII = "^[\x00-\x7F]+$";