import i18n from '../../i18n'
import ReplaceDevicesService from '../../services/replaceDevices';

const namespaced = true;

const state = {
	
}

const mutations = {

}

const getters = { }

const actions = {
	async replaceDevice({}, params){
		try {
			let resp = await ReplaceDevicesService.replaceDevice(params);
			return resp;
    } catch (e) {
      const errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
			throw errorMessage;
    }
	}
}

export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}