var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"geofences"}},[_c('v-row',[_c('v-col',{staticClass:"custom-card card-no-footer-height"},[_c('v-row',[_c('SearchBar',{attrs:{"id":'search-geozone',"cols":6,"placeholder":_vm.$t('geozone-search-placeholder'),"searchTerm":_vm.searchTerm},on:{"onSearchBarKeyUp":function($event){return _vm.searchChange($event)},"searchText":function($event){return _vm.searchChange($event)}}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"primary--text font-weight-bold ml-3 p-0 geofence-actions-popup",attrs:{"height":"32","width":"50","min-width":"50","depressed":true,"color":"primaryLight"}},[_c('actions-popup-component',{attrs:{"id":"geofence-options","closeDelay":'150',"iconColor":'var(--v-primary-base)',"actions":_vm.geofenceBtns}})],1)],1)],1),_c('v-row',{staticClass:"geo-container"},[_c('v-col',{staticClass:"accordion-scroll default-scroll-bar",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"disabled":!_vm.outdoorAreas.length,"flat":""},model:{value:(_vm.mapsOpen),callback:function ($$v) {_vm.mapsOpen=$$v},expression:"mapsOpen"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"},on:{"click":_vm.closeMapAreaPanel}},[_c('div',{staticClass:"d-flex justify-space-between align-center ml-5"},[_c('div',{staticClass:"fw-b"},[_vm._v(_vm._s(_vm.$t('maps')))]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"white","right":"","open-delay":"800","transition":"slide-x-transition","open-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 all-events",attrs:{"color":"white","tag":"span","x-small":"","fab":"","elevation":4},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddOutdoorArea($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t('add-area')))])])],1)]),_c('v-expansion-panel-content',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.mapAreaOpen),callback:function ($$v) {_vm.mapAreaOpen=$$v},expression:"mapAreaOpen"}},_vm._l((_vm.outdoorAreas),function(area,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"},on:{"click":function($event){_vm.mapAreaOpen !== i ? _vm.handleGetZones(area) : null},"mouseenter":function($event){return _vm.handleMapAreaHeaderHover(area, i)}}},[_c('v-row',{staticClass:"ml-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5 d-flex"}},[_c('div',{staticClass:"area-header"},[_c('p',{staticClass:"mr-2"},[_vm._v(_vm._s(area.name))]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"primary","right":"","open-delay":"200","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditArea(area)}}},on),[_c('v-icon',{attrs:{"size":"14px"}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit-area')))])]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"primary","right":"","open-delay":"200","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteArea(area)}}},on),[_c('v-icon',{attrs:{"size":"14px"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete-area')))])])],1)]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"area-header"},[_c('p',[_vm._v(_vm._s(_vm.$t('geozones')))])]),_c('div',{staticClass:"area-value"},[_vm._v(_vm._s(area.zoneCount))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"add-geozone-btn",attrs:{"id":"add-geozone","block":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('add-geozone'))},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddGeoZone(area)}}})],1)],1)],1),(Number(area.zoneCount) > 0)?_c('v-expansion-panel-content',[_c('div',{staticClass:"ml-4 mb-2"},[_c('div',{staticClass:"area-column w-50"},[_c('div',{staticClass:"area-header fs-12"},[_c('p',[_vm._v(_vm._s(_vm.$t('zone-name')))])])]),_c('div',{staticClass:"area-column w-50"},[_c('div',{staticClass:"area-header fs-12"},[_c('p',[_vm._v(_vm._s(_vm.$t('zone-center-point')))])])])]),(_vm.loadingZones)?_c('div',{staticClass:"mt-4"},_vm._l(([].concat( Array(3) )),function(zone,i){return _c('v-skeleton-loader',{key:i,staticClass:"ml-4 mb-4",attrs:{"loading":"","type":"text"}})}),1):_vm._l((_vm.outdoorZones),function(zone,i){return _c('div',{key:i,staticClass:"zone-item ml-4",on:{"mouseenter":function($event){return _vm.zonePolygonHover('outdoor', zone)},"mouseleave":function($event){_vm.hoveredPolygon = ''}}},[_c('div',{staticClass:"d-flex w-50 pr-2"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(zone.name))]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"primary","right":"","open-delay":"200","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleZoneClick(zone)}}},on),[_c('v-icon',{attrs:{"size":"14px"}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit-geozone')))])])],1),_c('div',{staticClass:"w-50"},[_c('div',{staticClass:"area-value fs-12"},[_vm._v(_vm._s(_vm.zoneCenter(zone)))])])])})],2):_vm._e()],1)}),1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"disabled":!_vm.indoorAreas.length,"flat":""},model:{value:(_vm.floorplansOpen),callback:function ($$v) {_vm.floorplansOpen=$$v},expression:"floorplansOpen"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"},on:{"click":_vm.closeFloorplanAreaPanel}},[_c('div',{staticClass:"d-flex justify-space-between align-center ml-5"},[_c('div',{staticClass:"fw-b"},[_vm._v(_vm._s(_vm.$t('floorplans')))]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"white","right":"","open-delay":"800","transition":"slide-x-transition","open-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 all-events",attrs:{"color":"white","tag":"span","x-small":"","fab":"","elevation":4},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddIndoorArea($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t('add-floorplan')))])])],1)]),_c('v-expansion-panel-content',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.floorplanAreaOpen),callback:function ($$v) {_vm.floorplanAreaOpen=$$v},expression:"floorplanAreaOpen"}},_vm._l((_vm.indoorAreas),function(area,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"},on:{"click":function($event){_vm.floorplanAreaOpen !== i ? _vm.handleGetZones(area) : null},"mouseenter":function($event){return _vm.handleFloorplanAreaHeaderHover(area, i)}}},[_c('v-row',{staticClass:"ml-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5 d-flex"}},[_c('div',{staticClass:"area-header"},[_c('p',{staticClass:"mr-2"},[_vm._v(_vm._s(area.name))]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"primary","right":"","open-delay":"200","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditArea(area)}}},on),[_c('v-icon',{attrs:{"size":"14px"}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit-floorplan')))])]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"primary","right":"","open-delay":"200","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteArea(area)}}},on),[_c('v-icon',{attrs:{"size":"14px"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete-floorplan')))])])],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"area-header"},[_c('p',[_vm._v(_vm._s(_vm.$t('zones')))])]),_c('div',{staticClass:"area-value"},[_vm._v(_vm._s(area.zoneCount))])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"add-geozone-btn fixed-h",attrs:{"id":"add-geozone","block":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('manage-floorplan-zones'))},on:{"click":function($event){$event.stopPropagation();return _vm.handleManageFloorplanZones(area)}}})],1)],1)],1),(Number(area.zoneCount) > 0)?_c('v-expansion-panel-content',[_c('div',{staticClass:"ml-4 mb-2"},[_c('div',{staticClass:"area-column w-50"},[_c('div',{staticClass:"area-header fs-12"},[_c('p',[_vm._v(_vm._s(_vm.$t('zone-name')))])])])]),(_vm.loadingZones)?_c('div',{staticClass:"mt-4"},_vm._l(([].concat( Array(3) )),function(zone,i){return _c('v-skeleton-loader',{key:i,staticClass:"ml-4 mb-4",attrs:{"loading":"","type":"text"}})}),1):_vm._l((_vm.indoorZones),function(zone,i){return _c('div',{key:i,staticClass:"zone-item ml-4",on:{"mouseenter":function($event){return _vm.zonePolygonHover('indoor', zone)},"mouseleave":function($event){_vm.hoveredPolygon = ''}}},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(zone.name))]),_c('v-tooltip',{attrs:{"content-class":"custom-tooltip","color":"primary","right":"","open-delay":"200","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleZoneClick(zone)}}},on),[_c('v-icon',{attrs:{"size":"14px"}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit-zone')))])])],1)})],2):_vm._e()],1)}),1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"p-relative",attrs:{"cols":"6"}},[(_vm.loadingZones && _vm.preselectedAreaType === 'indoor')?_c('div',{staticClass:"indoor-image-skeleton-loader"}):_vm._e(),_c('mapGL',{key:"indoor-map",staticClass:"geofences-map",class:{ 'show-map': _vm.preselectedAreaType === 'indoor' || _vm.preselectedAreaType === '' },attrs:{"id":"indoorMap","styleType":"clear","accessToken":'VUE_APP_SUPERTAG_MAPBOX_ACCESS_TOKEN'},on:{"onLoad":_vm.onLoadIndoor}}),_c('mapGL',{key:"outdoor-map",staticClass:"geofences-map",class:{
              'show-map': _vm.preselectedAreaType === 'outdoor'
              || _vm.preselectedAreaType === ''
              || !_vm.indoorAreas.find(function (n) { return n.image; })
            },attrs:{"id":"geozonesMap","styleType":"default","accessToken":'VUE_APP_SUPERTAG_MAPBOX_ACCESS_TOKEN'},on:{"onLoad":_vm.onLoad}})],1)],1)],1)],1),_c('ZoneModal',{ref:"mapZonePolygonUpload",attrs:{"areas":_vm.preselectedAreaType === 'outdoor' ? _vm.outdoorAreas: _vm.indoorAreas,"selectedZone":_vm.zoneSelected},on:{"hasChanges":_vm.handleZoneHasChanges}}),_c('ManageDevices',{ref:"locationAccessGateway",attrs:{"areas":_vm.indoorAreas}}),_c('AreaNewEdit',{ref:"areaNewEditModal",attrs:{"preSetAreaType":_vm.preselectedAreaType,"modalMode":_vm.createEdit},on:{"areaCreatedEdited":_vm.handleZoneHasChanges}}),_c('v-dialog',{attrs:{"max-width":"450"},on:{"click:outside":_vm.cancelAreaDeletion,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancelAreaDeletion($event)}},model:{value:(_vm.confirmationDeleteAreaDialog),callback:function ($$v) {_vm.confirmationDeleteAreaDialog=$$v},expression:"confirmationDeleteAreaDialog"}},[_c('v-card',[(_vm.areaToDelete)?_c('v-card-title',{staticClass:"error lighten-1"},[_vm._v(_vm._s(_vm.areaToDelete.areaLocation === 'indoor' ? _vm.$t('delete-floorplan') : _vm.$t('delete-area')))]):_vm._e(),(_vm.areaToDelete)?_c('v-card-text',{staticClass:"mt-3 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('delete-are-you-sure', { name: _vm.areaToDelete.name })))]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary--text font-weight-bold",attrs:{"height":"32","text":"","color":"error"},on:{"click":_vm.cancelAreaDeletion}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{staticClass:"px-8 float-right",attrs:{"depressed":"","color":"error lighten-1"},on:{"click":_vm.confirmAreaDeletion}},[_vm._v(_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1),_c('ZoneCategoryNewEdit',{ref:"zoneCategoryNewEditModal",attrs:{"modalMode":'create'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }