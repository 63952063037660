<template>
  <div>
    <img
      v-if="params[field] !== null && params[field] !== -1"
      class="battery"
      v-bind:src="icon"
      :title="description"
    />
    <span v-else :title="$t('battery-not-seen-message')">-- --</span>
  </div>
</template>

<script>
import { WARNING_DAYS } from '../../../../constants/warning-days'
export default {
  data() {
    return {
      icon: null,
      description: '',
      batteryFull: require('../../../../assets/img/aggrid/battery-full.png'),
      batteryLow: require('../../../../assets/img/aggrid/battery-low.png'),
      batteryHalf: require('../../../../assets/img/aggrid/battery-half.png'),
      batteryFullQuestion: require('../../../../assets/img/aggrid/battery-full-question.png'),
      batteryLowQuestion: require('../../../../assets/img/aggrid/battery-low-question.png'),
      batteryHalfQuestion: require('../../../../assets/img/aggrid/battery-half-question.png')
    }
  },
  props: ['params', 'field'],
  watch:{
    params(){
      this.getBatteryIcon(this.params[this.field]);
    }
  },
  created() { 
    this.getBatteryIcon(this.params[this.field]);
  },
  methods: {
    getBatteryIcon(id) {
      let tagDate = new Date(this.params.lastEventTime);
      let useQuestionMark = false;
      if (this.params.notSeenInDays >= WARNING_DAYS.MIN) {
        useQuestionMark = true;
      }
      switch (id) {
        case 0:
          this.icon = useQuestionMark ? this.batteryLowQuestion : this.batteryLow;
          this.description = useQuestionMark ? this.$t('battery-low-not-seen-message') : this.$t('battery-low-message');
          break;
        case 1:
          this.icon = useQuestionMark ? this.batteryFullQuestion : this.batteryFull;
          this.description = useQuestionMark ? this.$t('battery-full-not-seen-message') : this.$t('battery-full-message');
          break;  
        case 2:
          this.icon = useQuestionMark ? this.batteryHalfQuestion : this.batteryHalf;
          this.description = useQuestionMark ? this.$t('battery-half-not-seen-message') : this.$t('battery-half-message');
          break;
      }
    }
  }
};
</script>