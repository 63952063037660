<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent>
      <v-card id="mapPolygonZoneCreate">
        <v-card-title class="secondary">
          <div v-if="areas && areas.length">
            <v-autocomplete
              dark
              class="area-selector"
              id="selectArea"
              :items="areas"
              item-text="name"
              v-model="selectedAreaId"
              @change="selectArea"
              item-value="id"
              :label="$t('area')"
              return-object
              outlined
              dense
            >
            </v-autocomplete>
          </div>
          <div v-if="area && !areas">
            {{ $t('area') }}:
            <b>{{ area.name }}</b>
          </div>
          <div class="ml-2" v-if="area && area.site">
            | {{ $t('site') }}:
            <b>{{ area.site.value }}</b>
          </div>
        </v-card-title>
        <v-card-text
          v-bind:class="{ 'map-loaded': mapLoaded }"
          class="map-container"
        >
          <v-row class="h-100 w-100 m-0">
            <v-col class="search-column p-relative" v-if="area && area.areaLocation === 'outdoor'">
              <v-row justify="center">
                <v-col cols="11">
                  <div class="h-100">
                    <div class="add-geozone-title mb-5">{{ $t('add-geozone') }}</div>
                    <div id="geoCoder" ref="geoCoderRef"></div>
                    <p class="mb-1 ml-2" v-if="form.installedLatitude">
                      <b>{{ $t('lb-latitude') }}:</b>
                      <span class="secondary--text">{{
                        form.installedLatitude
                      }}</span>
                    </p>
                    <p class="ml-2 mb-0" v-if="form.installedLongitude">
                      <b>{{ $t('lb-longitude') }}:</b>
                      <span class="secondary--text">{{
                        form.installedLongitude
                      }}</span>
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-snackbar
                v-model="showDrawZoneAlert"
                absolute top right
                color="primary"
                timeout="4000"
              >
                <v-btn
                  @click="showDrawZoneAlert = false"
                  text color="white" icon small
                  class="custom-btn mr-1"
                >
                  X
                </v-btn>
                {{$t('required-zone-draw')}} <span class="finger-span ml-2">👉</span>
              </v-snackbar>
              <v-card-text v-if="invalidPolygon" class="invalid-polygon custom-invalid-polygon" >{{$t('invalid-zone')}}</v-card-text>
              <v-row> 
                  <v-col class="pl-7 pr-6" cols="12">
                   <v-autocomplete
                    class="table-style"
                    v-model="select"
                    cache-items
                    dense
                    solo                    
                    hide-selected
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"                                   
                    item-text="name"
                    item-value="id"
                    :placeholder="$t('search-tag-name')"                 
                    @change="onSelectedTag"
                    return-object
                  >
                   <template v-slot:append-item>
                  <div v-intersect="onIntersect"></div>
                  </template>
                  </v-autocomplete>
                  </v-col>
              </v-row>             
            </v-col>
            <v-col
              class="px-0 py-0"
              :cols="area && area.areaLocation === 'outdoor' ? '7' : '12'"
              :lg="area && area.areaLocation === 'outdoor' ? '9' : '12'"
              :md="area && area.areaLocation === 'outdoor' ? '8' : '12'"
            >
              <MapGrid
                class="grid-control"
                v-if="area && indoorMap && area.site && area.site.isAf3"
                :areaFileMapping="indoorMap"
                :draggable="true"
                :map="map"
                :mapLoaded="mapLoaded"
                :selectedFloor="area.id"
              />

              <div class="w-100 h-100" ref="zoneMapboxMap"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-actions">
          <v-col cols="10">
            <v-form class="zone-form" @submit.stop.prevent="handleSave(null)" id="zoneNameEditForm">
              <span class="d-flex">
                <v-text-field
                  ref="zoneNameInput"
                  :label="$t('name')"
                  tabindex="1"
                  :dense="true"
                  v-model="form.name"
                  outlined
                  class="zone-name"
                  :error-messages="nameErrors"
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                  color="secondary"
                ></v-text-field>
                <v-autocomplete
                  class="d-flex"
                  :items="zoneCategories"
                  item-text="value"
                  item-value="id"
                  :label="$t('zone-category')"
                  tabindex="1"
                  :dense="true"
                  :clearable="true"
                  v-model="form.categoryId"
                  outlined
                  color="secondary"
                ></v-autocomplete>
                <v-btn
                  color="secondary"
                  rounded
                  x-small
                  class="p-0 add-btn"
                  @click="addZoneCategory"
                  depressed
                >
                  <v-icon v-text="'mdi-plus'"></v-icon>
                </v-btn>
                </span>

                  <v-btn
                    depressed
                    id="saveZoneBtn"
                    color="secondary"
                    tabindex="2"
                    @click="handleSave(null)"
                    :disabled="invalidPolygon"
                    class="px-8 save-zone-btn"
                  >{{$t('save')}}</v-btn>

                  <v-btn
                    depressed
                    id="deleteZoneBtn"
                    color="error"
                    tabindex="3"
                    @click="handleDeleteZone"
                    :disabled="!currentZone || invalidPolygon"
                    class="px-8 ml-2 delete-zone-btn"
                  >{{$t('delete')}}</v-btn>
            </v-form>
          </v-col>
          <v-col cols="2">
            <v-btn
              text
              id="zoneUploadMapPolygonBtnCancel"
              color="secondary"
              tabindex="4"
              @click="closeModal"
              class="px-8 float-right"
              >{{ $t('close') }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <warn-modal
      @save="handleSave"
      @undo="handleUndo"
      :invalidPolygon="invalidPolygon"
      :zoneName="form.name"
      ref="warnModal"
    />
    <confirm-modal
      @confirm="confirmDeleteZone"
      @cancel="confirmationDeleteDialogZone = false"
      :dialogModel="confirmationDeleteDialogZone"
      :dialogBody="$t('delete-are-you-sure', { name: form.name })"
      :dialogTitle="$t('delete-zone')"
    />
    <confirm-modal
      @confirm="confirmCloseModal"
      @cancel="confirmationCloseDialog = false"
      :dialogModel="confirmationCloseDialog"
      :dialogBody="$t('unsaved-changes-are-you-sure')"
      :dialogTitle="$t('unsaved-changes')"
    />
    <ZoneCategoryNewEdit
      :modalMode="'create'"
      @finished="refreshZoneCategories"
      ref="zoneCategoryNewEditModal"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import SearchBar from '../../components/search-bar/search-bar';
import ZoneConfirmChanges from './zone-polygon-warn-dialog';
import ConfirmDialog from '../shared/modals/confirmModal';
import MapGrid from '../shared/af3-map-tools/af3-map-tools';
import { validationMixin } from 'vuelidate';
import {
  fitPolygon,
  getMapStyles,
  getMapInitOptions,
  getIndoorMapInitOptions,
  pointsToString,
  addPolygonsSourceToMap,
  addPolygonToMap,
  addFillLayerToMap,
  addLayerNameToMapForZones,
  addFillPolygonAndLineToMap,
  pointsInsidePolygon,
  drawAreaIndoorPlan,
  coordinatesGeocoder,
} from '../../utils/map';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import AuthService from '../../services/auth';
import ZoneCategoryNewEdit from '../../components/zones/zone-category-new-edit.vue';

const networkAssetPath = `${process.env.VUE_APP_NETWORK_ASSETS_URL}/networkAsset/airfinder/`;

export default {
  data() {
    return {
      descriptionLimit: 60,
      isLoading: false,
      select: null,
      search: null,
      iconCrosshair: require('../../assets/img/map/crosshair.png'),
      foundTag: false,
      cleanText: true,
      tagSelected: null,
      searchText: '',
      map: null,
      mapBoxDraw: null,
      dialog: false,
      mapLoaded: false,
      dirty: false,
      area: null,
      zoneHasChanges: false,
      indoorMap: null,
      currentZone: null,
      invalidPolygon: false,
      confirmationDeleteDialogZone: false,
      confirmationCloseDialog: false,
      zoneSourceUrl: null,
      currentMode: '',
      geoCoder: null,
      geojson: {},
      geojsonTag: {},
      location: null,
      tagName:'',
      page:0,
      pagination: {
        size: 5,
        page: 1,
      },
      mapPagination: {
        size: 50,
        page: 1
      },
      form: {
        categoryId: '',
        name: '',
        installedLatitude: '',
        installedLongitude: '',
      },
      zonePolygonsSourceId: 'zone-polygons-airfinder',
      zonePolygonsLayerId: '',
      selectedAreaId: '',
      areaChangedFromSelect: false,
      showDrawZoneAlert: false,
    };
  },
  props:['areas', 'selectedZone'],
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  components: {
    MapGrid,
    SearchBar,
    ZoneCategoryNewEdit,
    'warn-modal': ZoneConfirmChanges,
    'confirm-modal': ConfirmDialog,
   // 'search-bar': SearchBar,
  },
  watch: {
    search(val) {
      val && val !== this.select && this.searchTyped(val)
    }, 
  },
  computed: {
    ...mapGetters('site', ['currentSite', 'currentSiteId']),
    ...mapGetters('tag', ['tags', 'totalRows', 'filteredTotalRows']),    
    ...mapGetters('zone', ['zoneCategories']),

    items () {
      return this.tags.map(entry => {
        const Description = entry.name.length > this.descriptionLimit
          ? entry.name.slice(0, this.descriptionLimit) + '...'
          : entry.name

        return Object.assign({}, entry, { Description })
      })
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required &&
        errors.push(this.$t('error-name-required'));
      return errors;
    },
    pageCount() {
      return Math.ceil(this.filteredTotalRows / this.mapPagination.size);
    },
  },
  
  methods: {
    ...mapActions(['logout']),
    ...mapActions('zone', [
      'createZone',
      'editZone',
      'deleteZone',
      'getZoneById',
      'getZoneCategories'
    ]),
    ...mapActions('tag', ['getTags', 'getTag']),
    ...mapActions('area', ['getAreaIndoorFile', 'getAF3Points']),
    ...mapActions('location', ['editLocations']),
    ...mapActions('gateway', ['editGateways']),
    ...mapActions('accessPoint', ['editAccessPoints']),
    ...mapMutations('tag', ['clearTags']),
     
    onIntersect () {
      console.log('load more...')
      this.page += 1
      this.loadMoreTags({page: this.page, pageSize: this.mapPagination.size });
    },

    async loadMoreTags(pagination){
      this.mapPagination.page = pagination.page;
      
      this.getTags({
        siteId: this.area.site.id,
        pagination: this.mapPagination,
        filters: this.searchText,
        areaId: this.area.id,
      });
    },
    openModal(area) {
      this.areaChangedFromSelect = false;

      this.getTags({
        siteId: area?.site.id,
        pagination: this.mapPagination,
        filters: this.searchText,
        areaId: area?.id
      });

      this.geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: null,
            },
          },
        ],
      };
      this.invalidPolygon = false;
      this.dialog = true;
      this.area = area;
      
      this.selectedAreaId = this.area.id;
      this.zoneSourceUrl = `${networkAssetPath}zones/geojson?areaId=${this.area.id}`;
      this.resetForm();
      if ((area && area.areaLocation === 'outdoor')) {
        //because of the map being inside a vuetify dialog, the map init needs to be wrapped by a timeout
        setTimeout(() => {
          this.initMap();
        }, 0);
      } else if (area && area.areaLocation === 'indoor') {
        if (area.indoorMapping) {
          this.initIndoorMap(area);
        }
      }
    },
    selectArea(area) {

      if (this.map) {
        this.map.remove();
      }

      this.areaChangedFromSelect = true;
      this.area = area;
      this.area.site = this.currentSite;
      this.invalidPolygon = false;
      this.zoneSourceUrl = `${networkAssetPath}zones/geojson?areaId=${this.area.id}`;
      this.resetForm();
      if ((area && area.areaLocation === 'outdoor')) {
        setTimeout(() => {
          this.initMap();
        }, 0);
      } else if (area && area.areaLocation === 'indoor') {
        if (area.indoorMapping) {
          this.initIndoorMap(area);
        }
      }
    },
    onSelectedTag(value) {
      this.addTagPoint(value);
    },
    searchTyped(text) {
      this.isLoading = true;
      try {
        if (text.trim()) {
        this.tagName = text;
        this.searchText = '&search=' + text;

        this.getTags({
          siteId: this.area.site.id,
          pagination: this.mapPagination,
          filters: this.searchText,
          areaId: this.area.id
        });
      }
      } catch (error) {
        throw console.log(error);
      } finally {
        this.isLoading = false
        this.foundTag = true;
        this.cleanText = true;
      }
      
    },
    clearSearchClicked() {
      this.resetForm();
    },
    async initIndoorMap(area) {
      try {
        const indoorMapImage = await this.getAreaIndoorFile({
          areaId: area.id,
        });
        this.indoorMap = indoorMapImage;
        this.initMap();
      } catch (error) {
        throw new Error(error);
      }
    },
    async mapErrorHandler(e) {
      if (
        e.sourceId === this.zonePolygonsSourceId &&
        e.error.url &&
        e.error.url.toLowerCase().indexOf(this.zoneSourceUrl.toLowerCase()) > -1
      ) {
        try {
          await AuthService.refreshToken();
          this.deletePreviousDraw();
          this.loadZones();
        } catch (err) {
          this.logout({ vuetify: this.$vuetify });
        }
      }
    },
    mapSourceRequestHandler(url, resourceType) {
      if (
        resourceType === 'Source' &&
        url.toLowerCase().indexOf(this.zoneSourceUrl.toLowerCase() > -1)
      ) {
        const authData = AuthService.checkLoggedIn();
        const accessToken = authData.access_token;
        return {
          url: url,
          headers: { authorization: `Bearer ${accessToken}` },
        };
      }
    },
    addTagPoint(tag) {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN || '';

      let options = null;
      options = getMapInitOptions(this.$refs.zoneMapboxMap);

      const mapOptions = Object.assign(
        { transformRequest: this.mapSourceRequestHandler },
        options
      );
      this.map = new mapboxgl.Map(mapOptions);

      this.geojsonTag = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [tag.longitude, tag.latitude],
            },
            properties:{
              title: tag.name,
            },
          },
        ],
      };


      this.map.on('load', () => {
        // Add an image to use as a custom marker
        this.map.loadImage(this.iconCrosshair, (error, image) => {
          if (error) throw error;
          this.map.addImage('crosshair', image);

          this.map.addSource('points', {
            type: 'geojson',
            data: this.geojsonTag,
          });

          // Add a symbol layer
          this.map.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'points',
            layout: {
              'icon-image': 'crosshair',
              // get the title name from the source's "title" property
              'text-field': ['get', 'title'],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-offset': [0, 1.25],
              'text-anchor': 'top',
            },
          });
        });
      });

       this.map.flyTo({
        center: this.geojsonTag.features[0].geometry.coordinates,
        zoom: 14,
      });
      this.map.on(
        'load',
        function () {
          this.onLoad();
        }.bind(this)
      );
    },
    initMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN || '';

      let options = null;
      if (this.area && this.area.areaLocation === 'indoor') {
        options = getIndoorMapInitOptions(this.$refs.zoneMapboxMap);
      } else {
        options = getMapInitOptions(this.$refs.zoneMapboxMap);

        if (!this.geoCoder || !this.geoCoder.container) {
          this.geoCoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            marker: false,
            localGeocoder: coordinatesGeocoder,
          });
          this.geoCoder.addTo('#geoCoder');
        } else {
          this.$refs.geoCoderRef.childElementCount === 0 && this.geoCoder.addTo('#geoCoder');
        }
        this.geoCoder.on('result', (result) => {
          // Update the Point feature in `geojson` coordinates
          this.geojson.features[0].geometry.coordinates = [
            result.result.geometry.coordinates[0],
            result.result.geometry.coordinates[1],
          ];
          this.form.installedLongitude = result.result.geometry.coordinates[0];
          this.form.installedLatitude = result.result.geometry.coordinates[1];
          this.addPoint();
        });
      }
      const mapOptions = Object.assign(
        { transformRequest: this.mapSourceRequestHandler },
        options
      );
      this.map = new mapboxgl.Map(mapOptions);
      this.map.loadImage(this.iconCrosshair, (error, image) => {
        if (error) throw error;
        this.map.addImage('crosshair', image);
      });
      this.map.on(
        'load',
        function () {
          this.onLoad();
        }.bind(this)
      );
    },
    addPoint() {
      if (!this.map.getSource('point')) {
        // Add a single point to the map
        this.map.addSource('point', {
          type: 'geojson',
          data: this.geojson,
        });

        this.map.addLayer({
          id: 'point',
          type: 'symbol',
          source: 'point',
          layout: {
            'icon-image': 'crosshair',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        });
      } else {
        //call setData to the source layer `point` on it.
        this.map.getSource('point').setData(this.geojson);
      }
      this.map.flyTo({
        center: this.geojson.features[0].geometry.coordinates,
        zoom: 17,
      });
    },
    onLoad() {
      if (this.area.areaLocation === 'indoor') {
        drawAreaIndoorPlan(this.map, this.indoorMap);
      }
      this.mapBoxDraw = new MapboxDraw({
        displayControlsDefault: false,
        points: '',
        controls: {
          polygon: true,
        },
        styles: getMapStyles(
          this.area.id,
          this,
          this.$vuetify.theme.themes.light.success
        ),
      });
      this.map.on('click', this.handleMapClick);
      this.map.on('error', this.mapErrorHandler);
      this.map.addControl(this.mapBoxDraw, 'top-left');
      this.map.addControl(new mapboxgl.NavigationControl());
      this.map.on('draw.create', this.handleUpdateZone);
      this.map.on('draw.update', this.handleUpdateZone);
      this.map.on('draw.modechange', (ev) => {
        this.currentMode = ev.mode;
        this.deletePreviousDraw();
        if ( ev.mode === 'draw_polygon') {
          this.currentZone = null;
          this.resetForm();
        }
      });
      if (this.area.areaLocation === 'outdoor' && Object.keys(this.geojsonTag).length === 0) {
        let scaleControl = new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: 'metric',
        });
        this.map.addControl(scaleControl);
        this.loadOutdoorArea(this.area.id, this.area.polygon);
      }
      this.loadZones();
      if ( this.selectedZone && !this.areaChangedFromSelect) {
        this.preselectZoneToEdit();
      }

      /* Add class dynamically to draw button to change it text by css content property */
      let drawButton = document.querySelector('[title="Polygon tool (p)"]')

      if ( drawButton && this.area) {
        if( this.area.areaLocation === 'outdoor') {
          drawButton.classList.add('draw-geo-zone')
        } else {
          drawButton.classList.remove('draw-geo-zone')
        }
      }
    },
    loadOutdoorArea(id, polygon) {
      addPolygonToMap(this.map, polygon, id);
      addFillLayerToMap(
        this.map,
        id,
        this.$vuetify.theme.themes.light.secondary
      );
      fitPolygon(this.map, polygon);
    },
    deletePreviousDraw() {
      if (this.mapBoxDraw.getAll().features.length > 1) {
        this.mapBoxDraw.delete(this.mapBoxDraw.getAll().features[0].id);
        this.zoneHasChanges = false;
      }
    },
    async loadZones() {
      if (this.map.getSource(this.zonePolygonsSourceId)) {
        this.mapBoxDraw.deleteAll();
        this.map.removeLayer(this.zonePolygonsSourceId);
        this.map.removeLayer(this.zonePolygonsLayerId);
        this.map.removeLayer(`fill-${this.zonePolygonsSourceId}`);
        this.map.removeSource(this.zonePolygonsSourceId);
      }
      const sourceId = addPolygonsSourceToMap(
        this.map,
        this.zoneSourceUrl,
        this.zonePolygonsSourceId
      );
      this.zonePolygonsLayerId = addFillPolygonAndLineToMap(
        this.map,
        sourceId,
        this.$vuetify.theme.themes.light.primary,
        3
      );
      addLayerNameToMapForZones(this.map, this.zonePolygonsSourceId);
      this.mapLoaded = true;
    },
    async preselectZoneToEdit() {
      const zoneId = this.selectedZone.id;
      const zone = await this.getZoneById(zoneId);

      this.currentZone = zone;
      this.form.name = this.currentZone.name;
      this.mapBoxDraw.add({
        type: 'Polygon',
        coordinates: [this.currentZone.polygon],
      });

      //Move green layer draw to top.
      this.map.getStyle().layers.map(layer => {
        if (layer.id?.includes('draw-polygon')) {
          this.map.moveLayer(layer.id, this.zonePolygonsSourceId);
        }
      });

      //Fit zone to the map with a padding.
      fitPolygon(this.map, this.currentZone.polygon, 125);
      // 👇 This goes if its decided to only zoom to outdoors zones.
      // if( this.area && this.area.areaLocation === LOCATION_TYPES.OUTDOOR){
      //   fitPolygon(this.map, this.currentZone.polygon, 125);
      // }
    },
    async handleMapClick(ev) {
      if (this.currentMode === 'draw_polygon') {
        return;
      }
      const features = this.map.queryRenderedFeatures(ev.point);
      const zoneFeatures = features.filter((f) =>
        f.layer.id.includes(this.zonePolygonsSourceId)
      );
      if (zoneFeatures && zoneFeatures[0] && zoneFeatures[0].properties) {
        const zone = await this.getZoneById(zoneFeatures[0].properties.id);
        let isZone = zoneFeatures[0]?.geometry?.coordinates[0]?.length > 0;
        if (!isZone) {
          this.mapBoxDraw.deleteAll();
          return;
        }
        if (!this.currentZone) {
          this.currentZone = zone;
          this.form.name = this.currentZone.name;
          this.form.categoryId = this.currentZone.zoneCategoryId || null;
          this.mapBoxDraw.add({
            type: 'Polygon',
            coordinates: [this.currentZone.polygon]
          });
        } else if (this.zoneHasChanges) {
           this.$refs.warnModal.openModal();
        } else {
          this.currentZone = zone;
          this.mapBoxDraw.deleteAll();
          this.mapBoxDraw.add({
            type: 'Polygon',
            coordinates: [this.currentZone.polygon], //zoneFeatures[0]?.geometry?.coordinates[0]
          });
          this.form.name = this.currentZone.name;
          this.form.categoryId = this.currentZone.zoneCategoryId || null;
          this.mapBoxDraw.changeMode('direct_select', {featureId: this.mapBoxDraw.getAll().features[0].id})
        }
      }
      this.deletePreviousDraw();
      this.map.getStyle().layers.map(layer => {
        if (layer.id?.includes('draw-polygon')) {
          this.map.moveLayer(layer.id, this.zonePolygonsSourceId);
        }
      });
    },
    handleUpdateZone(ev) {
      this.zoneHasChanges = true;
      this.invalidPolygon = false;
      if (ev.type === 'draw.update') {
        if (this.currentZone) {
          this.form.name = this.currentZone.name;
        }
      } else if (ev.type === 'draw.create') {
        this.currentZone = null;
        this.resetForm();
      }
      if (this.area && this.area.areaLocation === 'outdoor') {
        pointsInsidePolygon(
          this.getCurrentDrawingZoneCoordinates(),
          this.area.polygon
        )
          ? (this.invalidPolygon = false)
          : (this.invalidPolygon = true);
      }
      this.$refs.zoneNameInput.focus();
    },
    handleDeleteZone() {
      this.confirmationDeleteDialogZone = true;
    },
    handleSave(name) {
      if (name) {
        this.form.name = name;
      }
      if (this.currentZone) {
        this.handleEditZone();
      } else {
        this.handleAddZone();
      }
    },
    getCurrentDrawingZoneCoordinates() {
      let zones = this.mapBoxDraw.getAll();
      if (
        zones &&
        zones.features &&
        zones.features[0] &&
        zones.features[0].geometry &&
        zones.features[0].geometry.coordinates
      ) {
        return (
          zones.features[0] &&
          zones.features[0].geometry &&
          zones.features[0].geometry.coordinates[0]
        );
      }
    },
    getCurrentDrawingZoneFeature() {
      let zones = this.mapBoxDraw.getAll();
      if (zones && zones.features && zones.features[0]) {
        return zones.features[0];
      }
    },
    handleUndo() {
      this.mapBoxDraw.deleteAll();
      this.zoneHasChanges = false;
      this.invalidPolygon = false;
      this.$v.form.$reset();
      this.currentZone = null;
    },
    async handleAddZone() {
      this.$v.$touch();
      if ( !this.zoneHasChanges ) {
        this.showDrawZoneAlert = true;
      }
      if (this.$v.form.$anyError) {
        return;
      }
      if (!this.invalidPolygon && this.zoneHasChanges) {
        let payload = {
          configValue: this.form.name.trim(),
          properties: {
            points: pointsToString(this.getCurrentDrawingZoneCoordinates()),
            areaId: this.area.id,
            siteId: this.area.site ? this.area.site.id : this.currentSiteId,
            zoneCategoryId: this.form.categoryId
          },
        };
        try {
          const newZone = await this.createZone(payload);
          const newZoneFeature = {
            id: newZone.id,
            type: 'Feature',
            properties: {
              id: newZone.id,
              name: newZone.name,
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [newZone.polygon]
            }
          }
          if (this.area.areaLocation === 'indoor' && (this.area.locations || (this.area.site.gateways && this.area.site.gateways.length > 0) || (this.area.site.accessPoints && this.area.site.accessPoints.length > 0))) {
            await this.updateDevices(
              newZone.id,
              newZoneFeature
            );
          }
          this.$toasted.show(this.$t('zone-created'), {
            position: 'bottom-right',
            className: ['toast-success'],
            duration: 2000,
          });
          this.loadZones();
          this.currentZone = null;
          this.zoneHasChanges = false;
          this.dirty = true;
          this.resetForm();
        } catch (error) {
          this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
        }
      }
    },
    async handleEditZone() {
      if (this.$v.form.$anyError) {
        return;
      }
      if (!this.invalidPolygon) {
        let payload = {
          data: {
            configValue: this.form.name,
            name: this.form.name,
            points: pointsToString(this.getCurrentDrawingZoneCoordinates()),
            zoneCategoryId: this.form.categoryId || null
          },
          id: this.currentZone.id,
        };
        try {
          await this.editZone(payload);
          if (this.area.areaLocation === 'indoor' && (this.area.locations || (this.area.site.gateways && this.area.site.gateways.length > 0) || (this.area.site.accessPoints && this.area.site.accessPoints.length > 0))) {
            await this.updateDevices(
              payload.id,
              this.getCurrentDrawingZoneFeature()
            );
          }
          this.$toasted.show(this.$t('zone-edited'), {
            position: 'bottom-right',
            className: ['toast-success'],
            duration: 2000,
          });
          this.currentZone = null;
          this.zoneHasChanges = false;
          this.dirty = true;
          this.loadZones();
          this.resetForm();
        } catch (error) {
          this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
        }
      }
    },
    async confirmDeleteZone() {
      try {
        const zoneId = this.currentZone.id;
        await this.deleteZone(zoneId);
        this.$toasted.show(this.$t('zone-deleted'), {
          position: 'bottom-right',
          className: ['toast-success'],
          duration: 1500,
        });
        this.currentZone = null;
        this.zoneHasChanges = false;
        this.dirty = true;
        this.confirmationDeleteDialogZone = false;
        this.mapBoxDraw.deleteAll();
        this.loadZones();
        this.resetForm();
      } catch (error) {
        this.$toasted.show(error, {
          position: 'bottom-right',
          className: ['toast-error'],
          duration: 1500,
        });
      }
    },
    async updateDevices(zoneId, polygon) {
      const locationsToUpdateWithZone = [];
      const locationsOutOfZone = [];

      const gatewaysToUpdateWithZone = [];
      const gatewaysOutOfZone = [];

      const accessPointsToUpdateWithZone = [];
      const accessPointsOutOfZone = [];
      let assignedLocations = [];
      if (this.area && this.area.locations) {
        assignedLocations = this.area.locations.filter(
          (location) => location.point.length
        );
      }
      let assignedGateways = [];
      if (this.area && this.area.site && this.area.site.gateways) {
        assignedGateways = this.area.site.gateways.filter(
          (gateway) => gateway.point.length && gateway.areaId === this.area.id
        );
      }
      let assignedAccessPoints = [];
      if (this.area && this.area.site && this.area.site.accessPoints) {
        assignedAccessPoints = this.area.site.accessPoints?.filter(
          (accessPoint) =>
            accessPoint.point.length && accessPoint.areaId === this.area.id
        );
      }
      assignedLocations.forEach((location) => {
        // To check if LB has moved in/out of the zone
        if (location.zoneId === zoneId) {
          if (!booleanPointInPolygon(location.point, polygon)) {
            location.zoneId = null;
            locationsOutOfZone.push({
              id: location.id,
              name: location.name,
              zoneId: null,
            });
          }
        } else if (booleanPointInPolygon(location.point, polygon)) {
          location.zoneId = zoneId;
          locationsToUpdateWithZone.push({
            id: location.id,
            name: location.name,
            zoneId: zoneId,
          });
        }
      });

      assignedGateways.forEach((gateway) => {
        if (gateway.zoneId === zoneId) {
          if (!booleanPointInPolygon(gateway.point, polygon)) {
            gateway.zoneId = null;
            gatewaysOutOfZone.push({
              id: gateway.id,
              name: gateway.name,
              zoneId: null,
            });
          }
        } else if (booleanPointInPolygon(gateway.point, polygon)) {
          gateway.zoneId = zoneId;
          gatewaysToUpdateWithZone.push({
            id: gateway.id,
            name: gateway.name,
            zoneId: zoneId,
          });
        }
      });

      assignedAccessPoints.forEach((accessPoint) => {
        if (accessPoint.zoneId === zoneId) {
          if (!booleanPointInPolygon(accessPoint.point, polygon)) {
            accessPoint.zoneId = null;
            accessPointsOutOfZone.push({
              id: accessPoint.id,
              name: accessPoint.name,
              zoneId: null,
            });
          }
        } else if (booleanPointInPolygon(accessPoint.point, polygon)) {
          accessPoint.zoneId = zoneId;
          accessPointsToUpdateWithZone.push({
            id: accessPoint.id,
            name: accessPoint.name,
            zoneId: zoneId,
          });
        }
      });

      const locationsToUpdate = [
        ...locationsToUpdateWithZone,
        ...locationsOutOfZone,
      ];
      if (locationsToUpdate.length) {
        await this.editLocations({siteId: this.area.site? this.area.site.id : this.currentSiteId, devicesToUpdate: locationsToUpdate});
      }
      const gatewaysToUpdate = [
        ...gatewaysToUpdateWithZone,
        ...gatewaysOutOfZone,
      ];
      if (gatewaysToUpdate.length) {
        await this.editGateways({siteId: this.area.site? this.area.site.id : this.currentSiteId, devicesToUpdate: gatewaysToUpdate});
      }
      const accessPointsToUpdate = [
        ...accessPointsToUpdateWithZone,
        ...accessPointsOutOfZone,
      ];
      if (accessPointsToUpdate.length) {
        await this.editAccessPoints({siteId: this.area.site? this.area.site.id : this.currentSiteId, devicesToUpdate: accessPointsToUpdate});
      }
    },
    addZoneCategory() {
      this.$refs.zoneCategoryNewEditModal.openModal('create', this.currentSiteId);
    },
    refreshZoneCategories() {
      this.getZoneCategories( {siteId: this.currentSiteId});
    },
    resetForm() {
      this.$v.form.$reset();      
      this.foundTag = false;
      this.searchText = '';
      this.cleanText = true;
      this.form.name = '';
      this.form.categoryId = null;
      this.form.installedLatitude = '';
      this.form.installedLongitude = '';
      if (this.geoCoder) {
        this.geoCoder.clear();
      }
      this.select = null;
    },
    confirmCloseModal() {
      this.dialog = false;
      if(this.$refs.searchBar !== undefined){
        this.$refs.searchBar.searchWord = '';
      }
      this.mapLoaded = false;
      this.currentZone = null;
      this.currentMode = '';
      this.zoneHasChanges = false;
      this.confirmationCloseDialog = false;
      if (this.dirty) {
        this.$emit('hasChanges', this.area);
      }
      if (this.map) {
        this.map.remove();
      }
       if (this.tags.length > 0){
        this.clearTags();
      }
      if (this.geoCoder) {
        this.geoCoder.clear();
      }
    },
    closeModal() {
      if (this.zoneHasChanges) {
        this.confirmationCloseDialog = true;
      } else {
        this.confirmCloseModal();
      }
      this.resetForm();
      if (this.geoCoder) {
        this.geoCoder.clear();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.row-selected {
  background-color: #cfd0d3;
}
.table-style {
  border: 1px solid  !important;
  border-color: rgba(0, 0, 0, 0.42) !important;
  width: 100%;
  max-width: 322px;
  height: 60%;
}


thead.header {
  background-color: #00A1C0;  
}
.font-text {
  color: white !important;
}

.item-title {
  max-width: auto;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  height: 18px;
  font-size: .8rem;
}

.map-container {
  height: 80vh;
  width: 100%;
  padding: 0 !important;
  opacity: 0;
}
.search-column {
  max-width: 322px;
  position: relative;
}

.grid-control {
  top: 104px;
  left: 8px;
}
.zone-name {
  margin-bottom: -20px;
  width: 68%;
  margin-right: 2%;
  display: inline-block;
}
.save-zone-btn {
  width: 14%;
  display: inline-block;
}
.delete-zone-btn {
  width: 14%;
  display: inline-block;
}
.zone-form {
  max-width: 500px;
}
.search-column {
  max-width: 422px;
  position: relative;
}
.finger-span {
  transform: scale(1.3)
}
::v-deep .v-snack__wrapper {
  min-width: unset !important;
}
::v-deep .v-snack__content {
  display: flex;
  align-items: center;
}

.area-selector {
  display: inline-block;
  width: 300px;
  margin-bottom: -23px;
}
.select-tag {
  width: 79% !important;
  margin-left: 5px;
}
.add-geozone-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--v-primary-base);
}
.custom-invalid-polygon {
  position: absolute;
  bottom: 0;
}
::v-deep .v-snack__wrapper {
  min-width: unset !important;
}
::v-deep .v-snack__content {
  display: flex;
  align-items: center;
}
.add-btn {
    min-height: inherit;
    max-height: 20px;
    max-width: 20px;
    min-width: 20px !important;
    right: 10px;
    top: 30px;
    i {
      font-size: 19px;
    }
}

</style>

