export const DEVICES_TYPE = Object.freeze({
  gateway: 'GATEWAY',
  gatewayAb: 'GW',
  accessPoint: 'ACCESS_POINT',
  accessPointAb: 'AP',
  locationBeacon: 'LOCATION',
  locationBeaconOrtag: 'LB-TAG',
  tag: 'TAG',
  replaceDevice: 'REPLACEDEVICE',
});
