<template>
  <div>
    <v-dialog v-model="dialog" width="413" scrollable>
      <v-card id="zoneCategoryEdit">
        <v-card-title v-if="mode === 'create'" class="secondary">{{
          $t('add-zone-category')
        }}</v-card-title>
        <v-card-title v-if="mode === 'edit'" class="secondary">{{
          $t('edit-zone-category')
        }}</v-card-title>
        <v-card-title v-if="mode === 'delete'" class="secondary">{{
          $t('delete-zone-category')
        }}</v-card-title>
        <v-card-text>
          <v-form @submit.stop.prevent id="zoneCategoryEditForm">
            <v-row justify="center">
              <v-col class="mt-3" cols="11">
                <!-- name -->
                <v-autocomplete
                  v-if="mode === 'edit' || mode === 'delete'"
                  class="d-flex"
                  :items="zoneCategories"
                  item-text="value"
                  item-value="id"
                  :label="$t('zone-category')"
                  tabindex="1"
                  :dense="true"
                  v-model="form.id"
                  @change="setZoneCategory"
                  outlined
                  color="secondary"
                ></v-autocomplete>
                <v-text-field
                  v-if="mode === 'edit' || mode === 'create'"
                  :label="$t('name')"
                  tabindex="2"
                  :dense="true"
                  v-model="form.name"
                  outlined
                  :error-messages="nameErrors"
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <v-btn
              v-if="mode === 'edit'"
              depressed
              color="secondary"
              tabindex="4"
              @click="onEditZoneCategory"
              class="px-8 float-right"
              >{{ $t('save') }}</v-btn
            >
            <v-btn
              v-if="mode === 'create'"
              depressed
              color="secondary"
              tabindex="4"
              @click="onCreateZoneCategory"
              class="px-8 float-right"
              >{{ $t('add') }}</v-btn
            >
            <v-btn
              v-if="mode === 'delete'"
              depressed
              color="error"
              tabindex="4"
              @click="onDeleteZoneCategory"
              class="px-8 float-right"
              >{{ $t('delete') }}</v-btn
            >
            <v-btn
              text
              color="secondary"
              tabindex="3"
              @click="resetForm"
              class="mr-2 px-8 float-right"
              >{{ $t('cancel') }}</v-btn
            >
          </v-col>
        </v-card-actions>
        <confirm-delete-modal
          @confirm="confirmDeleteZoneCategory"
          @cancel="confirmDeleteDialog = false"
          :dialogModel="confirmDeleteDialog"
          :dialogTitle="$t('delete-zone-category')"
          :dialogBody="$t('delete-are-you-sure', { name: currentCategoryName })"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ConfirmDeleteModal from '../shared/modals/confirmModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    'confirm-delete-modal': ConfirmDeleteModal,
  },
  data() {
    return {
      mode: 'create',
      dialog: false,
      currentCategoryName: null,
      confirmDeleteDialog: false,
      currentSiteId: null,
      form: {
        name: '',
        id: '',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  props: ['modalMode'],
  computed: {
    ...mapGetters('zone', ['zoneCategories']),
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required &&
        errors.push(this.$t('error-name-required'));
      return errors;
    },
  },
  methods: {
    ...mapActions('zone', ['createZoneCategory', 'deleteZoneCategory']),
    openModal(createEdit, siteId) {
      this.$set(this.form, 'name', '');
      this.$set(this.form, 'id', '');
      this.currentCategoryName = null;
      this.confirmDeleteDialog = false;
      this.mode = createEdit;
      this.currentSiteId = siteId
      this.dialog = true;
    },
    resetForm() {
      this.$v.form.$reset();
      Object.assign(this.form, {
        name: '',
      });
      this.dialog = false;
    },
    setZoneCategory(selectedCategory) {
      this.zoneCategories.map((category) => {
        if (selectedCategory === category.id) {
          this.currentCategoryName = category.value;
        }
      });
      this.$set(this.form, 'name', this.currentCategoryName);
    },
    async onEditZoneCategory() {
      this.$v.$touch();
      if (!this.$v.form.$anyError && this.mode === 'edit') {
        const siteId = this.currentSiteId;
        const { name, id } = this.form;
        const categoryToEdit = {
          siteId: siteId,
          id,
          name,
        };
        try {
          await this.createZoneCategory(categoryToEdit);
          this.$emit('finished');
          this.$toasted.show(this.$t('zone-category-edited'), {
            position: 'bottom-right',
            className: ['toast-success'],
            duration: 2000,
          });
          this.resetForm();
        } catch (error) {
          this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
        }
      }
    },
    async onCreateZoneCategory() {
      this.$v.$touch();
      if (!this.$v.form.$anyError && this.mode === 'create') {
        const siteId = this.currentSiteId;
        const { name } = this.form;
        const categoryToCreate = {
          name: name.trim(),
          siteId: siteId,
        };
        try {
          await this.createZoneCategory(categoryToCreate);
          this.$emit('finished');
          this.$toasted.show(this.$t('zone-category-created'), {
            position: 'bottom-right',
            className: ['toast-success'],
            duration: 2000,
          });
          this.resetForm();
        } catch (error) {
          this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
        }
      }
    },
    onDeleteZoneCategory() {
      this.$v.$touch();
      if (!this.$v.form.$anyError && this.mode === 'delete') {
        this.confirmDeleteDialog = true;
      }
    },
    async confirmDeleteZoneCategory() {
      let zoneCategoryId = this.form.id;
      try {
        await this.deleteZoneCategory(zoneCategoryId);
        this.confirmDeleteDialog = false;
        this.$emit('finished');
        this.$toasted.show(this.$t('zone-category-deleted'), {
          position: 'bottom-right',
          className: ['toast-success'],
          duration: 2000,
        });
        this.resetForm();
      } catch (error) {
        this.$toasted.show(error, {
          position: 'bottom-right',
          className: ['toast-error'],
          duration: 5000,
        });
      }
    },
  },
};
</script>