import { convertToTitleCase } from "../utils/case-conversion";
import { macAddressValidator } from "../../../mixins/macAddressValidator";
 
class SiteStatus {
  normalizeDeviceStatus = (input) => {
    let deviceStatusSorted = [];
    const deviceTypesOrder = ['GATEWAY', 'ACCESS_POINT', 'LOCATION', 'TAG'];
    const total = input.total;

    
    const tagDataExists = input.rows.findIndex((data) => data.assetType == 'TAG');

    if(tagDataExists < 0){
      input.rows.push({assetType: "TAG", label: "TAG", totalCount: 0, healthStatusCount: null, value: []})
    }

    const gatewayDataExists = input.rows.findIndex((data) =>  data.assetType == 'GATEWAY');

    if(gatewayDataExists < 0){
      input.rows.push({assetType: "GATEWAY", label: "GATEWAY", totalCount: 0, healthStatusCount: null, value: []})
    }

    const accessPointDataExists = input.rows.findIndex((data) =>  data.assetType == 'ACCESS_POINT');

    if(accessPointDataExists < 0){
      input.rows.push({assetType: "ACCESS_POINT", label: "ACCESS_POINT", totalCount: 0, healthStatusCount: null, value: []})
    }

    const locationDataExists = input.rows.findIndex((data) =>  data.assetType == 'LOCATION');

    if(locationDataExists < 0){
      input.rows.push({assetType: "LOCATION", label: "LOCATION", totalCount: 0, healthStatusCount: null, value: []})
    }

    deviceStatusSorted = input.rows.sort((a,b) => {
      return deviceTypesOrder.indexOf(a.assetType) - deviceTypesOrder.indexOf(b.assetType)
    })

    deviceStatusSorted.map((healthData) => {
      healthData.colors = [];
      healthData.labels = [];
      healthData.values = [];
      if(healthData.healthStatusCount){
        Object.entries(healthData.healthStatusCount).forEach(function([key, value]){
          if(key == "RED" && value != 0){
            healthData.colors.push('#ffcccc')
            healthData.labels.push('RED')
            healthData.values.push(value)
            healthData.label = healthData.assetType;
          }
          if(key == "GRAY" && value != 0){
            healthData.colors.push('#dcdcdc')
            healthData.labels.push('GRAY')
            healthData.values.push(value)
            healthData.label = healthData.assetType;
          }
          if(key == "GREEN" && value != 0){
            healthData.colors.push('#d6f5d6')
            healthData.labels.push('GREEN')
            healthData.values.push(value)
            healthData.label = healthData.assetType;
          }
        });
      }else{
        healthData.labels = ['GRAY', 'GRAY', 'GRAY'];
        healthData.colors = ['#dcdcdc', '#dcdcdc', '#dcdcdc'];
        healthData.values = healthData.value ? healthData.value : [healthData.totalCount];
        healthData.totalCount = healthData.totalCount;
        healthData.label = healthData.assetType;
      }
    })


    
    return {
      rows: deviceStatusSorted,
      total,
    };
  }

  normalizeUnprovisionedLocationBeaconStatus = (input) => {
    const total = input.total;
    const rows = input.rows.map((locationBeacon) => {
      locationBeacon.highlight = locationBeacon.maxRssi > -26 ? "blue" : "";
      if (locationBeacon.maxRssi) {
        locationBeacon.maxRssi = `${locationBeacon.maxRssi} dBm`;
      }
      if (locationBeacon.averageRssi) {
        locationBeacon.averageRssi = `${locationBeacon.averageRssi} dBm`;
      }
      return locationBeacon;
    });

    return {
      rows,
      total,
    };
  }

  normalizeUnprovisionedAccessPointStatus = (input) => {
    const total = input.total;
    const rows = input.rows.map((accessPoint) => {
      accessPoint.highlight = accessPoint.maxRssi > -26 ? "blue" : "";
      if (accessPoint.maxRssi) {
        accessPoint.maxRssi = `${accessPoint.maxRssi} dBm`;
      }
      if (accessPoint.averageRssi) {
        accessPoint.averageRssi = `${accessPoint.averageRssi} dBm`;
      }
      return accessPoint;
    });

    return {
      rows,
      total,
    };
  }

  normalizeGatewayStatus = (input,orgName,siteName) => {
    const total = input.total;
    const rows = input.rows.map((gateway) => {
      const properties = gateway.properties;
      if (properties) {
        if (properties.health) {
          properties.health = convertToTitleCase(
            properties.health
          );
        }
        gateway.properties.network = orgName+'('+ siteName + ')' ;
        gateway.highlight = parseHealth(properties.health);
        gateway.properties.downlink_channel = properties.downlink_channel;
        
      }
      return gateway;
    });

    return {
      rows,
      total,
    };
  }

  normalizeAccessPointStatus = (input,orgName,siteName) => {
    const total = input.total;
    const rows = input.rows.map((accessPoint) => {
      const properties = accessPoint.properties;
      if (properties) {
        if (properties.health) {
          properties.health = convertToTitleCase(
            properties.health
          );
        }
        accessPoint.properties.network = orgName+'('+ siteName + ')' ;
        accessPoint.highlight = parseHealth(properties.health);
      }
      return accessPoint;
    });

    return {
      rows,
      total,
    };
  }

  normalizeLocationBeaconStatus = (input) => {
    const total = input.total;
    const rows = input.rows.map((locationBeacon) => {
      const properties = locationBeacon.properties;
      if (properties) {
        if (properties.health) {
          properties.health = convertToTitleCase(properties.health);
        }
        if (properties.nodeAddress) {
          properties.node = properties.nodeAddress
          properties.nodeAddress = getMacAddress(properties.nodeAddress);
        }
        locationBeacon.highlight = parseHealth(properties.health);
      }
      return locationBeacon;
    });

    return {
      rows,
      total,
    };
  }

  normalizeTagStatus = (input) => {
    const total = input.total;
    const rows = input.rows.map((tag) => {
      const properties = tag.properties;
      if (properties) {
        if (properties.health) {
          properties.health = convertToTitleCase(properties.health);
        }
        if (properties.nodeAddress) {
          properties.nodeAddress = getMacAddress(properties.nodeAddress);
        }
      }
      return tag;
    });

    return {
      rows,
      total,
    };
  }
}

function parseHealth(inputHealth) {
  switch(inputHealth.toLowerCase()) {
    case "red":
      return "red";

    case "green":
      return "green";

    case "gray":
      return "gray";

    default:
      return ""
  }
}

function getMacAddress(nodeAddress) {
  const sliceNodeAddress = nodeAddress.slice(nodeAddress.length - 13, nodeAddress.length);
  return macAddressValidator.computed.formatMacAddress(sliceNodeAddress)
}

export default SiteStatus;