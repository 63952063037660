<template>
  <div id="sites">
    <v-row>
      <v-col class="custom-card card-no-footer-height">
        <v-row class="mb-5" style="height: 40px;">
          <!-- search bar -->
          <search-bar
            @searchText="search"
            :placeholder="$t('search-by-name')"
          />
          <!-- Add Site button -->
          <v-col cols="8">
            <div class="mr-3 right-btn-container">
              <v-btn
                v-if="isOrgAdmin"
                id="add-site"
                height="32"
                :depressed="true"
                @click="createSite"
                class="primary--text font-weight-bold"
                color="primaryLight"
                >{{ $t('add-site') }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <!-- accodions -->
        <div class="accordion-container">
          <!-- sites -->
          <v-expansion-panels multiple accordion :value="sitePanel">
            <v-expansion-panel
              v-for="(site, i) in filterByTerm"
              :key="i"
            >
              <v-expansion-panel-header
                class="accordion-header"
                expand-icon="mdi-menu-down"
              >
                <div class="accordion-title">
                  {{ site.value }}
                </div>
              </v-expansion-panel-header>
              <actions-popup
                :closeDelay="'150'"
                class="actions-popup"
                :actions="siteSettings"
                :row="site"
              />
              <v-expansion-panel-content >
                <!-- Site Metadata -->
                <v-list dense rounded>
                  <v-list-item>
                    <v-list-item-content >
                      <v-list-item-title class="font-weight-black">Site Type</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ siteType(site) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-black">Organization Id</v-list-item-title>
                      <v-list-item-subtitle>{{site.organizationId}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-black">Site Id</v-list-item-title>
                      <v-list-item-subtitle>{{site.id}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
    <!-- Site Editing -->
    <new-edit-modal
      ref="siteNewEditModal"
      :modalMode="createEdit"
      @finishedEdit="finished"
      @finishedCreate="finished"
    />
    <!-- Labels Editing -->
    <label-edit-modal @labelsEdited="sitePanel = []" ref="siteLabelEditModal" />
    <!-- Offsite LB -->
    <OffsiteLocations ref="offsiteLocations" />
    <!-- Delete Site Confirmation Modal -->
    <confirm-modal
      @confirm="confirmDeleteSite"
      @cancel="confirmationDeleteDialogSite = false"
      :dialogModel="confirmationDeleteDialogSite"
      :dialogBody="$t('delete-are-you-sure', { name: nameToDelete })"
      :dialogTitle="$t('delete-site')"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import actionsPopUp from '../../components/shared/data-table/custom-cells/actions-popup';
import SearchBar from '../../components/search-bar/search-bar';
import SiteNewEdit from '../../components/sites/site-new-edit';
import SiteLabelEdit from '../../components/sites/site-label-edit';
import ConfirmationModal from '../../components/shared/modals/confirmModal';
import OffsiteLocations from '../../components/locations/offsite-locations';
import { ROLES } from '../../constants/roles';

export default {
  name: 'Sites',
  components: {
    'new-edit-modal': SiteNewEdit,
    'label-edit-modal': SiteLabelEdit,
    'search-bar': SearchBar,
    'confirm-modal': ConfirmationModal,
    'actions-popup': actionsPopUp,
    OffsiteLocations,
  },
  data() {
    return {
      sitePanel: [],
      createEdit: 'create',
      confirmationDeleteDialogSite: false,
      objToDelete: null,
      nameToDelete: '',
      searchValue: '',
      firstLoaded: false,
      siteSettings: [
        {
          name: this.$t('offsite-lb'),
          fn: this.listOffsiteLB,
          icon: 'map-marker',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('site', ['configurationSites', 'currentSiteId']),
    ...mapGetters('organization', { orgUserRole: 'userRole' }),
    filterByTerm() {
      if (this.searchValue) {
        return this.configurationSites.filter(s => {
          return s.value
            .toLowerCase()
            .replaceAll(' ', '')
            .includes(this.searchValue.toLowerCase().replaceAll(/%20/g, ''));
        });
      } else {
        return this.configurationSites;
      }
    },
    isOrgAdmin() {
      //check the organization user role (Admin OrgUser or SuperAdmin)
      if (
        this.orgUserRole &&
        (this.orgUserRole.assetInfo.metadata.props.role === ROLES.ADMIN ||
          this.orgUserRole.assetInfo.metadata.props.role === ROLES.SUPER_ADMIN)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.isOrgAdmin) {
      this.siteSettings.unshift({
        name: this.$t('edit-site-labels'),
        fn: this.editLabels,
        icon: 'label',
      });
      this.siteSettings.unshift({
        name: this.$t('edit-site'),
        fn: this.editSite,
        icon: 'pen',
      });
      this.siteSettings.push({
        name: this.$t('delete-site'),
        fn: this.handleDeleteSite,
        icon: 'delete',
      });
    } else {
      this.siteSettings.unshift({
        name: this.$t('edit-site-labels'),
        fn: this.editLabels,
        enabledIf: ['Admin'], //if site Admin
        icon: 'label',
      });
      this.siteSettings.unshift({
        name: this.$t('edit-site'),
        fn: this.editSite,
        enabledIf: ['Admin'], //if site Admin
        icon: 'pen',
      });
      this.siteSettings.push({
        name: this.$t('delete-site'),
        fn: this.handleDeleteSite,
        enabledIf: ['Admin'], //if site Admin
        icon: 'delete',
      });
    }
  },
  methods: {
    ...mapActions('site', [
      'getSites',
      'deleteSite',
      'setCurrentSiteId',
    ]),
    ...mapMutations('site', ['setCurrentSite']),
    async fetchSites() {
      await this.getSites();
      (!this.currentSiteId) ? this.setCurrentSiteId({ siteId: this.configurationSites[0].id }) :  this.setCurrentSiteId({ siteId: this.currentSiteId });
    },
    editSite(row) {
      this.createEdit = 'edit';
      this.editedRow = row;
      this.$refs.siteNewEditModal.openModal(row);
    },
    createSite() {
      this.createEdit = 'create';
      this.$refs.siteNewEditModal.openModal();
    },
    handleDeleteSite(site) {
      this.nameToDelete = site.value;
      this.confirmationDeleteDialogSite = true;
      this.objToDelete = site;
    },
    editLabels(site) {
      this.$refs.siteLabelEditModal.openModal(site);
    },
    listOffsiteLB(site) {
      this.$refs.offsiteLocations.openModal(site);
    },
    async confirmDeleteSite() {
      const site = this.objToDelete;
      try {
        await this.deleteSite({ site });
        this.$toasted.show(this.$t('site-deleted'), {
          position: 'bottom-right',
          className: ['toast-success'],
          duration: 1500,
        });
        //if the deleted site is the selected one
        if (site.id === this.currentSiteId) {
          this.setCurrentSite({});
        }
        this.fetchSites();
        this.confirmationDeleteDialogSite = false;
      } catch (error) {
        this.$toasted.show(error, {
          position: 'bottom-right',
          className: ['toast-error'],
          duration: 1500,
        });
      }
    },
    finished() {
      this.fetchSites();
    },
    search(str) {
      this.searchValue = str;
    },
    siteType(site) {
      //According to what was answered to my query in the daily, we will show 'BLE' for AF2 and Everywhere sites.
      if ( site.isAf2 ) {
        return 'BLE';
      }
      if ( site.isAf3 ) {
        return 'XLE';
      }
      if ( site.isEverywhere ) {
        return 'Everywhere';
      }
      return 'Unknown';
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-container {
  position: static;
}
.accordion-header {
  width: 90%;
}
.actions-popup {
  position: absolute;
  right: 30px;
  top: 6px;
}
.right-btn-container {
  height: 100%;
  float: right;
}
.v-expansion-panel-header {
  padding-left: 50px;
}
.v-expansion-panels {
  overflow: auto;
  position: static;
  max-height: calc(100vh - 190px);
}
.accordion-title {
  font-size: 1rem;
  text-align: left;
  width: 100%;
  font-weight: 600;
  color: var(--v-primary-base);
}
.theme--light.v-expansion-panels .v-expansion-panel {
  &:hover {
    background-color: var(--v-primaryLight-base);
  }
}
//media queries
@media (max-width: 812px) {
  .right-btn-container {
    float: none !important;
    margin-left: 13px;
  }
}
</style>
