var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showTable)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}]},[(_vm.settings.actionsPanel)?_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkedRows.length > 0),expression:"checkedRows.length > 0"}],staticClass:"pl-1 pr-1 ml-5",attrs:{"id":"actions-panel"}},[_c('ActionsPanel',{key:_vm.actionKey,on:{"on-delete":_vm.onDeleteSelectedRows,"on-hide-deselected":_vm.onHideDeselected,"on-export":_vm.onExport}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[(_vm.getWindowWidth > _vm.breakpoint)?_c('div',{ref:"tableContainer",staticClass:"tableContainer default-scroll-bar",class:( _obj = {extensibleTableContainer: _vm.resizeRef !== undefined,
              withActions: _vm.checkedRows.length > 0
            }, _obj[_vm.customTableContainerClass] = _vm.customTableContainerClass, _obj ),style:({ maxWidth: _vm.tableContainerWidth })},[_c('table',{class:{resizableTable: _vm.resizeRef !== undefined}},[_c('thead',{ref:"thead"},[_c('tr',[_c('th',{staticClass:"checkbox",class:{
                  hide: !_vm.settings.actionsPanel && !_vm.settings.singleSelect,
                },attrs:{"slot":"header"},slot:"header"},[(_vm.settings.actionsPanel)?_c('div',[_c('img',{attrs:{"src":_vm.checkedAll ? _vm.uncheckAll : _vm.uncheckedIcon},on:{"click":_vm.checkAll},model:{value:(_vm.checkedAll),callback:function ($$v) {_vm.checkedAll=$$v},expression:"checkedAll"}})]):_vm._e()]),_vm._l((_vm.mutableColumns),function(col,index){return _c('th',{key:index,staticClass:"p-relative",class:_vm.name + col.field,attrs:{"draggable":"true","width":col.width ? col.width : '',"title":col.name},on:{"drop":_vm.dropTh,"dragstart":_vm.dragTh}},[_c('span',{staticStyle:{"user-select":"none"},on:{"click":function($event){return _vm.sort(col, index)}}},[_vm._v(" "+_vm._s(col.name)+" ")]),(_vm.settings.sorting)?_c('v-icon',{staticClass:"sort-icon",domProps:{"textContent":_vm._s(
                    col.order != undefined
                      ? col.order
                        ? 'mdi-menu-up'
                        : 'mdi-menu-down'
                      : 'mdi-menu-swap'
                  )},on:{"click":function($event){return _vm.sort(col, index)}}}):_vm._e()],1)}),(_vm.settings.moreActions)?_c('th',{staticClass:"more-actions"},[_c('v-menu',{attrs:{"bottom":"","left":"","close-delay":100,"open-on-hover":true,"close-on-click":true,"close-on-content-click":true,"offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"center-content w-100 restore-data-table",attrs:{"dark":"","icon":""}},on),[_c('img',{attrs:{"src":_vm.combinedShapeIcon}})])]}}],null,false,3578585313)},[(_vm.resizeRef)?_c('v-list',[_c('v-list-item',{on:{"click":_vm.restoreWidths}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-restore")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('restore-columns')))])],1)],1):_vm._e()],1)],1):_vm._e()],2)]),_c('tbody',{ref:"tbody"},[(_vm.rows.length === 0)?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.instructions ? _vm.$t('table-instructions') : _vm.$t('table-no-rows'))+" ")])]):_vm._e(),_vm._l((_vm.rows),function(row,indexRow){return _c('tr',{key:indexRow,class:row.hide ? 'hide' : ''},[_c('td',{staticClass:"checkbox",class:{
                  hide: !_vm.settings.actionsPanel && !_vm.settings.singleSelect,
                }},[(_vm.settings.actionsPanel || _vm.settings.singleSelect)?_c('div',{class:row.checked ? 'checked' : ''},[_c('img',{attrs:{"src":row.checked ? _vm.checkedMarkIcon : _vm.uncheckedIcon},on:{"click":function($event){return _vm.checkRow(row)}},model:{value:(row.checked),callback:function ($$v) {_vm.$set(row, "checked", $$v)},expression:"row.checked"}})]):_vm._e()]),_vm._l((_vm.mutableColumns),function(col,colIndex){return _c('td',{key:colIndex,class:[
                  {
                    'border-highlighted':
                      colIndex === 0 &&
                      !_vm.settings.actionsPanel &&
                      !_vm.settings.singleSelect,
                    capitalize: col.capitalizeStyle,
                    assetNameColor: colIndex == 0                
                  },
                  _vm.name + col.field ],attrs:{"title":col.type === 'data' ? row[col.field] : '',"width":col.width ? col.width : ''}},[(col.type === 'data')?[_vm._v(" "+_vm._s(row[col.field]))]:_vm._e(),(col.type === 'objectData')?[_vm._v(" "+_vm._s(_vm.getValueFromStringObjectProperty(row, col.field)))]:(
                    col.type === 'render' || col.type === 'renderObject'
                  )?[_c(col.cellRenderer,{tag:"component",attrs:{"params":row,"renderParams":col.renderParams ? col.renderParams : null,"field":col.type === 'renderObject'
                        ? _vm.addObjectEntry(row, col.field)
                        : col.field},on:{"previewItem":_vm.previewItem}})]:(col.type === 'date')?[(row[col.field])?_c('span',[_vm._v(_vm._s(_vm._f("moment")(row[col.field],col.format)))]):_vm._e()]:(col.type === 'bool')?[_vm._v(" "+_vm._s(row[col.field] ? _vm.$t('yes') : _vm.$t('no'))+" ")]:(col.type === 'gmap')?[(row[col.latField] && row[col.lngField])?_c('v-btn',{attrs:{"icon":"","target":"_blank","href":'http://maps.google.com/maps?t=k&q=loc:'+row[col.latField]+','+row[col.lngField]}},[_c('v-icon',[_vm._v("mdi-link")])],1):_vm._e()]:_vm._e()],2)}),(_vm.settings.moreActions)?_c('td',{staticClass:"more-actions"},[(_vm.settings.moreActions.length)?_c('ActionsPopupComponent',{attrs:{"actions":_vm.settings.moreActions,"row":row}}):_vm._e()],1):_vm._e()],2)})],2)])]):_vm._e(),(_vm.mobileView)?_c('table',{attrs:{"id":"mobileTable"}},[_c('tbody',{class:( _obj$1 = {}, _obj$1[_vm.customTableContainerClass] = _vm.customTableContainerClass, _obj$1.iPlus = _vm.getWindowWidth <= _vm.iPlus, _obj$1 )},_vm._l((_vm.rows),function(row,indexR){return _c('tr',{key:indexR,staticClass:"v-data-table__mobile-table-row"},_vm._l((_vm.mutableColumns),function(col,indexC){return _c('td',{key:indexC,staticClass:"v-data-table__mobile-row",class:{ hide: ((col.type === 'gmap') && (!row[col.latField] || !row[col.lngField])) || ((col.type !== 'gmap') && !row[col.field]) }},[_c('v-row',[(_vm.settings.moreActions && indexC === 0)?_c('v-col',{staticClass:"py-0 mb-1 text-right",attrs:{"cols":"12"}},_vm._l((_vm.settings.moreActions),function(action,index){return _c('span',{key:index},[(
                        action.enabledIf
                        ? _vm.isEnabled(action.enabledIf, row)
                          ? true
                          : false
                        : action.condition 
                          ? _vm.executeCondition(action.condition, row)
                            ? true
                            : false
                          : true
                      )?_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return action.fn(row)}}},[_vm._v("mdi-"+_vm._s(action.icon))]):_vm._e()],1)}),0):_vm._e(),_c('v-col',{staticClass:"py-0 text-ellipsis",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(col.name)+" ")]),_c('v-col',{staticClass:"text-right py-0",attrs:{"cols":"7"}},[(col.type === 'data')?[_vm._v(" "+_vm._s(row[col.field]))]:_vm._e(),(col.type === 'objectData')?[_vm._v(" "+_vm._s(_vm.getValueFromStringObjectProperty(row, col.field)))]:(
                      col.type === 'render' || col.type === 'renderObject'
                    )?[_c(col.cellRenderer,{tag:"component",attrs:{"params":row,"renderParams":col.renderParams ? col.renderParams : null,"field":col.type === 'renderObject'
                          ? _vm.addObjectEntry(row, col.field)
                          : col.field},on:{"previewItem":_vm.previewItem}})]:(col.type === 'date')?[(row[col.field])?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row[col.field],col.format))+" ")]):_vm._e()]:(col.type === 'bool')?[_vm._v(" "+_vm._s(row[col.field] ? _vm.$t('yes') : _vm.$t('no'))+" ")]:(col.type === 'gmap')?[_c('v-btn',{attrs:{"x-small":"","icon":"","target":"_blank","href":'http://maps.google.com/maps/search/?api=1&query='+row[col.latField]+','+row[col.lngField]}},[_c('v-icon',[_vm._v("mdi-link")])],1)]:_vm._e()],2)],1)],1)}),0)}),0)]):_vm._e(),_c('confirm-modal',{attrs:{"dialogModel":_vm.confirmationDeleteSelectionDialog,"dialogBody":(_vm.$t(_vm.confirmDeleteModalBody) || _vm.$t('delete-selection-default')) +
          ' (' +
          _vm.assetsNumberToDelete +
          ')',"dialogTitle":_vm.$t(_vm.confirmDeleteModalTitle) || _vm.$t('delete-selection-title-default')},on:{"confirm":_vm.confirmDelete,"cancel":function($event){_vm.confirmationDeleteSelectionDialog = false}}})],1)],1),_c('v-row',[(_vm.totalRows > 0 && !_vm.settings.noPagination)?_c('Pagination',{class:{ 'mobile mt-6': _vm.mobileView },attrs:{"rowsCount":_vm.rowsCount,"page":_vm.page,"size":_vm.pageSize},on:{"paginationChange":_vm.paginationChange}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }