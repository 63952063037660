<script>
import { HorizontalBar } from 'vue-chartjs';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';

export default {
  name: 'Horizontal-Chart',
  extends: HorizontalBar,
  props: ['chartProp'],
  data() {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#f87979',
            data: [],
          },
        ],
      },
      options: {
        tooltips: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                },
              },
            },
          ],
          yAxes: [
            {
              maxBarThickness: 40,
              ticks: {
                display: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            clip: true,
            anchor: 'end',
            align: 'start',
            font: {
              weight: 'bold',
              size: 13,
            },
          },
        },
      },
    };
  },
  watch: {
    chartProp(newVal, oldVal) {
      this.chartdata.labels = newVal.labels;
      this.options.plugins.datalabels.color = newVal.dataLabelColor;
      this.chartdata.datasets[0].backgroundColor = newVal.color;
      this.chartdata.datasets[0].label = newVal.label;
      this.chartdata.datasets[0].data = newVal.data;
      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>
