import constans from '../constants/id-types'

const validNames = ['name', 'tag_name', '<tag name site level>'];
const validMacAddress = ['mac_address', 'tag_mac', 'mac', 'node_address'];
const validCategories = ['category', 'categories', 'category_id', '<category site label>'];
const validGroups = ['group', 'groups', 'group_id'];
const validField1 = ['field_1', 'field1'];
const validField2 =  ['field_2', 'field2'];

export const csvMixin = {
    methods: {
        // downloads a csv file from an array of data
        csvExport(arrData, name) {
          //Byte order mark  
          let universalBOM = "\uFEFF";
          let csvContent = [];
          csvContent += [
            Object.keys(arrData[0]).join(","),
            ...arrData.map(item => Object.values(item).join(","))
          ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

          const data = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", name + ".csv");
          link.click();
        },
        // converts a csv file into a JSON import devices
        createJSONForImportDevices(csv) {
         
          let lines = csv.split(/\r\n|\n|\r/);
          let result = [];
          let headers = lines[0].split(",");
          let name = headers.find(n => this.validateName(n));
          
          let mac_address = headers.find(m=> this.validateMcAddress(m));
          let category = headers.find(c => this.validateCategory(c));
          let group = headers.find(g => this.validateGroup(g));
          let field1 = headers.find(f1 => this.validateField1(f1));
          let field2 = headers.find(f2 => this.validateField2(f2));

          let locationName = headers.find(l => l.toLowerCase().trim() === 'location_name');

          if (!mac_address || !name || !category || !group || !field1 || !field2){
            //not a valid csv format, must be 5 columns only
            return
          }
          
          for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(",");            

            for (let j = 0; j < headers.length; j++) {
              if (validNames.includes(headers[j].toLowerCase())) {
                obj.name = currentLine[j];
              } else if (validMacAddress.includes(headers[j].toLowerCase())) {
                obj.mac_address = currentLine[j];
              } else if (validCategories.includes(headers[j].toLowerCase())) {
                obj.category_id = currentLine[j];
              } else if (validGroups.includes(headers[j].toLowerCase())) {
                obj.groups = currentLine[j];
              } else if (validField1.includes(headers[j].toLowerCase())) {
                obj.field_1 = currentLine[j];
              } else if (validField2.includes(headers[j].toLowerCase())) {
                obj.field_2 = currentLine[j];
              }

              if (locationName && headers[j] === locationName) {
                obj.location_name = currentLine[j]; //Add 'location_name' header if exists
              }

            }
            result.push(obj);
        }
          return result;
        },
        createJSONForImportGateways(csv) {
          let lines = csv.split(/\r\n|\n|\r/);
          let result = [];
          let headers = lines[0].split(",");
          let name = headers.find(h=> h.trim() === 'name');
          let node_address = headers.find(m=> this.validateMcAddress(m));
          if (name != 'name' || (node_address != constans.NODE_ADDRESS  && node_address != constans.MAC_ADDRESS))  {
            return
          }
          for (let i=1;i<lines.length;i++) {
              let obj = {};
              let currentline = lines[i].split(",");
              for(let j=0; j<headers.length; j++){   
                if (currentline && currentline[j]) {
                  obj[headers[j].trim()] = currentline[j].trim();
                }               
              }
              //avoid empty lines
              if (obj.name || obj.node_address ) {
                result.push(obj);
              }
          }
          return result;
        },
        removeCol(csv, col) {
          const lines = csv.split("\n");
          const headers = lines[0].split(",");
          const colNameToRemove = headers.find(h=> h.trim() === col);
          const index = headers.indexOf(colNameToRemove);
          let newLines = [];
          lines.map((line)=>{
            let fields = line.split(",");
            fields.splice(index, 1)
            newLines.push(fields)
          })
          let arrData = '';
          for (let index = 0; index < newLines.length; index++) {
            const element = newLines[index];
            arrData += element.join(',') + '\n'
          }
          return arrData;
        },  
        // downloads a csv file obtained from the server
        downloadFile(filename, data){
          const blob = new Blob([data], { type: 'octet/stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style = 'display: none';
          a.href = url;
          a.download = `${filename}.csv`
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
      },

      validateName(name) {
        return name && validNames.includes(name.toLowerCase().trim())
      },
      validateMcAddress(macAddress) {
        return macAddress && validMacAddress.includes(macAddress.toLowerCase().trim());          
      },
      validateCategory(category) {
        return category && validCategories.includes(category.toLowerCase().trim());          
      },
      validateGroup(group) {
       return group && validGroups.includes(group.toLowerCase().trim());
      },
      validateField1(field1){
        return field1 && validField1.includes(field1.toLowerCase().trim());
      },
      validateField2(field2){
        return field2 && validField2.includes(field2.toLowerCase().trim());
      }
    }
  };