export default (() => {

    function validateSpecialChars(value){
        if (!value) {
            return value;
          }      
          const specialChars = {
            'Ã´': 'ô',
            'Ãª': 'ê',
            'ÃŽ': 'Î',
            'Ã©': 'é',
            'Ã¨': 'è',
            'Ã»': 'û',
            'Ã®': 'î',
            'Ã ': 'à',
            'Ã':  'à',
            'Ã¯': 'í',
            'Ã²': 'ò',
            'É™': 'ə',   
            '™': ' ',
            'Ä': 'č',
            'à‰': 'É',
            'Ã¯-':'í ',
            'Å¾': 'ž',
            'Å ': 'š',
            'Ä': 'ĝ',
            'ÄŒ': 'Č',
            'Äş': 'ş',
            'Ä‚': 'Ă',
            'ÄŠ': 'Č',
            'Äš': 'ě',
            'Åˇ': 'š',
            'Ä†': 'ć',
            'Åƒ': 'ń',
            'Ä¤': 'Ť',
            'Å·': 'ž',
            'Ä´': 'ň',
            'Ä§': 'ť',
            'Ä¦': 'Ť',
            'Ä¶': 'ľ',
            'Ä·': 'ľ',
            'Äº': 'ú',
            'Ä¹': 'é',
            'Ä»': 'Č',
            'Ä¼': 'ď',
            'Ä½': 'Ľ',
            'Ä¾': 'ľ',
            'Ä¿': 'Ž',
          };
    
          const regex = new RegExp(Object.keys(specialChars).join('|'), 'g');
    
          return value.replace(regex, match => specialChars[match]);
}

    return{validateSpecialChars}
})();

