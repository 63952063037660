import { supertagTok } from '../constants/supertag'

class Tag {
  normalizeForCsv = (data, columns) => {
    let obj = {};
    obj[columns.find(obj => obj.field === 'name').name] = data.name;
    obj['Mac Address'] = data.macAddress,
    obj['Area'] = data.areaName;
    obj['Zone'] =  data.zoneName;
    obj['Location Name'] = data.locationName;
    obj['Location'] = data.location0;
    obj['Latitude'] = data.latitude;
    obj['Longitude'] = data.longitude;
    obj[columns.find(obj => obj.field === 'categoryName').name] = data.categoryName;
    obj[columns.find(obj => obj.field === 'groupName').name] = data.groupName;
    obj[columns.find(obj => obj.field === 'field1').name] =  data.field1;
    obj[columns.find(obj => obj.field === 'field2').name] =  data.field2;
    let auxDate = new Date(data.lastEventTime);
    auxDate = ('0' + (auxDate.getMonth()+1)).slice(-2) + '/' + ('0' + auxDate.getDate()).slice(-2) + '/' + auxDate.getFullYear() + ' ' + ('0' + auxDate.getUTCHours()).slice(-2) + ':' + ('0' + auxDate.getUTCMinutes()).slice(-2) + ':' + ('0' + auxDate.getUTCSeconds()).slice(-2);
    obj['Last Seen'] = data.lastEventTime ? auxDate : '';
    return obj;
  }

  normalizeHistory = history => {
    let d = history.time;
    if (typeof (d) === 'string') {
      d = d.concat('Z')
    }
    return {
      latitude: history.latitude
        ? +history.latitude
        : undefined,
      longitude: history.longitude
        ? +history.longitude
        : undefined,
      id: history.uuid,
      time: new Date(d),
      etype: history.locationType || 'heartbeat'
    };
  };

}

export default Tag;