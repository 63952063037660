import ZoneService from '../../services/zones';
import { sortByValue } from '../../utils/localeCompare';
import i18n from '../../i18n'

const namespaced = true;

const state = {
	zones: [],
  zoneCategories: []
}

const mutations = {
	setZones(state, zones) {
		state.zones = zones;
	},
  setZoneCategories(state, categories) {
		state.zoneCategories = categories;
	},
}

const getters = { 
  zoneCategories: (state) => state.zoneCategories,
}

const actions = {
	async getZonesBySite({}, { siteId }){
		try {
			const response = await ZoneService.getZonesBySite(siteId);
			return response;
		}catch (e) {
            throw e;
        }
	},
	async getZones({commit}, { siteId, hiddenLoader }){
		try {
			const response = await ZoneService.getZones(siteId, hiddenLoader);
			commit('setZones', response);
			return response;
		}catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
	},
	async getZoneByArea({}, { areaId, hiddenLoader }) {
    try {
      const zones = await ZoneService.getZoneByArea( areaId, hiddenLoader );
      return zones.sort(sortByValue);
    } catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
	},
  async getZoneById({}, zoneId) {
    try {
      const zone = await ZoneService.getZoneById(zoneId);
      return zone;
    } catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
	},
	async createZone({}, zone) {
    try {
      const newZone = await ZoneService.createZone(zone)
      return newZone
    } catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
	},
	async editZone({}, zone) {
    try {
      const editedZone = await ZoneService.editZone(zone)
      return editedZone
    } catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
	},
	async deleteZone({}, zoneId) {
    try {
      const deletedZone = await ZoneService.deleteZone(zoneId)
      return deletedZone
    } catch (e) {			
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
  },
  async getZoneCategories({ commit }, { siteId, hiddenLoader }) {
    try {
      const response = await ZoneService.getZoneCategories(siteId, hiddenLoader);
      commit('setZoneCategories', response);
      return response;
    } catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t('something-went-wrong');
      throw new Error(errorMessage, e);
    }
  },
  async createZoneCategory({}, zoneCategory) {
    try {
      const newZoneCategory = await ZoneService.createZoneCategory(zoneCategory)
      return newZoneCategory
    } catch (e) {
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
	},
  async deleteZoneCategory({}, zoneCategoryId) {
    try {
      const deletedZoneCategory = await ZoneService.deleteZoneCategory(zoneCategoryId)
      return deletedZoneCategory
    } catch (e) {			
      let errorMessage = e.data ? e.data.message : i18n.t("something-went-wrong");
      throw new Error(errorMessage, e);
    }
  }
}

export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}