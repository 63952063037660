import axiosHelper from '../utils/axios';

export default (() => {
    const networkAssetPath = "/networkAsset/airfinder/";
    async function replaceDevice(params) {
      const response = await axiosHelper.getNetworkAsset()
      .post(`${networkAssetPath}device?deviceType=${params.deviceType}&originalDeviceId=${params.originalDeviceId}&newDeviceId=${params.newDeviceId}&siteId=${params.siteId}`, null, { headers: { 'Content-Type': 'application/json' }});
      return response.data;
    }
  return {
    replaceDevice,
  }
})();