<template>
  <div class="summary-container">
    <v-row class="summary-main mx-0 pb-3" no-gutters>
      <v-col cols="12">
        <div class="ml-2 py-1">
          <label>{{ $t('group-label') }}:</label>
        </div>
        <span
          class="d-flex ml-5"
          :class="swapped ? 'flex-row-reverse justify-end' : 'justify-start'"
        >
          <span class="d-flex">
            <div class="pl-2 pt-3">
              <label>{{ $t('location') }}</label>
            </div>
            <v-card outlined height="50" class="d-flex ml-2">
              <v-list id="primary-options" class="pt-1" dense>
                <v-list-item-group
                  active-class="group-by-item-active"
                  v-model="selectedLeft"
                  @change="onChangeSelectedLeft"
                  color="primary"
                  multiple
                  class="d-flex"
                >
                  <v-list-item class="filter-item ml-1 mr-1 pr-4 pl-4">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('area') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="filter-item mr-1 pr-4 pl-4">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('zone') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </span>

          <v-btn class="ml-2 mt-2" text icon @click="onSwap">
            <v-icon>mdi-swap-horizontal</v-icon>
          </v-btn>

          <span class="d-flex">
            <div class="pl-2 pt-3">{{ $t('classification') }}</div>
            <v-card outlined height="50" class="d-flex ml-2">
              <v-list id="secondary-options" class="pt-1" dense>
                <v-list-item-group
                  active-class="group-by-item-active"
                  v-model="selectedRight"
                  @change="onChangeSelectedRight"
                  color="primary"
                  multiple
                  class="d-flex"
                >
                  <v-list-item class="filter-item ml-1 mr-1 pr-2 pl-2">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ currentSiteLabels.category }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="filter-item mr-1 pr-2 pl-2">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ currentSiteLabels.group }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="filter-item mr-1 pr-2 pl-2">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ currentSiteLabels.field1 }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="filter-item mr-1 pr-2 pl-2">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ currentSiteLabels.field2 }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </span>
        </span>
      </v-col>
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center">
          <v-row class="chart-header d-flex justify-start ma-0">
            <v-col cols="1" class="d-flex justify-center pl-2 pt-4">
              
            </v-col>
            <v-col
              :cols="attributesColumnCount"
              class="chart-header-item"
              v-for="(attribute, i) in allAttributes"
              :key="i"
            >
              <label
                class="header-label-item"
                @click="onSortOptionChange(attribute)"
                >{{ attributeDetail[attribute].label }}</label
              >
              <v-icon
                class="header-sort-icon"
                @click="onSortOptionChange(attribute)"
                v-text="
                  attributeDetail[attribute].sortEnabled
                    ? selectedSortOrder === sortOrders.ASC
                      ? 'mdi-menu-up'
                      : 'mdi-menu-down'
                    : 'mdi-menu-swap'
                "
              ></v-icon>
            </v-col>
            <v-col
              :cols="12 - allAttributes * attributesColumnCount"
              class="chart-header-item"
            >
              <label
                class="header-label-item"
                @click="onSortOptionChange('assetName')"
                >{{ attributeDetail.assetName.label }}</label
              >
              <v-icon
                class="header-sort-icon"
                @click="onSortOptionChange('assetName')"
                v-text="
                  attributeDetail.assetName.sortEnabled
                    ? selectedSortOrder === sortOrders.ASC
                      ? 'mdi-menu-up'
                      : 'mdi-menu-down'
                    : 'mdi-menu-swap'
                "
              ></v-icon>
            </v-col>
          </v-row>
          <v-row
            class="chart-main default-scroll-bar d-flex pa-0 ma-0"
            no-gutters
          >           
            <v-col
              :cols="1"
              class="chart-column  d-flex justify-center"
            >
              <div :style="{ height: chartContentHeight - 30 + 'px', 'align-items': 'center' }" class="checkbox column-label">
                <div  
                  class="checked"
                  v-for="(summaryLabel, k) in tagsSummary"
                  :key="k"
                >                 
                  <img         
                    width="18"         
                    :src="summaryLabel.checked ? checkedMarkIcon : uncheckedIcon"
                    @click="checkRow(summaryLabel)"
                  />
                  </div>                
              </div>
            </v-col>
            <v-col
              v-for="(attribute, i) in allAttributes"
              :key="i"
              :cols="attributesColumnCount"
              class="chart-column"
            >
              <div
                class="column-label"
                :style="{ height: chartContentHeight - 30 + 'px' }"
              >
                <div
                  class="list-label-item d-flex align-center px-2"
                  v-for="(summaryLabel, j) in tagsSummary"
                  :key="j"
                >
                  <label class="label"
                    >{{ summaryLabel[attribute] || '-' }}
                  </label>
                </div>
              </div>
            </v-col>
            <v-col :cols="11 - allAttributes.length * attributesColumnCount">
              <HorizontalChart
                :style="{ height: chartContentHeight + 'px' }"
                :chartProp="chartDataset"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HorizontalChart from './horizontal-chart.vue';
import { sortStrings } from '../../utils/localeCompare';

export default {
  name: 'Summary',
  components: {
    HorizontalChart,
  },
  data() {
    return {
      uncheckedIcon: require('../../assets/img/icons/unchecked.svg'),
      checkedMarkIcon: require('../../assets/img/icons/white-check.png'),
      uncheckAll: require('../../assets/img/icons/checkbox-deselect.svg'),
      combinedShapeIcon: require('../../assets/img/icons/combined-shape.svg'),
      selectedLeft: [0],
      selectedRight: [0],
      chartDataset: {},
      leftAttributes: [0],
      rightAttributes: [0],
      allAttributes: [],
      sortOrders: {
        ASC: 'asc',
        DESC: 'desc',
      },
      selectedSortOption: null,
      selectedSortOrder: null,
      tagsSummary: [],
      attributeDetail: {},
      swapped: false,
    };
  },
  computed: {
    ...mapGetters('site', ['currentSiteId', 'currentSiteLabels']),
    chartContentHeight() {
      const itemsCount = this.tagsSummary.length;
      let customHeight;
      if (itemsCount <= 10) {
        customHeight = this.tagsSummary.length * 70;
      } else if (itemsCount <= 100) {
        customHeight = this.tagsSummary.length * 60;
      } else {
        customHeight = this.tagsSummary.length * 40;
      }
      // Canvas height limitation
      if (customHeight > 25000) {
        customHeight = 25000;
      }
      return customHeight;
    },
    attributesColumnCount() {
      let columns = 3;
      switch (this.allAttributes.length) {
        case 1:
          columns = 3;
          break;
        case 2:
        case 3:
        case 4:
          columns = 2;
          break;
        case 5:
        case 6:
          columns = 1;
          break;
        default:
          break;
      }
      return columns;
    },
  },
  watch: {
    currentSiteId(newSiteId) {
      if (newSiteId) {
        this.setAttributesDetail();
        this.resetToDefaultSorting();
        this.fetchChartData(newSiteId);
      }
    },
  },
  beforeMount() {
    this.setAttributesDetail();
  },
  mounted() {
    this.resetToDefaultSorting();
    if (this.currentSiteId) {
      this.fetchChartData(this.currentSiteId);
    }
  },
  methods: {
    ...mapActions('tag', ['getTagsSummary']),

    checkRow(row) {
      let firstTagWithArea = {};
      this.tagsSummary.forEach((tag) => {
        if(tag.id === row.id){
          row.checked = !row.checked;
          firstTagWithArea.areaId = tag.areaId;
          firstTagWithArea.areaName = tag.areaName;
          firstTagWithArea.zoneName = tag.zoneName;
          firstTagWithArea.groupName = tag.groupName;
          firstTagWithArea.categoryName = tag.categoryName;
        }else{
          tag.checked = false;
        }        
      });

      if(!row.checked){
        firstTagWithArea= {};
      }
      this.$emit('updateFilters', firstTagWithArea);
    },

    async fetchChartData(siteId) {
      this.setLeftAttributes();
      this.setRightAttributes();
      if (this.swapped) {
        this.allAttributes = [...this.rightAttributes, ...this.leftAttributes];
      } else {
        this.allAttributes = [...this.leftAttributes, ...this.rightAttributes];
      }
      const attributesString = this.allAttributes.toString();
      try {
        this.tagsSummary = await this.getTagsSummary({
          siteId,
          attributes: attributesString,
        });

        // this.tagsSummary.forEach((tag, ) => {
        //   this.$set(tag, 'checked', false);
        // });

        this.updateChart();
      } catch (error) {
        this.$toasted.show(error, {
          position: 'bottom-right',
          className: ['toast-error'],
          duration: 5000,
        });
      }
    },
    updateChart() {
      this.tagsSummary = this.sortSummary(
        [...this.tagsSummary],
        this.selectedSortOption,
        this.selectedSortOrder
      );

      const summaryData = this.tagsSummary.map((item) => item.tagAmount);
      const dataset = {
        color: this.$vuetify.theme.themes.light.secondary,
        dataLabelColor: this.$vuetify.theme.themes.light.primary,
        label: this.currentSiteLabels.asset,
        labels: summaryData,
        data: summaryData,
      };
      this.chartDataset = dataset;
    },
    resetToDefaultSorting() {
      this.selectedSortOption = this.attributeDetail.areaName.sortBy;
      this.attributeDetail.areaName.sortEnabled = true;
      this.selectedSortOrder = this.sortOrders.DESC;
    },
    setAttributesDetail() {
      this.attributeDetail = {
        assetName: {
          label: this.$t('asset-count', {
            asset: this.currentSiteLabels.asset,
          }),
          sortEnabled: false,
          sortBy: 'tagAmount',
        },
        areaName: {
          label: this.$t('area'),
          sortEnabled: false,
          sortBy: 'tagAmount',
        },
        zoneName: {
          label: this.$t('zone'),
          sortEnabled: false,
          sortBy: 'zoneName',
        },
        categoryName: {
          label: this.currentSiteLabels.category,
          sortEnabled: false,
          sortBy: 'tagAmount',
        },
        groupName: {
          label: this.currentSiteLabels.group,
          sortEnabled: false,
          sortBy: 'groupName',
        },
        field1: {
          label: this.currentSiteLabels.field1,
          sortEnabled: false,
          sortBy: 'field1',
        },
        field2: {
          label: this.currentSiteLabels.field2,
          sortEnabled: false,
          sortBy: 'field2',
        },
      };
    },
    onChangeSelectedLeft() {
      this.fetchChartData(this.currentSiteId);
    },
    onChangeSelectedRight() {
      this.fetchChartData(this.currentSiteId);
    },
    setLeftAttributes() {
      this.leftAttributes = [];
      const areaAttribute = this.selectedLeft.findIndex((item) => item === 0);
      if (areaAttribute > -1) {
        this.leftAttributes.push('areaName');
      }
      const zoneAttribute = this.selectedLeft.findIndex((item) => item === 1);
      if (zoneAttribute > -1) {
        this.leftAttributes.push('zoneName');
      }
    },
    setRightAttributes() {
      this.rightAttributes = [];

      const categoryAttribute = this.selectedRight.findIndex(
        (item) => item === 0
      );
      if (categoryAttribute > -1) {
        this.rightAttributes.push('categoryName');
      }

      const groupAttribute = this.selectedRight.findIndex((item) => item === 1);
      if (groupAttribute > -1) {
        this.rightAttributes.push('groupName');
      }

      const field1Attribute = this.selectedRight.findIndex(
        (item) => item === 2
      );
      if (field1Attribute > -1) {
        this.rightAttributes.push('field1');
      }

      const field2Attribute = this.selectedRight.findIndex(
        (item) => item === 3
      );
      if (field2Attribute > -1) {
        this.rightAttributes.push('field2');
      }
    },
    onSortOptionChange(option) {
      this.setAttributesDetail();
      this.attributeDetail[option].sortEnabled = true;
      this.selectedSortOption = this.attributeDetail[option].sortBy;
      this.selectedSortOrder =
        this.selectedSortOrder === this.sortOrders.ASC
          ? this.sortOrders.DESC
          : this.sortOrders.ASC;
      this.updateChart();
    },
    onHandleSortOrder(order) {
      this.selectedSortOrder = order;
      this.updateChart();
    },
    onSwap() {
      this.swapped = !this.swapped;
      this.fetchChartData(this.currentSiteId);
    },
    sortSummary(data, sortOption, sortOrder) {
      const isString = !!(sortOption !== 'tagAmount');

      if (isString) {
        data.sort((a, b) => {
          const nameA =
            sortOrder === this.sortOrders.ASC ? a[sortOption] : b[sortOption];
          const nameB =
            sortOrder === this.sortOrders.ASC ? b[sortOption] : a[sortOption];
          return sortStrings(nameA || '', nameB || '');
        });
      } else {
        data.sort((a, b) => {
          const nameA =
            sortOrder === this.sortOrders.ASC ? a[sortOption] : b[sortOption];
          const nameB =
            sortOrder === this.sortOrders.ASC ? b[sortOption] : a[sortOption];
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        });
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  height: calc(100vh - 110px);
  .summary-main {
    margin-top: -45px;
    width: 100%;
    height: 100%;

    .group-by-item-active {
      color: white;
      background-color: var(--v-info-base);
    }

    .filter-item {
      max-width: 110px;
    }

    .chart-header {
      background: var(--v-info-base);
      height: 50px;
      .chart-header-item {
        border: 1px solid whitesmoke;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .header-label-item {
          cursor: pointer;
        }

        .header-sort-icon {
          color: #b8bdc7;
          width: 15px;
          margin-left: 10px;
          height: 15px;
          font-size: 18px;
          padding-left: 0px !important;
        }
      }
    }

    .chart-main {
      height: calc(100vh - 270px);
      overflow: auto;
      overflow-x: hidden;
      align-items: flex-start;
      min-height: 0;

      .chart-column {
        border: 1px solid whitesmoke;
        .column-label {
          display: grid;
          grid-template-columns: 100%;
          grid-auto-rows: 1fr;
        }
        .list-label-item {
          flex-shrink: 1;
          flex-grow: 1;
          flex-basis: 0;
          overflow: hidden;
          min-height: 0;
          .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
          &:nth-child(even) {
            background-color: #f8f8f9;
          }
        }
      }
    }
  }
}
div.checkbox {
  width: 43px;
  div {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    margin-bottom: 7px;
    &.checked {
      background-color: var(--v-primary-base);
      margin-bottom: 4px;
      margin-left: 10px;
      margin-top: 4px;
    }
    img {
      margin-left: 1px;
      margin-top: 1px;
    }
  }
}
</style>
