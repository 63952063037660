<template>
<v-row>
  <div class="custom-card card-no-footer-height site-status-wrapper">
    <div class="card-header">
      <h2 v-if="header">{{ header }}</h2>
      <div><b>{{siteName}}<br>{{deviceStatusHeaderSuffix}}</b></div>
      <div class="d-flex align-center">
        <v-btn
          @click="refreshAll()"
          color="primary"
          class="mr-2"
          v-text="'Refresh'"
        />
        <v-btn
          @click="exportAll()"
          :disabled="deviceLoading || gatewayLoading || accessPointLoading || locationBeaconLoading || tagLoading"
          color="primary"
          v-text="'Export all'"
        />
        <div class="px-2">
          <img
            class="help-icon"
            src="./assets/img/help-icon.svg"
            @click="showModal = true"
          />
        </div>
      </div>
    </div>
    <div class="card-content default-scroll-bar">
      <v-row no-gutters>
          <v-col md="3" class="px-2" v-for="(chartData, index) in deviceStatus.rows" :key="index" >
            <p class="custom-label text-center mt-4 text-h6">{{SummaryChartLabels[chartData.assetType]}}</p>
            <DoughnutChart :chartData="chartData" :colors="chartData.colors" :labels="chartData.labels" :values="chartData.values"
              :label="chartData.label" :middleText="chartData.totalCount" :styles="styles" />
          </v-col>
      </v-row>
      <div class="site">
        <grid v-show="unprovisionedLocationBeaconStatus.total"
          :columns="unprovisionedLocationBeaconStatusColumns"
          :rows="unprovisionedLocationBeaconStatus.rows"
          :header="unprovisionedLocationBeaconStatusTitle"
          :isSortable="true"
          :isLoading="deviceLoading || unprovisionedLocationBeaconLoading"
          :disableButton="unprovisionedLocationBeaconStatus.total==0"
          :allowExport="true"
          @export="exportUnprovisionedLocationBeaconStatus"
          @onFilter="getFilteredUnprovisionedLocationBeaconStatus"
          :totalRows="unprovisionedLocationBeaconStatus.total"
          class="grid text-center"
          ref="unprovisionedLocationBeaconTable"
        ></grid>
        <grid v-show="unprovisionedAccessPointStatus.total"
          :columns="unprovisionedAccessPointStatusColumns"
          :rows="unprovisionedAccessPointStatus.rows"
          :header="unprovisionedAccessPointStatusTitle"
          :isSortable="true"
          :isLoading="deviceLoading || unprovisionedAccessPointLoading"
          :disableButton="unprovisionedAccessPointStatus.total==0"
          :allowExport="true"
          @export="exportUnprovisionedAccessPointStatus"

          @onFilter="getFilteredUnprovisionedAccessPointStatus"
          :totalRows="unprovisionedAccessPointStatus.total"
          class="grid text-center"
          ref="unprovisionedAccessPointTable"
        ></grid>
        <grid
          :columns="gatewayStatusColumns"
          :rows="gatewayStatus.rows"
          :actions="gatewayStatusOptions"
          :header="gatewayStatusTitle"
          :isSortable="true"
          :isLoading="deviceLoading || gatewayLoading"
          :disableButton="gatewayStatus.total==0"
          :allowExport="true"
          @export="exportGatewayStatus"
          @onFilter="getFilteredGatewayStatus"
          :totalRows="gatewayStatus.total"
          class="grid text-center"
          ref="gatewayTable"
        ></grid>
        <grid
          :columns="accessPointStatusColumns"
          :rows="accessPointStatus.rows"
          :actions="accessPointStatusOptions"
          :header="accessPointStatusTitle"
          :isSortable="true"
          :isLoading="deviceLoading || accessPointLoading"
          :disableButton="accessPointStatus.total==0"
          :allowExport="true"
          @export="exportAccessPointStatus"
          @onFilter="getFilteredAccessPointStatus"
          :totalRows="accessPointStatus.total"
          class="grid text-center"
          ref="accessPointTable"
        ></grid>
        <grid
          :columns="locationBeaconStatusColumns"
          :rows="locationBeaconStatus.rows"
          :header="locationBeaconStatusTitle"
          :isSortable="true"
          :isLoading="deviceLoading || locationBeaconLoading"
          :disableButton="locationBeaconStatus.total==0"
          :allowExport="true"
          @export="exportLocationBeaconStatus"
          @onFilter="getFilteredLocationBeaconStatus"
          :totalRows="locationBeaconStatus.total"
          class="grid text-center"
          ref="locationBeaconTable"
        ></grid>
        <grid
          :columns="tagStatusColumns"
          :rows="tagStatus.rows"
          :header="tagStatusTitle"
          :isSortable="true"
          :isLoading="deviceLoading || tagLoading"
          :disableButton="tagStatus.total==0"
          :allowExport="true"
          @export="exportTagStatus"
          @onFilter="getFilteredTagStatus"
          :totalRows="tagStatus.total"
          class="grid text-center"
          ref="tagTable"
        ></grid>
      </div>
    </div>
    <div v-if="showModal" :id="'help-modal'" class="modal" :class="{'is-active': showModal}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Definitions</p>
          <img class="help-icon" src="./assets/icons/cross.svg" @click="showModal = false">
        </header>
        <section class="modal-card-body">
          <table>
            <tr>
              <th>Color</th>
              <th class="table-header-defintion">Definition</th>
            </tr>
            <tbody>
              <tr>
                <td>Red</td>
                <td>
                  <span style="display:inline-block;">
                    Several Heartbeats missed; device is likely offline.
                    Check for device changes, loss of power, or poor
                    signal coverage.
                  </span>
                </td>
              </tr>
              <tr>
                <td>Green</td>
                <td>
                  <span style="display:inline-block;">
                    Device is actively sending heartbeats.
                  </span>
                </td>
              </tr>
              <tr>
                <td>Gray</td>
                <td>
                  <span style="display:inline-block;">
                    No traffic history detected for device. Verify
                    device is installed, has power, and mac address is
                    correct.
                  </span>
                </td>
              </tr>
              <tr>
                <td>Blue</td>
                <td>
                  <span style="display:inline-block;">
                    Unprovisioned device is likely in the same room as
                    the device name given. (LB Name for Unprovisioned
                    Access Points, AP Name for Unprovisioned Location
                    Beacons)
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="second-table">
            <tr>
              <th>Parameter</th>
              <th class="table-header-defintion">Definition</th>
            </tr>
            <tbody>
              <tr>
                <td>HBER</td>
                <td>
                  <strong>Heartbeat Error Rate:</strong>
                  <br />
                  <br />
                  <span style="display:inline-block;">
                    0% means 0 of the last 4 messages from the device
                    were missed. An error rate of 25% indicates 1 of the
                    last 4 heartbeat messages was not received. A HBER
                    with an &quot;*&quot; next to it indicates the
                    device has never sent a heartbeat to the system.
                  </span>
                </td>
              </tr>

              <tr>
                <td>RSRQ</td>
                <td>
                  <strong>Reference Signal Received Quality:</strong>
                  <br />
                  <br />
                  <span style="display:inline-block;">
                    RSRQ = (N * RSRP) / RSSI measured over the same
                    bandwidth. RSRQ is a C/I type of measurement and it
                    indicates the quality of the received reference
                    signal. The RSRQ measurement provides additional
                    information when RSRP is not sufficient to make a
                    reliable handover or cell re-selection decision.
                  </span>
                </td>
              </tr>

              <tr>
                <td>RSRP</td>
                <td>
                  <strong>Reference Signal Received Power:</strong>
                  <br />
                  <br />
                  <span style="display:inline-block;">
                    RSRP is the power of the LTE Reference Signals
                    spread over the full bandwidth and narrowband. A
                    minimum of -20 dB SINR (of the S-Synch channel) is
                    needed to detect RSRP/RSRQ.
                  </span>
                </td>
              </tr>

              <tr>
                <td>RSSI</td>
                <td>
                  <strong>Received Signal Strength Indicator:</strong>
                  <br />
                  <br />
                  <span style="display:inline-block;">
                    For Location Beacons and Access Points, RSSI is an
                    estimated measure of power level that a device is
                    receiving from an Access Point or Gateway,
                    respectively. At low values (larger negative value)
                    signal wireless data rates get slower and/or
                    transmissions cannot get through.
                  </span>
                  <br />
                  <br />
                  <span style="display:inline-block;">
                    For Gateways, the carrier RSSI measures the average
                    total received power observed only in OFDM symbols
                    containing reference symbols for antenna port 0. The
                    total received power of the carrier RSSI includes
                    the power from co-channel serving &amp; non-serving
                    cells, adjacent channel interference, thermal noise,
                    etc.
                  </span>
                </td>
              </tr>

              <tr>
                <td>Avg</td>
                <td>
                  <strong>
                    Average:
                  </strong>
                  <br />
                  <br />
                  <span style="display:inline-block;">
                    Average is calculated from the last 10 samples.
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
    <GatewayNewEdit
      @changeOnGateways="fetchAllStatus"
      ref="gatewayNewEdit"
      :modalMode="listingDialogView ? 'edit' : 'create'" 
      :area="area"
    />
    <AccessPointNewEdit
      @changeOnAccessPoints="fetchAllStatus"
      ref="accessPointNewEdit"
      :modalMode="listingDialogView ? 'edit' : 'create'" 
      :area="area"
    />
  </div>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import JSZip from "jszip";
import moment from "moment";
import Grid from './components/grid.vue'
import { downloadBlobInBrowser, downloadInBrowser } from "./utils/file";
import GatewayNewEdit from '../../components/locations/gateway-new-edit';
import DoughnutChart from '../../components/assets-summary/doughnut-chart.vue'
import AccessPointNewEdit from "../../components/locations/access-point-new-edit";
import {
  DeviceColumns,
  GatewayColumns,
  AccessPointColumns,
  LocationBeaconColumns,
  TagColumns,
  SiteStatusType,
  UnprovisionedLocationBeaconColumns,
  UnprovisionedAccessPointColumns,
  firstPage,
  defaultPerPage,
  defaultSortDirection,
  defaultSortBy,
  defaultTagDirection,
  defaultTagSortBy,
  defaultUnprovisionedSortDirection,
  defaultUnprovisionedSortBy,
  SummaryTableLabels
} from './constants/site-status';
import SiteStatus from './models/site-status';
import StatusService from '../../services/status';
import { ROLES } from '../../constants/roles';

export default {
  components: {
    Grid,
    GatewayNewEdit,
    AccessPointNewEdit,
    DoughnutChart
  },
  data: function() {
    return {
      orgName: null,
      orgID: null,
      site: null,
      deviceStatusTitle: "Device Report",
      deviceStatusHeaderSuffix: "",
      unprovisionedLocationBeaconStatusTitle: "Location Beacons detected near site that are not provisioned",
      unprovisionedAccessPointStatusTitle: "Access Points detected near site that are not provisioned",
      gatewayStatusTitle: "Gateways",
      accessPointStatusTitle: "Access Points",
      locationBeaconStatusTitle: "Location Beacons",
      tagStatusTitle: "Tags",
      lastStatusFetchTime: new Date(),
      deviceLoading: true,
      unprovisionedLocationBeaconLoading: true,
      unprovisionedAccessPointLoading: true,
      gatewayLoading: true,
      accessPointLoading: true,
      locationBeaconLoading: true,
      tagLoading: true,
      deviceStatus: { rows: [], total: 0 },
      unprovisionedLocationBeaconStatus: { rows: [], total: 0 },
      unprovisionedAccessPointStatus: { rows: [], total: 0 },
      gatewayStatus: { rows: [], total: 0 },
      accessPointStatus: { rows: [], total: 0 },
      locationBeaconStatus: { rows: [], total: 0 },
      tagStatus: { rows: [], total: 0 },
      formatCsv: 'csv',
      unprovisionedLocationBeaconUrl: '/locations/unprovisioned?',
      unprovisionedAccessPointUrl: '/accessPoints/unprovisioned?',
      gatewayUrl: '/gateways?',
      accessPointUrl: '/accessPoints?',
      locationBeaconUrl: '/locations?',
      tagsUrl: '/tags?',
      deviceStatusUrl: '/status?',
      exportAllClicked: false,
      listingDialogView: 'edit',
      area: {
        site: {
          id: this.currentSiteId
        }
      },
      styles : {
        'margin-top': '-20px'
      },
      showModal: false,
      locationBeaconExportOptions: {
        orderBy: null,
        sortBy: null
      },
      gatewayExportOptions: {
        orderBy: null,
        sortBy: null
      },
      accessPointExportOptions: {
        orderBy: null,
        sortBy: null
      },
      tagExportOptions: {
        orderBy: null,
        sortBy: null
      },
      unprovisionedLocationBeaconExportOptions: {
        orderBy: null,
        sortBy: null
      },
      unprovisionedAccessPointExportOptions: {
        orderBy: null,
        sortBy: null
      },
    };
  },
  beforeMount() {
  },

  props:['siteid', 'inputservice', 'header', 'showActions'],
  computed: {
    ...mapGetters('site', ['currentSiteId', 'currentSiteName']),
    ...mapGetters('organization', ['userRole','orgSelectedName']),
    
    deviceStatusColumns: function(){
      return DeviceColumns;
    },
    unprovisionedLocationBeaconStatusColumns: function(){
      return UnprovisionedLocationBeaconColumns;
    },
    unprovisionedAccessPointStatusColumns: function(){
      return UnprovisionedAccessPointColumns;
    },
    gatewayStatusColumns: function(){
      return GatewayColumns;
    },
    accessPointStatusColumns: function(){
      return AccessPointColumns;
    },
    locationBeaconStatusColumns: function(){
      return LocationBeaconColumns;
    },
    tagStatusColumns: function(){
      return TagColumns;
    },
    deviceStatusHeader: function() {
      let headers = [];
      if (this.deviceStatusHeaderSuffix) {
        headers.push(
          `${this.deviceStatusTitle} | ${this.deviceStatusHeaderSuffix}`
        );
      } else {
        headers.push(this.deviceStatusTitle);
      }

      if (this.orgID) {
        headers.push(`Org ID: ${this.orgID}`);
      }

      let siteIdHeader = "Site ID: ";
      if (this.siteid) {
        siteIdHeader = `${siteIdHeader}${this.siteid}`;
      }
      headers.push(siteIdHeader);

      if (this.orgName) {
        headers.push(`Org Name: ${this.orgName}`);
      }

      let siteNameHeader = "Site Name: ";
      if (this.site && this.site.value) {
        siteNameHeader = `${siteNameHeader}${this.site.value}`;
      }
      headers.push(siteNameHeader);

      return headers;
    },
    siteIdentifier() {
      return this.site && this.site.value
        ? this.site.value.split(" ").join("-")
        : this.siteId;
    },
   accessPointStatusOptions() {
      if (this.showActions && this.userRole) {
        if(this.userRole.assetInfo.metadata.props.role === ROLES.SUPER_ADMIN){
          return [
          {
            name: 'Restart',
            icon: 'reload',
            fn: this.onRestartAccessPoint,
          },
           {
            name: 'Edit',
            icon: 'pen',
            fn: this.editDeviceAccessPoint,
          },
        ]
        }else{
          return [          
            {
              name: 'Edit',
              icon: 'pen',
              fn: this.editDeviceAccessPoint,
            }
          ]
        } 
        
      }
      return null
    },
    gatewayStatusOptions() {
      if (this.showActions && this.userRole) {
        if(this.userRole.assetInfo.metadata.props.role === ROLES.SUPER_ADMIN){
          return [
            {
              name: 'Restart',
              icon: 'reload',
              fn: this.onRestartGateway,
            },
            {
              name: 'Edit',
              icon: 'pen',
              fn: this.editDeviceGateway,
            },
          ]
        }else {
            return [            
            {
              name: 'Edit',
              icon: 'pen',
              fn: this.editDeviceGateway,
            },
          ]
        }
      }
      return null;
    },
    SummaryChartLabels: function(){
      return SummaryTableLabels;
    },
    siteName(){
      return this.currentSiteName
    },
  },
  mounted: function() {
    this.getData();
    
  
  },
  watch:{
    siteid(){
      this.getData();
    }
  },
  methods: {
    ...mapActions('accessPoint', ['getAccessPointById', 'deleteAccessPoints']),
    ...mapActions('gateway', ['getGatewayById', 'deleteGateways']),
    async editDeviceAccessPoint(device) {     
      let editDeviceData = await this.getAccessPointById(device.properties.nodeAddress);
      this.$refs.accessPointNewEdit.openModal(editDeviceData);
    },
    async editDeviceGateway(device) {       
      let editDeviceData = await this.getGatewayById(device.properties.nodeAddress);
      this.$refs.gatewayNewEdit.openModal(editDeviceData);      
    },
  
    async getData() {
      if (this.siteid) {
        await Promise.all([
          this.getSiteAndOrgInfo(),
          this.fetchAllStatus()
        ]);
      }
    },
    refreshAll() {
      this.$refs.unprovisionedLocationBeaconTable.refresh();
      this.$refs.unprovisionedAccessPointTable.refresh();
      this.$refs.gatewayTable.refresh();
      this.$refs.accessPointTable.refresh();
      this.$refs.locationBeaconTable.refresh();
      this.$refs.tagTable.refresh();
      this.fetchAllStatus();
    },
    async fetchAllStatus(){
      this.locationBeaconExportOptions.orderBy = defaultSortDirection
      this.locationBeaconExportOptions.sortBy = defaultSortBy

      this.gatewayExportOptions.orderBy = defaultSortDirection
      this.gatewayExportOptions.sortBy = defaultSortBy
      
      this.accessPointExportOptions.orderBy = defaultSortDirection
      this.accessPointExportOptions.sortBy = defaultSortBy

      this.tagExportOptions.orderBy = defaultSortDirection
      this.tagExportOptions.sortBy = defaultSortBy

      this.unprovisionedLocationBeaconExportOptions.orderBy = defaultUnprovisionedSortDirection
      this.unprovisionedLocationBeaconExportOptions.sortBy = defaultUnprovisionedSortBy

      this.unprovisionedAccessPointExportOptions.orderBy = defaultUnprovisionedSortDirection
      this.unprovisionedAccessPointExportOptions.sortBy = defaultUnprovisionedSortBy

      await this.getDeviceStatus();
      await Promise.all([
        this.getUnprovisionedLocationBeaconStatus(
          firstPage,
          defaultPerPage,
          defaultUnprovisionedSortDirection,
          defaultUnprovisionedSortBy,
        ),
        this.getUnprovisionedAccessPointStatus(
          firstPage,
          defaultPerPage,
          defaultUnprovisionedSortDirection,
          defaultUnprovisionedSortBy,
        ),
        this.getGatewayStatus(
          firstPage,
          defaultPerPage,
          defaultSortDirection,
          defaultSortBy,
        ),
        this.getAccessPointStatus(
          firstPage,
          defaultPerPage,
          defaultSortDirection,
          defaultSortBy,
        ),
        this.getLocationBeaconStatus(
          firstPage,
          defaultPerPage,
          defaultSortDirection,
          defaultSortBy,
        ),
        this.getTagStatus(
          firstPage,
          defaultPerPage,
          defaultTagDirection,
          defaultTagSortBy,
        )
      ]);
    },
    async getSiteAndOrgInfo() {
      this.site = null;
      this.orgName = null;
      this.site = await this.inputservice.getSite(this.siteid);
      this.orgName =
        this.site &&
        this.site.assetInfo &&
        this.site.assetInfo.metadata &&
        this.site.assetInfo.metadata.props &&
        this.site.assetInfo.metadata.props.organizationName;
        this.orgID =
        this.site &&
        this.site.assetInfo &&
        this.site.assetInfo.metadata &&
        this.site.assetInfo.metadata.props &&
        this.site.assetInfo.metadata.props.organizationId;
    },
    async getDeviceStatus() {
      this.deviceLoading = true;
      try {
        const response = await this.inputservice.getDeviceStatus(this.siteid);
        this.deviceStatus = new SiteStatus().normalizeDeviceStatus(response);
      } catch (e) {
        this.deviceStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.deviceLoading = false;
      }
      this.setDeviceReportHeader();
    },
    async getUnprovisionedLocationBeaconStatus(
      page,
      size,
      orderBy,
      sortBy
    ) {
      try {
        this.unprovisionedLocationBeaconLoading = true;
        const response = await this.inputservice.getUnprovisionedLocationBeaconStatus(
          this.siteid,
          page,
          size,
          orderBy,
          sortBy
        );
        this.unprovisionedLocationBeaconStatus = new SiteStatus().normalizeUnprovisionedLocationBeaconStatus(response);
        this.unprovisionedLocationBeaconExportOptions.orderBy = orderBy
        this.unprovisionedLocationBeaconExportOptions.sortBy = sortBy
      } catch (e) {
        this.unprovisionedLocationBeaconStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.unprovisionedLocationBeaconLoading = false;
      }
    },
    async getUnprovisionedAccessPointStatus(
      page,
      size,
      orderBy,
      sortBy,
    ) {
      try {
        this.unprovisionedAccessPointLoading = true;
        const response = await this.inputservice.getUnprovisionedAccessPointStatus(
          this.siteid,
          page,
          size,
          orderBy,
          sortBy
        );
        this.unprovisionedAccessPointStatus = new SiteStatus().normalizeUnprovisionedAccessPointStatus(response);
        this.unprovisionedAccessPointExportOptions.orderBy = orderBy
        this.unprovisionedAccessPointExportOptions.sortBy = sortBy
      } catch (e) {
        this.unprovisionedAccessPointStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.unprovisionedAccessPointLoading = false;
      }
    },
    async getGatewayStatus(
      page,
      size,
      orderBy,
      sortBy,
    ) {
      this.gatewayLoading = true;
      try {
        const response = await this.inputservice.getGatewayStatus(
          this.siteid,
          page,
          size,
          orderBy,
          sortBy
        );
        this.gatewayStatus = new SiteStatus().normalizeGatewayStatus(response,this.orgSelectedName, this.currentSiteName);
        this.gatewayExportOptions.orderBy = orderBy
        this.gatewayExportOptions.sortBy = sortBy
      } catch (e) {
        this.gatewayStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.gatewayLoading = false;
      }
    },
    async getAccessPointStatus(
      page,
      size,
      orderBy,
      sortBy,
    ) {
      this.accessPointLoading = true;
      try {
        const response = await this.inputservice.getAccessPointStatus(
          this.siteid,
          page,
          size,
          orderBy,
          sortBy
        );
        this.accessPointStatus = new SiteStatus().normalizeAccessPointStatus(response,this.orgSelectedName, this.currentSiteName);
        this.accessPointExportOptions.orderBy = orderBy
        this.accessPointExportOptions.sortBy = sortBy
      } catch (e) {
        this.accessPointStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.accessPointLoading = false;
      }
    },
    async getLocationBeaconStatus(
      page,
      size,
      orderBy,
      sortBy,
    ) {
      this.locationBeaconLoading = true;
      try {
        const response = await this.inputservice.getLocationBeaconStatus(
          this.siteid,
          page,
          size,
          orderBy,
          sortBy
        );
        this.locationBeaconStatus = new SiteStatus().normalizeLocationBeaconStatus(response);
        this.locationBeaconExportOptions.orderBy = orderBy
        this.locationBeaconExportOptions.sortBy = sortBy
      } catch (e) {
        this.locationBeaconStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.locationBeaconLoading = false;
      }
    },
    async getTagStatus(
      page,
      size,
      orderBy,
      sortBy,
    ) {
      this.tagLoading = true;
      try {
        const response = await this.inputservice.getTagStatus(
          this.siteid,
          page,
          size,
          orderBy,
          sortBy
        );
        this.tagStatus = new SiteStatus().normalizeTagStatus(response);
        this.tagExportOptions.orderBy = orderBy
        this.tagExportOptions.sortBy = sortBy
      } catch (e) {
        this.tagStatus = { rows: [], total: 0 };
        throw e;
      } finally {
        this.tagLoading = false;
      }
    },
    setDeviceReportHeader() {
      const currentDateTime = new Date();
      const currentTime = currentDateTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit"
      });
      const currentDate = currentDateTime.toLocaleDateString();
      const timeZoneRegex = /\((.*)\)/;
      const regexResult = timeZoneRegex.exec(currentDateTime.toString());
      if (regexResult && regexResult[1]) {
        const bigTimeZone = regexResult[1];
        const shortTimeZone = bigTimeZone
          .split(" ")
          .map(word => word[0])
          .join("");
        this.deviceStatusHeaderSuffix = `Generated ${currentDate} @ ${currentTime} ${shortTimeZone}`;
      }
    },
    async exportDeviceStatus(){
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
          defaultPerPage,
          defaultUnprovisionedSortDirection,
          defaultUnprovisionedSortBy,
          this.formatCsv,
          this.deviceStatusUrl
      )
      const fileName = this.createFileName(this.deviceStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){ 
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
    async exportUnprovisionedLocationBeaconStatus(sortBy, orderBy) {
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
          defaultPerPage,
          (orderBy != '') ? orderBy : defaultUnprovisionedSortDirection,
          (sortBy != '') ? sortBy : defaultUnprovisionedSortBy,
          this.formatCsv,
          this.unprovisionedLocationBeaconUrl
      )
      const fileName = this.createFileName(this.unprovisionedLocationBeaconStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){ 
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
   async exportUnprovisionedAccessPointStatus(sortBy, orderBy) {
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
          defaultPerPage,
          (orderBy != '') ? orderBy : defaultUnprovisionedSortDirection,
          (sortBy != '') ? sortBy : defaultUnprovisionedSortBy,
          this.formatCsv,
          this.unprovisionedAccessPointUrl
      )
      const fileName = this.createFileName(this.unprovisionedAccessPointStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
    async exportGatewayStatus(sortBy, orderBy) {
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
          defaultPerPage,
          (orderBy != '') ? orderBy : defaultSortDirection,
          (sortBy != '') ? sortBy : defaultSortBy,
          this.formatCsv,
          this.gatewayUrl
      )
      const fileName = this.createFileName(this.gatewayStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
     async exportAccessPointStatus(sortBy, orderBy) {
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
        defaultPerPage,
        (orderBy != '') ? orderBy : defaultSortDirection,
        (sortBy != '') ? sortBy : defaultSortBy,
        this.formatCsv,
        this.accessPointUrl
      )
      const fileName = this.createFileName(this.accessPointStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
   async exportLocationBeaconStatus(sortBy, orderBy) {
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
          defaultPerPage,
          (orderBy != '') ? orderBy : defaultSortDirection,
          (sortBy != '') ? sortBy : defaultSortBy,
          this.formatCsv,
          this.locationBeaconUrl
      )
      const fileName = this.createFileName(this.locationBeaconStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
   async exportTagStatus(sortBy, orderBy) {
      try{
        const data = await StatusService.getCSVData(
        this.siteid,
        firstPage,
          defaultPerPage,
          (orderBy != '') ? orderBy : defaultTagDirection,
          (sortBy != '') ? sortBy : defaultTagSortBy,
          this.formatCsv,
          this.tagsUrl
      )
      const fileName = this.createFileName(this.tagStatusTitle)
      if(this.exportAllClicked){
        return {
          fileName,
          data: data
        };
      }
      downloadInBrowser(data, fileName);
      }catch(error){
        this.$toasted.show(error, {
            position: 'bottom-right',
            className: ['toast-error'],
            duration: 5000,
          });
      }
    },
    generateExportTimeString() {
      return moment(this.lastStatusFetchTime)
        .utc()
        .format("YYYY-MM-DDTHH-mm")
        .concat("Z");
    },
    // generateExportData(
    //   tableName,
    //   rows,
    //   columns,
    //   time
    // ) {
    //   const formattedTableName = tableName.split(" ").join("-");
    //   const fileName = `${formattedTableName}_${this.siteIdentifier}_${time}.csv`;
    //   const csvData = generateCSVData(rows, columns);
    //   return {
    //     fileName,
    //     data: csvData
    //   };
    // },
    createFileName(tableName){
      const time = this.generateExportTimeString();
      const formattedTableName = tableName;
      const fileName = `${formattedTableName}_${this.siteIdentifier}_${time}.csv`;
      return fileName
      
    },
    async exportStatus(value, time) {
      if (!time) {
        time = this.generateExportTimeString();
      }
      let exportData = null;
      switch (value) {
        case SiteStatusType.summary:
           exportData = await this.exportDeviceStatus();
          break;

        case SiteStatusType.unprovisionedLocationBeacon:
           exportData = await this.exportUnprovisionedLocationBeaconStatus(
             this.unprovisionedLocationBeaconExportOptions.sortBy,
             this.unprovisionedLocationBeaconExportOptions.orderBy     
        );
          break;

        case SiteStatusType.unprovisionedAccessPoint:
          exportData = await this.exportUnprovisionedAccessPointStatus(
            this.unprovisionedAccessPointExportOptions.sortBy,
            this.unprovisionedAccessPointExportOptions.orderBy   
        );
          break;

        case SiteStatusType.gateway:
          exportData = await this.exportGatewayStatus(
            this.gatewayExportOptions.sortBy,
            this.gatewayExportOptions.orderBy 
        );
          break;

        case SiteStatusType.accessPoint:
          exportData = await this.exportAccessPointStatus(
            this.accessPointExportOptions.sortBy,
            this.accessPointExportOptions.orderBy
        );
          break;

        case SiteStatusType.locationBeacon:
          exportData = await this.exportLocationBeaconStatus(
            this.locationBeaconExportOptions.sortBy,
            this.locationBeaconExportOptions.orderBy     
        );
          break;

        case SiteStatusType.tag:
          exportData = await this.exportTagStatus(
            this.tagExportOptions.sortBy,
            this.tagExportOptions.orderBy  
        );
          break;

        default:
          break;
      }
      return exportData;
    },
   
    async exportAll() {
      const zip = new JSZip();
      const time = this.generateExportTimeString();
      this.exportAllClicked = true;
      this.$toasted.show(this.$t('export-all-download'), {
            position: 'bottom-right',
            className: ['toast-success'],
            duration: 5000,
          });
      for(let status of Object.keys(SiteStatusType)){
        const exportData =  await this.exportStatus(status, time);
        if (exportData) {
          zip.file(exportData.fileName, exportData.data);
        }
      }
      this.exportAllClicked = false;
      const zipBlob = await zip.generateAsync({ type: "blob" });
      downloadBlobInBrowser(
        zipBlob,
        `Site-Status_${this.siteIdentifier}_${time}.zip`
      );
    },
    getFilteredUnprovisionedLocationBeaconStatus(
      size,
      page,
      orderBy,
      sortBy,
    ) {
      this.getUnprovisionedLocationBeaconStatus(page, size, orderBy, sortBy);
    },
    getFilteredUnprovisionedAccessPointStatus(
      size,
      page,
      orderBy,
      sortBy,
    ) {
      this.getUnprovisionedAccessPointStatus(page, size, orderBy, sortBy);
    },
    getFilteredGatewayStatus(
      size,
      page,
      orderBy,
      sortBy,
    ) {
      this.getGatewayStatus(page, size, orderBy, sortBy);
    },
    getFilteredAccessPointStatus(
      size,
      page,
      orderBy,
      sortBy,
    ) {
      this.getAccessPointStatus(page, size, orderBy, sortBy);
    },
    getFilteredLocationBeaconStatus(
      size,
      page,
      orderBy,
      sortBy,
    ) {
      this.getLocationBeaconStatus(page, size, orderBy, sortBy);
    },
    getFilteredTagStatus(
      size,
      page,
      orderBy,
      sortBy,
    ) {
      this.getTagStatus(page, size, orderBy, sortBy);
    },
    onRestartAccessPoint(ap) {
      this.inputservice.restartAccessPoint(ap);
    },
    onRestartGateway(gateway) {
      this.inputservice.restartGateway(gateway);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./styles/bulma/sass/utilities/_all.sass";
@import "./styles/bulma/sass/components/modal.sass";

.site-status-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card-header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.card-content {
  padding: 1rem;
  overflow-y: auto;
  flex: 1;
}

.site {
  .site-status {
    .ag-header-cell,
    .ag-cell {
      font-size: 16px;
    }
  }
}
.grid {
  padding: 10px 0px;
}

.help-icon {
  width: 20px;
  cursor: pointer;
}

</style>
