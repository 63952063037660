class ZoneCategory {
  normalize = categoryData => {
    return {
      value: categoryData.name,
      id: categoryData.id,
    };
  };
}

export default ZoneCategory;
