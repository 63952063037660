import { supertagTok } from '../constants/supertag'

class Tag {
  normalize = (data) => {
    data.lastEventTime = this.normalizeTime(data.lastEventTime);
    const latitudeTime = this.normalizeTime(data.latitude_time);
    const locationTime = this.normalizeTime(data.locationTime);

    //supertag converted in indoor tag => remove lat and long in order to not show it on the map with an old location
    if(supertagTok.includes(data.app_tok) && data.latitude && data.longitude && (locationTime > latitudeTime)){
      data.latitude = null;
      data.longitude = null;
    }

    let location = {};
    const mt = +data.lastMsgType;
    if (data.isHelp && mt === 3) {
      location = { macAddress: data.location0 }
    } else if (!data.isHelp) {
      location = { macAddress: data.location0 }
    }
    let notSeenInDays = null;
    if (data.lastEventTime) {
      let tagDate = new Date(data.lastEventTime);
      notSeenInDays = Math.round((new Date().getTime() - tagDate.getTime()) / (1000 * 3600 * 24));
    }
    const accessPointAsLocation = !!(data.usingAPasLoc0 && data.usingAPasLoc0 === 'true');
    const msgTypeVar = this.normalizeMsgType(parseInt(data.msgType));
    return {
      id: data.nodeAddress,
      app_tok: data.app_tok,// NEX-7560- this property is added to filter tag versions
      appTok: data.registrationToken && data.registrationToken.toLowerCase(),
      site: { id: data.siteId },
      category: { id: data.categoryId },
      group: data.groupId && {
        id: data.groupId
      },
      macAddress: data.macAddress,
      name: data.description,
      area: { id: data.areaId },
      areaName: data.areaName,
      lastMsgType: parseInt(data.lastMsgType),
      location,
      locationName: data.locationName,
      location0: data.location0,
      locationMapPoint: data.locationMapPoint ? data.locationMapPoint.split(',').map(Number) : [],
      isAccessPointLocation: accessPointAsLocation,
      accessPointMapPoint: accessPointAsLocation && data.accessPointMapPoint ? data.accessPointMapPoint.split(',').map(Number) : [],
      zoneName: data.zoneName,
      zoneCategoryName: data.zoneCategoryName,
      categoryName: data.categoryName,
      groupName: data.groupName,
      field1: data.field1,
      field2: data.field2,
      battery:data.batteryStatus ?  parseInt(data.batteryStatus): null,
      batteryStatus: (data.batteryCapacity_mAh || data.batteryConsumed_mAh || data.batteryVoltage) ? parseInt(data.batteryStatus) : null,
      batteryVoltage: parseFloat(data.batteryVoltage), // NEX-7560- this property is added to calculate battery percentage in setting page.
      lastEventTime: data.lastEventTime ? data.lastEventTime : null,
      notSeenInDays: notSeenInDays,
      latitude:
        data.latitude &&
        +data.latitude,
      longitude:
        data.longitude &&
        +data.longitude,
      registrationToken: data.registrationToken,
      source: data.positionSource || 'gps',
      positionName: data.positionName,
      isLost: data.isLost !== 'false',
      locationTime,
      latitudeTime,
      showPlayBtn: false,
      xCoordinate: data.xCoordinate,
      yCoordinate: data.yCoordinate,
      zCoordinate: data.zCoordinate,
      xConfidence: data.xConfidence,
      yConfidence: data.yConfidence,
      zConfidence: data.zConfidence,
      lastAf3EventTime: data.t_ingest ? new Date(parseInt(data.t_ingest)) : null,
      isLeashTag: data.msgType && msgTypeVar
    };
  }

  normalizeTime = (dateTime) => {
    if (typeof (dateTime) === 'string') {
      // api does not return TZ, everything is in UTC
      if(!dateTime.includes('Z')){
        dateTime = dateTime.concat('Z');
      }
      return dateTime;
    }
    return null;
  }

  normalizeMsgType = (msgType) => {
    const msgTypeList = [300, 301, 302, 303, 304, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 10, 11, 12, 13, 35, 36, 37];
    return msgTypeList.includes(msgType);
  }

}

export default Tag;