import SubscriptionModel from '../models/subscriptionModel';
import axiosHelper from '../utils/axios';

export default (() => {
  const networkAssetPath = '/networkAsset/airfinder';

  async function getSubscriptions(orgId, siteId) {
    let url = `${networkAssetPath}/mqttUsers?organizationId=${orgId}`

    if(siteId !== undefined){
      url = url + `&siteId=${siteId}`
    }
    const response = await axiosHelper
      .getNetworkAsset()
      .get(url);
    response.data = response.data.map((sub) =>
      new SubscriptionModel().normalize(sub)
    );
    return response;
  }

  async function createSubscription(data) {
    const response = await axiosHelper
      .getNetworkAsset()
      .post(`${networkAssetPath}/mqttUser`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
    return response;
  }

  async function editSubscription(data) {
    const response = await axiosHelper
      .getNetworkAsset()
      .put(`${networkAssetPath}/mqttUser`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
    return response;
  }

  async function deleteSubscription(params) {
    const response = await axiosHelper
      .getNetworkAsset()
      .delete(`${networkAssetPath}/mqttUser?organizationId=${params.orgId}&mqttUsername=${params.username}&clientId=${params.clientId}`,
      { headers: { 'Content-Type': 'application/json' }, data: {} });
    return response;
  }

  return {
    getSubscriptions,
    createSubscription,
    editSubscription,
    deleteSubscription
  };
})();
