<template>
  <div>
    <v-dialog
      @click:outside="closeModal"
      @keydown.esc="closeModal"
      v-model="dialog"
      width="413"
    >
      <v-card>
        <v-card-title class="secondary">{{
          $t('replace-devices')
        }}</v-card-title>
        <v-card-text>
          <v-form @submit.stop.prevent id="deviceEditForm">
            <v-row justify="center">
              <v-col cols="11">
                <!-- current mac address -->
                <v-text-field
                  :label="$t('current-device')"
                  v-model="form.currentMacAddress"
                  :dense="true"
                  :error-messages="currentMacAddressErrors"
                  outlined
                  required
                  color="secondary"
                  @change="$v.form.currentMacAddress.$touch()"
                ></v-text-field>

                <!-- new mac address -->
                <v-text-field
                  :label="$t('replacement-device')"
                  v-model="form.replaceMacAddress"
                  :error-messages="replaceMacAddressErrors"
                  :dense="true"
                  outlined
                  required
                  color="secondary"
                  @change="$v.form.replaceMacAddress.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-alert
					id="invalidMacReplace"
					class="submit-error"
					:value="invalidWarning"
					transition="scale-transition"
					>
					{{$t('invalid-values')}}
				</v-alert>
        <!-- </v-row> -->
        <v-card-actions>
          <v-col cols="12">
            <v-btn
              depressed
              color="secondary"
              @click="submitForm"
              class="px-4 float-right"
              >{{ $t('confirm') }}</v-btn
            >
            <v-btn
              text
              color="secondary"
              @click="closeModal"
              class="px-4 mr-2 float-right"
              >{{ $t('exit') }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      @click:outside="closeModal"
      @keydown.esc="closeModal"
      v-model="dialogSuccessError"
      width="413"
    >
      <v-card>
        <v-card-title class="secondary">{{$t('replace-devices')}}
          <v-btn color="primary" text fab outlined x-small dark class="float-right mt-1 mr-1 close-replace" title="close" @click:outside="closeModals" @click="closeModals">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="11">
              <h3 class="mt-3">{{response && response.message}}</h3>
                <div class="mt-8 output-info">
                <div class="">Unprovisioned {{device}}</div> 
                <div class="mb-2 color-error">{{OldDeviceMacAdress}}</div>
                <!-- UNPROVISSIONED DEVICE -->
                <div>Battery Voltage: {{response.unprovisionedBatteryVoltage + ' V'}}</div>
                <div>Status: <span :class="colorstatusOld">{{response.unprovisionedStatus}}</span></div>
                <div>Avg RSSI: {{response.unprovisionedRssi + ' dBm'}}</div>
                <!-- PROVISSIONED DEVICE -->
                <hr class="mt-3 mb-3">
                <div class="">Provisioned {{device}}</div> 
                <div class="mb-2 color-success">{{NewDeviceMacAdress}}</div> 
                <div>Battery Voltage: {{response.provisionedBatteryVoltage + ' V'}}</div>
                <div> Status: <span :class="colorstatusNew">{{response.provisionedStatus}}</span> </div>
                <div>Avg RSSI: {{response.provisionedRssi + ' dBm'}}</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- </v-row> -->
        <v-card-actions>
          <v-col cols="12">
            <v-btn
              depressed
              color="secondary"
              @click="handleEdit"
              class="px-4 mr-2 float-right"
              >{{ $t('edit') }}</v-btn
            >
            <v-btn
              depressed
              color="secondary"
              @click="handleReplaceAnother"
              class="px-4 mr-2 float-right"
              >{{ $t('replace-another') }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-location-modal 
      ref="locationEdit"
      :modalMode="'edit'"
      :area="area"
      :areas="siteAreasIndoor"
    />

    <edit-gateway-modal
      ref="gatewayEdit"
      :modalMode="'edit'"      
    />
     <edit-access-point-modal
      ref="accesspointEdit"
      :modalMode="'edit'"      
    />

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import EditLocationModal  from '../../components/locations/location-new-edit.vue';
import EditGatewayModal from '../../components/locations/gateway-new-edit.vue';
import EditAccessPointModal from '../../components/locations/access-point-new-edit.vue';
import { deviceType } from '../../mixins/deviceType';

export default {
  name: 'Replace',
  validations: {
    form: {
      currentMacAddress: {
        required,
      },
      replaceMacAddress: {
        required,
      },
    },
  },
  data() {
    return {
      deviceData:{},
      colorstatusNew: '',
      colorstatusOld: '',
      device: '',
      deviceName: '',
      NewDeviceMacAdress: '',
      OldDeviceMacAdress: '',
      response: [],    
      area: {
        site: {
          id: this.currentSiteId,
        },
      },
      invalidWarning: false,
      form: {
        currentMacAddress: '',
        replaceMacAddress: '',
      },
      dialog: false,
      dialogSuccessError: false,
    };
  },
  components: {
    EditLocationModal,
    EditGatewayModal,
    EditAccessPointModal
  },
  computed: {
    ...mapGetters('site', ['sites', 'currentSiteId']),
    ...mapGetters('area', ['areas']),
    currentMacAddressErrors() {
      const errors = [];
      if (!this.$v.form.currentMacAddress.$dirty) return errors;
      !this.$v.form.currentMacAddress.required &&
        errors.push(this.$t('error-required'));
      return errors;
    },
    replaceMacAddressErrors() {
      const errors = [];
      if (!this.$v.form.replaceMacAddress.$dirty) return errors;
      !this.$v.form.replaceMacAddress.required &&
        errors.push(this.$t('error-required'));
      return errors;
    },
    siteAreasIndoor() {
      return this.areas
        ? this.areas.filter(
            area =>
              area.assetInfo.metadata.props.areaLocation.toLowerCase() ===
              'indoor'
          )
        : [];
    },
  },
  methods: {
    ...mapActions('location', ['getLocationById']),
    ...mapActions('gateway', ['getGatewayById']),
    ...mapActions('accessPoint', ['getAccessPointById']),
    ...mapActions('replace', ['replaceDevice']),
    ...mapActions('site', ['getSites']),
    getStatusColor(statuscolor) {
      let colorstatus = "" 
      switch (statuscolor) {
          case "GRAY":  
            colorstatus="GRAY";
            break;
          case "RED":
            colorstatus="RED";  
            break;
          case "GREEN":
            colorstatus="GREEN";     
            break
          case "BLUE":
            colorstatus="BLUE";
            break;
          default:
            colorstatus=undefined;
        }
        return colorstatus;
    },
    deviceTypeName(deviceName) {
      let aux = ""
      switch (deviceName) {
        case "LB-TAG":
          aux="Location Beacon";
          break;
        case "AP":
          aux= "Access Point";
          break;
        case "LOCATION":
          aux= "Location Beacon";
          break;
        case "GW":
          aux= "Gateway";
          break;
        default:
            aux=''
          break;
      }
      return aux
    },
    openModal() {
      this.dialog = true;
    },
    async handleEdit() {
      const rawId = this.NewDeviceMacAdress.replace(/:| /g, '').toLowerCase();
      switch (this.deviceType) {
        case "LB-TAG":
          this.deviceData = await this.getLocationById(rawId)
          this.$refs.locationEdit.openModal(this.deviceData);
          break;
        case "AP":
          this.deviceData = await this.getAccessPointById(rawId)
          this.$refs.accesspointEdit.openModal(this.deviceData);
          break;
        case "GW":
          this.deviceData = await this.getGatewayById(rawId)
          this.$refs.gatewayEdit.openModal(this.deviceData);
          break;
        default:
          break;
      }
    },
    async submitForm() {
      this.deviceType =  deviceType.computed.getDeviceType(this.form.currentMacAddress);
      this.device = this.deviceTypeName(this.deviceType);
      if (this.$v.form.$anyError || !this.deviceType) {
        return
      }
      let formatedCurrent = this.getMacFromText(this.form.currentMacAddress);
      let formatedNew = this.getMacFromText(this.form.replaceMacAddress);
      this.OldDeviceMacAdress = this.form.currentMacAddress;
      this.NewDeviceMacAdress = this.form.replaceMacAddress;
      if (!(formatedCurrent && formatedNew) || formatedNew === formatedCurrent) {
        this.invalidWarning = true;
        return
      } else {
        this.invalidWarning = false;
      }
      const params = {
        deviceType: this.deviceType,
        originalDeviceId: formatedCurrent,
        newDeviceId: formatedNew,
        siteId: this.currentSiteId
      }

      try {
        const resp = await this.replaceDevice(params);
        
        this.response = resp;
        this.dialogSuccessError = true;
        this.dialog = false;

        this.colorstatusNew = this.getStatusColor(this.response.provisionedStatus)
        this.colorstatusOld = this.getStatusColor(this.response.unprovisionedStatus)
     } catch (error) {
          this.$toasted.show(error, {
          position: 'bottom-right',
          className: ['toast-error'],
          duration: 4500,
          action: {
            text: this.$t('devices-page-ok'),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        });
      }
     },
    handleReplaceAnother() {
      this.$v.form.$reset();
      Object.assign(this.form, {
        currentMacAddress: '',
        replaceMacAddress: '',
      });
      this.dialogSuccessError = false;
      this.dialog = true;
    },
    closeModal() {
      this.$v.form.$reset();
      Object.assign(this.form, {
        currentMacAddress: '',
        replaceMacAddress: '',
      });
      this.dialog = false;
    },
    closeModals() {
      this.closeModal();
      this.dialogSuccessError = false;
    },
    getMacFromText(value) {
      let parsed = '';
      if (value) { 
        parsed = value.replace(/[-:;,_$]/g, '').toLowerCase();
        if (parsed.length >= 12) {
          parsed = parsed.slice(parsed.length - 12, parsed.length)
        } else {
          parsed = ''
        }
      }
      return parsed;
    } 
  },
};
</script>

<style lang="scss" scoped>
.RED {
  color: red
}
.BLUE {
  color: blue;
}
.GRAY {
  color: gray;
}
.GREEN {
  color: green;
}
.output-info {
  font-size: 1rem;
}
.custom-card {
  overflow-y: auto;
  min-height: 100px;
}
.search-bar-devices {
  margin-left: -10px !important;
}
.search-title {
  font-weight: bold;
}
.site-name {
  cursor: pointer;
  max-width: fit-content;
  &:hover {
    color: var(--v-primary-base);
    i {
      color: var(--v-primary-base);
    }
  }
  i {
    top: -2px;
  }
}
.item {
  margin: 15px 0;
  .text {
    font-weight: bold;
  }
}
.small-text {
  font-size: 0.86rem;
}
.no-padding {
  margin-top: -40px;
}
.max-card-height-mobile {
  max-height: 65vh;
  margin-bottom: 15px;
}
#deviceEditForm {
  padding-top: 2rem;
}
.close-replace {
    right: 10px;
    border: none;
    color: #fff !important;
    position: absolute;
    top: 10px;
}
.devices-radio {
  margin: -15px 15px -15px 15px;
}
.submit-error {
  color: var(--v-error-base); 
  margin-left: 42px;
  position: absolute;
  margin-top: -20px;
  font-size: 0.8rem;
  padding: 0;
}
</style>