import { DEVICES_TYPE } from '../constants/status';

export const deviceType = {
  computed: {
      getDeviceType(value) {
        const rawId = value.replace(/:/g, '').toLowerCase();
        if (rawId.indexOf('$101') > -1) {
           return DEVICES_TYPE.gatewayAb;
        } else if (
          rawId.indexOf('$301') > -1 ||
          rawId.indexOf('c00030') > -1 ||
          rawId.indexOf('000030') > -1
        ) {
          return DEVICES_TYPE.accessPointAb;
        } else {
          return DEVICES_TYPE.locationBeaconOrtag;
        }
      }
  }
};